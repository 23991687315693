@import url(../../node_modules/angular-material/angular-material.min.css);
body #main #pageContent header {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

body #main #pageContent article {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }
  body #main #pageContent article .parent {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important; }
    body #main #pageContent article .parent #scheduleCustomerData {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important; }
      body #main #pageContent article .parent #scheduleCustomerData div {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
    body #main #pageContent article .parent blocworx-forbidden {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important; }
    body #main #pageContent article .parent blocworx-authentication {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important; }
    body #main #pageContent article .parent > * {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important; }
      body #main #pageContent article .parent > *.home-page {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
      body #main #pageContent article .parent > * blocworx-navbar {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
        body #main #pageContent article .parent > * blocworx-navbar > div {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
        body #main #pageContent article .parent > * blocworx-navbar .sidebar {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
      body #main #pageContent article .parent > * .content {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
        body #main #pageContent article .parent > * .content .inner-standard-box-wrapper {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
          body #main #pageContent article .parent > * .content .inner-standard-box-wrapper .field-rules-hover {
            -webkit-transition: none !important;
            -moz-transition: none !important;
            -o-transition: none !important;
            transition: none !important; }
        body #main #pageContent article .parent > * .content .login-page {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
        body #main #pageContent article .parent > * .content .icon-link-wrapper {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
        body #main #pageContent article .parent > * .content .scan-station-entry {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
          body #main #pageContent article .parent > * .content .scan-station-entry * {
            -webkit-transition: none !important;
            -moz-transition: none !important;
            -o-transition: none !important;
            transition: none !important; }
      body #main #pageContent article .parent > * blocworx-authentication, body #main #pageContent article .parent > * .content {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
      body #main #pageContent article .parent > * search-data {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
        body #main #pageContent article .parent > * search-data div {
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important; }
      body #main #pageContent article .parent > * table thead, body #main #pageContent article .parent > * table tbody, body #main #pageContent article .parent > * table tr, body #main #pageContent article .parent > * table th, body #main #pageContent article .parent > * table td {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }
    body #main #pageContent article .parent #scanStationHeader {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important; }
      body #main #pageContent article .parent #scanStationHeader div, body #main #pageContent article .parent #scanStationHeader span {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important; }

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important; }

#body.blocworx-iframe #main #pageContent article .is-in-iframe .page-bar,
#body.blocworx-iframe #main #pageContent article .is-in-iframe .advanced-reports-tab,
#body.blocworx-iframe #main #pageContent article .is-in-iframe .sop-files-list-for-station,
#body.blocworx-iframe #main #pageContent article .is-in-iframe .import-data-from-excell-tab {
  display: none !important; }

* {
  -webkit-transition: text-shadow .25s linear, border .25s linear, background-color .25s linear, width .25s linear, height .25s linear, max-height .25s linear;
  -moz-transition: text-shadow .25s linear, border .25s linear, background-color .25s linear, width .25s linear, height .25s linear, max-height .25s linear;
  -o-transition: text-shadow .25s linear, border .25s linear, background-color .25s linear, width .25s linear, height .25s linear, max-height .25s linear;
  transition: text-shadow .25s linear, border .25s linear, background-color .25s linear, width .25s linear, height .25s linear, max-height .25s linear; }

.two-factor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }

body {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  padding: 0px;
  margin: 0px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch; }
  body #modal {
    display: none; }
  body #main #pageContent {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start; }
    body #main #pageContent header {
      display: flex;
      width: 100%;
      -webkit-flex-direction: row;
      flex-direction: row;
      flex: 1 1 90px;
      max-height: 90px; }
      body #main #pageContent header .header {
        width: 100%;
        padding-right: 6px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        flex-wrap: nowrap; }
        body #main #pageContent header .header .logo {
          flex: 1 auto; }
        body #main #pageContent header .header ul.desktop {
          display: flex;
          justify-content: flex-end; }
          body #main #pageContent header .header ul.desktop li:last-child {
            margin-right: 20px; }
    body #main #pageContent article {
      width: 100%;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      flex: 1 1 auto; }
      body #main #pageContent article blocworx-navbar {
        position: relative;
        display: flex;
        align-items: stretch;
        flex-direction: row;
        flex: 0 1 auto; }
        body #main #pageContent article blocworx-navbar .sidebar {
          display: flex;
          flex-direction: row;
          width: 252px;
          height: 100%;
          font-size: 19px !important;
          z-index: 2;
          box-sizing: border-box;
          padding: 22px;
          overflow: auto; }
      body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a {
        display: none; }
      body #main #pageContent article #blocworxNavbar .menu-item-wrapper:not(.sidebar-page):last-child {
        border-bottom: 4px solid #ee3124;
        border-radius: 0px 0px 3px 3px; }
      body #main #pageContent article .parent {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        flex-wrap: nowrap; }
        body #main #pageContent article .parent .logout {
          margin-top: 20px; }
        body #main #pageContent article .parent > * {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-content: stretch;
          align-items: stretch;
          justify-content: flex-start; }
          body #main #pageContent article .parent > * > div {
            width: 100%; }
          body #main #pageContent article .parent > *.home-page {
            padding-top: 20px; }
          body #main #pageContent article .parent > * .content {
            display: flex;
            align-items: stretch;
            box-sizing: border-box;
            padding-left: 25px;
            padding-right: 25px;
            position: relative;
            flex-direction: column; }
            body #main #pageContent article .parent > * .content .switchTo {
              width: 100%; }
            body #main #pageContent article .parent > * .content .login-page input {
              background: #fff !important; }
            body #main #pageContent article .parent > * .content .inner-standard-box-wrapper h4 {
              margin-top: 0px;
              font-weight: normal; }
            body #main #pageContent article .parent > * .content .inner-standard-box-wrapper input[type="checkbox"] {
              margin-right: 10px;
              float: left;
              clear: both;
              margin-bottom: 5px; }
            body #main #pageContent article .parent > * .content .inner-standard-box-wrapper .resize-grid-popup label {
              font-size: 13px;
              width: 122px; }
          body #main #pageContent article .parent > * blocworx-authentication, body #main #pageContent article .parent > * .content {
            flex: 1 1 auto;
            min-width: 74%;
            max-width: 100%; }
  body #main #iframe #pageContent article .content {
    padding: 0;
    z-index: 9999; }
    body #main #iframe #pageContent article .content .standard-box {
      margin: 0px; }
  body #main div.skin-setting-dark {
    background: #232d2f;
    color: #f0f4f5; }
    body #main div.skin-setting-dark .job-single-in-list div {
      color: #004854; }
    body #main div.skin-setting-dark #pageContent article .parent .content {
      background: #232d2f; }
      body #main div.skin-setting-dark #pageContent article .parent .content .inner-standard-box-wrapper .field-rules-hover {
        background: #374244; }
      body #main div.skin-setting-dark #pageContent article .parent .content .inner-standard-box-wrapper .resize-grid-popup {
        background: #374244;
        border: 1px solid #ddd; }
    body #main div.skin-setting-dark #pageContent article .row-blocworx {
      width: 100%; }
      body #main div.skin-setting-dark #pageContent article .row-blocworx:hover .child-element-add-field {
        border: none; }
  body #main div.skin-setting-bright {
    background-color: #fff;
    color: #004854; }
    body #main div.skin-setting-bright #pageContent article .parent .content {
      background: #fff; }
      body #main div.skin-setting-bright #pageContent article .parent .content .inner-standard-box-wrapper .field-rules-hover {
        background: #c7cacc; }
      body #main div.skin-setting-bright #pageContent article .parent .content .inner-standard-box-wrapper .resize-grid-popup {
        background: #eaeaea;
        border: 1px solid #45505285; }

.dark::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

.dark::-webkit-scrollbar-thumb {
  background: #455052; }

.dark::-webkit-scrollbar-track {
  background: #232d2f; }

.dark {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f; }

.bright::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

.bright::-webkit-scrollbar-thumb {
  background: #343a40; }

.bright::-webkit-scrollbar-track {
  background: #eaeaea; }

.bright {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea; }

@media only screen and (max-width: 976px) {
  body #modal, #body #modal {
    display: none; }
  body #main #pageContent header, #body #main #pageContent header {
    flex: 2;
    min-height: 90px; }
    body #main #pageContent header .header ul.desktop, #body #main #pageContent header .header ul.desktop {
      display: none; }
  body #main #pageContent article, #body #main #pageContent article {
    flex: 14;
    flex-direction: column; }
    body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a, #body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a {
      display: block; } }

@media only screen and (max-width: 480px) {
  body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a, #body #main #pageContent article #blocworxNavbar .sidebar .only-mobile a {
    display: block; } }

@media (min-width: 977px) and (max-width: 2570px) {
  body #main #pageContent article > .parent, #body #main #pageContent article > .parent {
    width: calc(100% - 252px); }
  body #main #iframe #pageContent article > .parent, #body #main #iframe #pageContent article > .parent {
    width: 100%; } }

#body #main .field-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 0px;
  width: 100%;
  align-content: center; }

#body #main .blocworx-multi-button {
  min-width: 97px;
  clear: none;
  margin: 5px;
  color: #fff;
  padding: 6px 20px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  font-size: 15px;
  font-weight: 600;
  box-sizing: border-box;
  border: none !important;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none; }
  #body #main .blocworx-multi-button.full-size {
    width: 100%;
    margin: 0px; }

#body #main .blocworx-green-colour {
  background: #58cb78;
  background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%);
  background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%);
  background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#58cb78', endColorstr='#35b835', GradientType=0); }
  #body #main .blocworx-green-colour.selected {
    background: #007E08;
    border-color: #000; }

#body #main .blocworx-dark-green-colour {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4d7f88+0,004854+100 */
  background: #4d7f88;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4d7f88 0%, #004854 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4d7f88 0%, #004854 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4d7f88 0%, #004854 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d7f88', endColorstr='#004854', GradientType=0);
  /* IE6-9 */ }
  #body #main .blocworx-dark-green-colour.selected {
    background: #004854;
    border-color: #00080e; }

#body #main .blocworx-red-colour {
  background: #f36f66;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f36f66 0%, #ee3124 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f36f66 0%, #ee3124 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f36f66 0%, #ee3124 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f36f66', endColorstr='#ee3124', GradientType=0);
  /* IE6-9 */ }
  #body #main .blocworx-red-colour.selected {
    background: #b60000;
    border-color: #3E0000; }

#body #main .blocworx-orange-colour {
  background: #fcbe5f;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fcbe5f 0%, #faa21a 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fcbe5f 0%, #faa21a 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fcbe5f 0%, #faa21a 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcbe5f', endColorstr='#faa21a', GradientType=0);
  /* IE6-9 */
  border-color: #ac7113; }
  #body #main .blocworx-orange-colour.selected {
    background: #da8200;
    border-color: #000; }

#body #main .blocworx-blue-colour {
  background: #6891c9;
  /* Old browsers */
  background: -moz-linear-gradient(top, #6891c9 0%, #2761b1 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #6891c9 0%, #2761b1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #6891c9 0%, #2761b1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6891c9', endColorstr='#2761b1', GradientType=0);
  /* IE6-9 */ }
  #body #main .blocworx-blue-colour.selected {
    background: #114B9B;
    border-color: #000; }

#body #main .blocworx-cyan-colour {
  background: #7de0e3;
  /* Old browsers */
  background: -moz-linear-gradient(top, #7de0e3 0%, #45d2d7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #7de0e3 0%, #45d2d7 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #7de0e3 0%, #45d2d7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7de0e3', endColorstr='#45d2d7', GradientType=0);
  /* IE6-9 */ }
  #body #main .blocworx-cyan-colour.selected {
    background: #079499;
    border-color: #000; }

#body #main .blocworx-purple-colour {
  background: #b971bd;
  /* Old browsers */
  background: -moz-linear-gradient(top, #b971bd 0%, #9b34a1 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #b971bd 0%, #9b34a1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #b971bd 0%, #9b34a1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b971bd', endColorstr='#9b34a1', GradientType=0);
  /* IE6-9 */ }
  #body #main .blocworx-purple-colour.selected {
    background: #7F1885;
    border-color: #000; }

body #main #pageContent article .strong {
  font-weight: 600; }

body #main #pageContent article .flex-container {
  display: inline-flex;
  width: 100%;
  align-content: center;
  align-items: stretch; }
  body #main #pageContent article .flex-container.spaced {
    margin: 10px 0px 10px 0px; }
  body #main #pageContent article .flex-container h2 {
    font-size: 19px;
    width: 110%;
    margin: 0px 0px 10px -6px;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 5px;
    border-radius: 11px; }
  body #main #pageContent article .flex-container h3 {
    font-size: 18px;
    width: 100%;
    font-weight: 600;
    margin: 10px 0px; }
  body #main #pageContent article .flex-container .flex-child-featured {
    width: 100%;
    flex: 1 1 100%; }
  body #main #pageContent article .flex-container .flex-child-x {
    flex: 1 1 20%;
    width: 20%;
    cursor: pointer;
    display: flex;
    justify-content: center; }
  body #main #pageContent article .flex-container .flex-child-button {
    flex: 1 1 27%;
    width: 27%;
    cursor: pointer;
    max-width: 100px; }
  body #main #pageContent article .flex-container .flex-half-title, body #main #pageContent article .flex-container .flex-half-child {
    width: 47%;
    flex: 1 1 47%; }
    body #main #pageContent article .flex-container .flex-half-title.right-margin, body #main #pageContent article .flex-container .flex-half-child.right-margin {
      margin: 0px 2% 0px 0px; }
    body #main #pageContent article .flex-container .flex-half-title.left-margin, body #main #pageContent article .flex-container .flex-half-child.left-margin {
      margin: 0px 0px 0px 2%; }
  body #main #pageContent article .flex-container .left-padding {
    padding: 0px 0px 0px 2.1%; }

#body #modal md-dialog {
  border-radius: 11px; }
  #body #modal md-dialog button md-icon {
    color: white; }

#body #modal .box-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px; }
  #body #modal .box-container .box-title {
    color: #fff;
    width: 100%;
    float: left;
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 20.5px;
    font-weight: 600;
    border-radius: 11px 11px 0px 0px; }

#body #modal .wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start; }
  #body #modal .wrapper .info-wrapper, #body #modal .wrapper .fields-wrapper, #body #modal .wrapper .button-wrapper {
    min-height: 70px;
    width: 100%; }
  #body #modal .wrapper .warn-wrapper {
    width: 100%;
    background: #744848;
    padding: 10px;
    border-radius: 11px;
    margin: 0px 0px 10px 0px;
    color: white; }

#body #modal .options-wrapper, #body #modal .info-wrapper, #body #modal .fields-wrapper {
  width: 100%;
  display: inline-block; }

#body #modal .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px; }
  #body #modal .button-wrapper button {
    display: inline-flex;
    font-size: 16px; }
  #body #modal .button-wrapper .delete:hover {
    background-color: pink; }
  #body #modal .button-wrapper .mobile {
    display: none; }

#body #modal .column {
  flex: 1;
  margin: 10px;
  border-radius: 11px; }
  #body #modal .column.main {
    margin: 0px; }
  #body #modal .column.half {
    flex: 1 1 50%; }
  #body #modal .column.third {
    flex: 1 1 33%; }
  #body #modal .column.left-side {
    margin-left: 0px; }
  #body #modal .column.right-side {
    margin-right: 0px; }

#body #modal .flexbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  #body #modal .flexbox .flex-child {
    border-radius: 11px; }
    #body #modal .flexbox .flex-child.full {
      flex: 1 1 100%;
      max-width: 100%; }
    #body #modal .flexbox .flex-child.half {
      flex: 1 1 50%;
      max-width: 49%; }
    #body #modal .flexbox .flex-child.third {
      flex: 1 1 33%;
      max-width: 32%; }
    #body #modal .flexbox .flex-child.left-side {
      margin-left: 0px; }
    #body #modal .flexbox .flex-child.right-side {
      margin-right: 0px; }
    #body #modal .flexbox .flex-child hr.dashed {
      border-top: 3px dashed #bbbbbb1a; }
    #body #modal .flexbox .flex-child h2 {
      color: #fff;
      width: 100%;
      float: left;
      box-sizing: border-box;
      padding: 10px 20px;
      font-size: 20.5px;
      font-weight: 600;
      border-radius: 11px 11px 0px 0px; }
    #body #modal .flexbox .flex-child h3 {
      box-sizing: border-box;
      padding: 0px 0px 0px 10px;
      font-size: 18.5px;
      font-weight: 600;
      border-radius: 0px;
      color: #fff;
      background: #ffffff21;
      position: relative;
      left: -14px;
      width: 106%; }
    #body #modal .flexbox .flex-child .blocworx-content {
      width: 100%;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: stretch;
      justify-content: flex-start;
      padding: 14px; }
      #body #modal .flexbox .flex-child .blocworx-content:first-of-type {
        padding-top: 14px;
        padding-bottom: 0px; }
      #body #modal .flexbox .flex-child .blocworx-content:last-of-type {
        padding-top: 0px;
        padding-bottom: 14px; }
    #body #modal .flexbox .flex-child .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      min-height: 40px;
      margin-top: 10px;
      width: 100%; }

#body #modal .flexboxHeader {
  margin: 2px 0px 10px 0px; }

#body #modal.dark .box-container .column, #body #modal.dark .box-container .flex-child, #body #modal.dark .flexbox .column, #body #modal.dark .flexbox .flex-child {
  background: #455052; }

#body #modal.dark .box-container .box-title, #body #modal.dark .flexbox .box-title {
  color: white;
  background-color: #343a40cf; }

#body #modal.dark .box-container .bloc-setting-panel .box-title, #body #modal.dark .flexbox .bloc-setting-panel .box-title {
  color: #f0f4f5;
  background: inherit; }

#body #modal.dark .box-container .info-wrapper, #body #modal.dark .box-container p, #body #modal.dark .box-container label, #body #modal.dark .flexbox .info-wrapper, #body #modal.dark .flexbox p, #body #modal.dark .flexbox label {
  color: white; }

#body #modal.light .box-container .column, #body #modal.light .box-container .flex-child, #body #modal.light .flexbox .column, #body #modal.light .flexbox .flex-child {
  background: #eaeaea; }

#body #modal.light .box-container .box-title, #body #modal.light .flexbox .box-title {
  color: white;
  background-color: #455052b5; }

#body #modal.light .box-container .bloc-setting-panel .box-title, #body #modal.light .flexbox .bloc-setting-panel .box-title {
  color: #004854;
  background: inherit; }

#body #modal.light .box-container button p, #body #modal.light .flexbox button p {
  color: white; }

#body #modal.light .box-container .info-wrapper, #body #modal.light .box-container p, #body #modal.light .box-container label, #body #modal.light .flexbox .info-wrapper, #body #modal.light .flexbox p, #body #modal.light .flexbox label {
  color: #004854; }

#body #main #pageContent article md-dialog {
  border-radius: 11px; }
  #body #main #pageContent article md-dialog button md-icon {
    color: white; }

#body #main #pageContent article .box-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px; }
  #body #main #pageContent article .box-container .box-title {
    color: #fff;
    width: 100%;
    float: left;
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 20.5px;
    font-weight: 600;
    border-radius: 11px 11px 0px 0px; }

#body #main #pageContent article .wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-start; }
  #body #main #pageContent article .wrapper .info-wrapper, #body #main #pageContent article .wrapper .fields-wrapper, #body #main #pageContent article .wrapper .button-wrapper {
    min-height: 70px;
    width: 100%; }
  #body #main #pageContent article .wrapper .warn-wrapper {
    width: 100%;
    background: #744848;
    padding: 10px;
    border-radius: 11px;
    margin: 0px 0px 10px 0px;
    color: white; }

#body #main #pageContent article .options-wrapper, #body #main #pageContent article .info-wrapper, #body #main #pageContent article .fields-wrapper {
  width: 100%;
  display: inline-block; }

#body #main #pageContent article .button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-top: 10px; }
  #body #main #pageContent article .button-wrapper button {
    display: inline-flex;
    font-size: 16px; }
  #body #main #pageContent article .button-wrapper .delete:hover {
    background-color: pink; }
  #body #main #pageContent article .button-wrapper .mobile {
    display: none; }

#body #main #pageContent article .column {
  flex: 1;
  margin: 10px;
  border-radius: 11px; }
  #body #main #pageContent article .column.main {
    margin: 0px; }
  #body #main #pageContent article .column.half {
    flex: 1 1 50%; }
  #body #main #pageContent article .column.third {
    flex: 1 1 33%; }
  #body #main #pageContent article .column.left-side {
    margin-left: 0px; }
  #body #main #pageContent article .column.right-side {
    margin-right: 0px; }

#body #main #pageContent article .flexbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  #body #main #pageContent article .flexbox .flex-child {
    border-radius: 11px; }
    #body #main #pageContent article .flexbox .flex-child.full {
      flex: 1 1 100%;
      max-width: 100%; }
    #body #main #pageContent article .flexbox .flex-child.half {
      flex: 1 1 50%;
      max-width: 49%; }
    #body #main #pageContent article .flexbox .flex-child.third {
      flex: 1 1 33%;
      max-width: 32%; }
    #body #main #pageContent article .flexbox .flex-child.left-side {
      margin-left: 0px; }
    #body #main #pageContent article .flexbox .flex-child.right-side {
      margin-right: 0px; }
    #body #main #pageContent article .flexbox .flex-child hr.dashed {
      border-top: 3px dashed #bbbbbb1a; }
    #body #main #pageContent article .flexbox .flex-child h2 {
      color: #fff;
      width: 100%;
      float: left;
      box-sizing: border-box;
      padding: 10px 20px;
      font-size: 20.5px;
      font-weight: 600;
      border-radius: 11px 11px 0px 0px; }
    #body #main #pageContent article .flexbox .flex-child h3 {
      box-sizing: border-box;
      padding: 0px 0px 0px 10px;
      font-size: 18.5px;
      font-weight: 600;
      border-radius: 0px;
      color: #fff;
      background: #ffffff21;
      position: relative;
      left: -14px;
      width: 106%; }
    #body #main #pageContent article .flexbox .flex-child .blocworx-content {
      width: 100%;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: stretch;
      justify-content: flex-start;
      padding: 14px; }
      #body #main #pageContent article .flexbox .flex-child .blocworx-content:first-of-type {
        padding-top: 14px;
        padding-bottom: 0px; }
      #body #main #pageContent article .flexbox .flex-child .blocworx-content:last-of-type {
        padding-top: 0px;
        padding-bottom: 14px; }
    #body #main #pageContent article .flexbox .flex-child .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      min-height: 40px;
      margin-top: 10px;
      width: 100%; }

#body #main #pageContent article .flexboxHeader {
  margin: 2px 0px 10px 0px; }

#body #main div.skin-setting-dark #pageContent article .box-container .column, #body #main div.skin-setting-dark #pageContent article .box-container .flex-child, #body #main div.skin-setting-dark #pageContent article .flexbox .column, #body #main div.skin-setting-dark #pageContent article .flexbox .flex-child {
  background: #455052; }

#body #main div.skin-setting-dark #pageContent article .box-container .box-title, #body #main div.skin-setting-dark #pageContent article .flexbox .box-title {
  color: white;
  background-color: #343a40cf; }

#body #main div.skin-setting-dark #pageContent article .box-container .bloc-setting-panel .box-title, #body #main div.skin-setting-dark #pageContent article .flexbox .bloc-setting-panel .box-title {
  color: #f0f4f5;
  background: inherit; }

#body #main div.skin-setting-dark #pageContent article .box-container .info-wrapper, #body #main div.skin-setting-dark #pageContent article .box-container p, #body #main div.skin-setting-dark #pageContent article .box-container label, #body #main div.skin-setting-dark #pageContent article .flexbox .info-wrapper, #body #main div.skin-setting-dark #pageContent article .flexbox p, #body #main div.skin-setting-dark #pageContent article .flexbox label {
  color: white; }

#body #main div.skin-setting-dark #pageContent article .box-container::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

#body #main div.skin-setting-dark #pageContent article .box-container::-webkit-scrollbar-thumb {
  background: #455052; }

#body #main div.skin-setting-dark #pageContent article .box-container::-webkit-scrollbar-track {
  background: #232d2f; }

#body #main div.skin-setting-dark #pageContent article .box-container {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f; }

#body #main div.skin-setting-bright #pageContent article .box-container .column, #body #main div.skin-setting-bright #pageContent article .box-container .flex-child, #body #main div.skin-setting-bright #pageContent article .flexbox .column, #body #main div.skin-setting-bright #pageContent article .flexbox .flex-child {
  background: #eaeaea; }

#body #main div.skin-setting-bright #pageContent article .box-container .box-title, #body #main div.skin-setting-bright #pageContent article .flexbox .box-title {
  color: white;
  background-color: #455052b5; }

#body #main div.skin-setting-bright #pageContent article .box-container .bloc-setting-panel .box-title, #body #main div.skin-setting-bright #pageContent article .flexbox .bloc-setting-panel .box-title {
  color: #004854;
  background: inherit; }

#body #main div.skin-setting-bright #pageContent article .box-container button p, #body #main div.skin-setting-bright #pageContent article .flexbox button p {
  color: white; }

#body #main div.skin-setting-bright #pageContent article .box-container .info-wrapper, #body #main div.skin-setting-bright #pageContent article .box-container p, #body #main div.skin-setting-bright #pageContent article .box-container label, #body #main div.skin-setting-bright #pageContent article .flexbox .info-wrapper, #body #main div.skin-setting-bright #pageContent article .flexbox p, #body #main div.skin-setting-bright #pageContent article .flexbox label {
  color: #004854; }

#body #main div.skin-setting-bright #pageContent article .box-container::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

#body #main div.skin-setting-bright #pageContent article .box-container::-webkit-scrollbar-thumb {
  background: #343a40; }

#body #main div.skin-setting-bright #pageContent article .box-container::-webkit-scrollbar-track {
  background: #eaeaea; }

#body #main div.skin-setting-bright #pageContent article .box-container {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea; }

@media only screen and (max-width: 367px) {
  #body #modal .box-container, #body #modal .flexbox {
    flex-direction: column; }
    #body #modal .box-container .box-title, #body #modal .flexbox .box-title {
      font-size: 16px; }
    #body #modal .box-container .column, #body #modal .box-container .flex-child, #body #modal .flexbox .column, #body #modal .flexbox .flex-child {
      margin-left: 0px;
      margin-right: 0px; }
    #body #modal .box-container .wrapper .button-wrapper button, #body #modal .flexbox .wrapper .button-wrapper button {
      font-size: 14px; }
    #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
      display: inline-flex; }
    #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
      display: none; }
  #body #modal .flexbox .flex-child {
    margin-bottom: 20px; }
    #body #modal .flexbox .flex-child.half {
      max-width: 100%; }
    #body #modal .flexbox .flex-child.third {
      max-width: 100%; }
  #body #main #pageContent article .box-container, #body #main #pageContent article .flexbox {
    flex-direction: column; }
    #body #main #pageContent article .box-container .box-title, #body #main #pageContent article .flexbox .box-title {
      font-size: 16px; }
    #body #main #pageContent article .box-container .column, #body #main #pageContent article .box-container .flex-child, #body #main #pageContent article .flexbox .column, #body #main #pageContent article .flexbox .flex-child {
      margin-left: 0px;
      margin-right: 0px; }
    #body #main #pageContent article .box-container .wrapper .button-wrapper button, #body #main #pageContent article .flexbox .wrapper .button-wrapper button {
      font-size: 14px; }
    #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
      display: inline-flex; }
    #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
      display: none; }
  #body #main #pageContent article .flexbox .flex-child {
    margin-bottom: 20px; }
    #body #main #pageContent article .flexbox .flex-child.half {
      max-width: 100%; }
    #body #main #pageContent article .flexbox .flex-child.third {
      max-width: 100%; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  #body #modal .box-container, #body #modal .flexbox {
    flex-direction: column; }
    #body #modal .box-container .box-title, #body #modal .flexbox .box-title {
      font-size: 16px; }
    #body #modal .box-container .column, #body #modal .box-container .flex-child, #body #modal .flexbox .column, #body #modal .flexbox .flex-child {
      margin-left: 0px;
      margin-right: 0px; }
    #body #modal .box-container .wrapper .button-wrapper button, #body #modal .flexbox .wrapper .button-wrapper button {
      font-size: 14px; }
    #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
      display: inline-flex; }
    #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
      display: none; }
  #body #modal .flexbox .flex-child {
    margin-bottom: 20px; }
    #body #modal .flexbox .flex-child.half {
      max-width: 100%; }
    #body #modal .flexbox .flex-child.third {
      max-width: 100%; }
  #body #main #pageContent article .box-container, #body #main #pageContent article .flexbox {
    flex-direction: column; }
    #body #main #pageContent article .box-container .box-title, #body #main #pageContent article .flexbox .box-title {
      font-size: 16px; }
    #body #main #pageContent article .box-container .column, #body #main #pageContent article .box-container .flex-child, #body #main #pageContent article .flexbox .column, #body #main #pageContent article .flexbox .flex-child {
      margin-left: 0px;
      margin-right: 0px; }
    #body #main #pageContent article .box-container .wrapper .button-wrapper button, #body #main #pageContent article .flexbox .wrapper .button-wrapper button {
      font-size: 14px; }
    #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
      display: inline-flex; }
    #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
      display: none; }
  #body #main #pageContent article .flexbox .flex-child {
    margin-bottom: 20px; }
    #body #main #pageContent article .flexbox .flex-child.half {
      max-width: 100%; }
    #body #main #pageContent article .flexbox .flex-child.third {
      max-width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; } }

@media (min-width: 979px) and (max-width: 1024px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; } }

@media (min-width: 1025px) and (max-width: 1340px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; } }

@media (min-width: 1340px) {
  #body #modal .box-container .wrapper .column, #body #modal .box-container .wrapper .flex-child, #body #modal .flexbox .wrapper .column, #body #modal .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #modal .box-container .wrapper .button-wrapper .mobile, #body #modal .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #modal .box-container .wrapper .button-wrapper .desktop, #body #modal .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; }
  #body #main #pageContent article .box-container .wrapper .column, #body #main #pageContent article .box-container .wrapper .flex-child, #body #main #pageContent article .flexbox .wrapper .column, #body #main #pageContent article .flexbox .wrapper .flex-child {
    margin-left: 0px;
    margin-right: 0px; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .mobile, #body #main #pageContent article .flexbox .wrapper .button-wrapper .mobile {
    display: none; }
  #body #main #pageContent article .box-container .wrapper .button-wrapper .desktop, #body #main #pageContent article .flexbox .wrapper .button-wrapper .desktop {
    display: inline-flex; } }

#body #main #pageContent article .table-header md-toolbar {
  padding: 10px; }
  @media only screen and (max-width: 599px) {
    #body #main #pageContent article .table-header md-toolbar {
      font-size: 16px; } }

#body #main #pageContent article .table-header mat-grid-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%; }

#body #main #pageContent article .table-header dl {
  margin: 0px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row; }
  #body #main #pageContent article .table-header dl dd {
    margin-left: 4px; }
  #body #main #pageContent article .table-header dl * {
    margin: 0px;
    padding: 0px; }

#body #main #pageContent article .material-table-container {
  width: 100%;
  display: inline-flex;
  max-width: 98.5em; }
  @media only screen and (min-width: 3840px) {
    #body #main #pageContent article .material-table-container {
      max-width: 3840px; } }
  @media only screen and (min-width: 2560px) and (max-width: 3839px) {
    #body #main #pageContent article .material-table-container {
      max-width: 2226px; } }
  @media only screen and (min-width: 1920px) and (max-width: 2559px) {
    #body #main #pageContent article .material-table-container {
      max-width: 1586px; } }
  #body #main #pageContent article .material-table-container table {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: inline-block; }
    #body #main #pageContent article .material-table-container table::-webkit-scrollbar {
      width: 10px;
      height: 10px; }
    #body #main #pageContent article .material-table-container table::-webkit-scrollbar-thumb {
      background: #142124; }
    #body #main #pageContent article .material-table-container table::-webkit-scrollbar-track {
      background: #455052; }
    #body #main #pageContent article .material-table-container table {
      scrollbar-face-color: #142124;
      scrollbar-track-color: #455052; }
  #body #main #pageContent article .material-table-container tbody td button {
    float: none;
    margin: 0px;
    width: 90px; }
  #body #main #pageContent article .material-table-container thead, #body #main #pageContent article .material-table-container tbody {
    display: flex;
    flex-wrap: wrap; }
    #body #main #pageContent article .material-table-container thead tr, #body #main #pageContent article .material-table-container tbody tr {
      display: flex;
      flex: 1 1 100%; }
  #body #main #pageContent article .material-table-container thead tr th, #body #main #pageContent article .material-table-container tbody tr td {
    flex: 1 1 100%;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    overflow-wrap: break-word;
    white-space: normal;
    flex-direction: column;
    align-items: stretch;
    max-width: 300px;
    width: 140px; }

#body #main div.skin-setting-dark #pageContent article .material-table-container table {
  border-radius: 0px 0px 11px 11px;
  border-color: #343a40;
  border-style: solid;
  border-width: 0px 10px 10px 10px; }
  #body #main div.skin-setting-dark #pageContent article .material-table-container table tr th {
    color: white; }
    #body #main div.skin-setting-dark #pageContent article .material-table-container table tr th > * {
      color: white; }
  #body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr:nth-child(even) {
    background: #c8cbcb; }
  #body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr:nth-child(odd) {
    background: #cedade; }
  #body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td {
    color: #004854; }
    #body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td > * {
      color: #004854; }
    #body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td > button {
      color: white; }
    #body #main div.skin-setting-dark #pageContent article .material-table-container table tbody tr td .red-link {
      color: #ee3124; }

#body #main div.skin-setting-bright #pageContent article .material-table-container table {
  border-radius: 0px 0px 11px 11px;
  border-color: #757d7e;
  border-style: solid;
  border-width: 0px 10px 10px 10px; }
  #body #main div.skin-setting-bright #pageContent article .material-table-container table tr th {
    color: #004854; }
    #body #main div.skin-setting-bright #pageContent article .material-table-container table tr th > * {
      color: white; }
  #body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr:nth-child(even) {
    background: #c8cbcb; }
  #body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr:nth-child(odd) {
    background: #cedade; }
  #body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td {
    color: #004854; }
    #body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td > * {
      color: #004854; }
    #body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td > button {
      color: white; }
    #body #main div.skin-setting-bright #pageContent article .material-table-container table tbody tr td .red-link {
      color: #ee3124; }

#body #main #pageContent article md-content {
  padding: 0px;
  border-radius: 11px; }
  #body #main #pageContent article md-content md-card {
    margin: 0px; }
    #body #main #pageContent article md-content md-card md-card-header md-card-header-text .md-subhead {
      margin-top: 4px; }

#body #main #pageContent article md-content:nth-child(odd) {
  margin-top: 20px; }

#body #main div.skin-setting-dark #pageContent article md-content md-card {
  background: #455052; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-header {
    background: #343a40; }
    #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-header md-card-header-text .md-title {
      color: white;
      background: #343a40; }
    #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-header md-card-header-text .md-subhead {
      color: white;
      background: #343a40; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-title md-card-title-text .md-headline {
    color: white; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-title md-card-title-text .md-subhead {
    color: white; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-actions button {
    color: white; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card .md-button {
    color: white;
    background: #343a40; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-content p, #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-content div, #body #main div.skin-setting-dark #pageContent article md-content md-card md-card-content span {
    color: white; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card mat-grid-tile {
    display: inline-flex;
    align-items: center; }
  #body #main div.skin-setting-dark #pageContent article md-content md-card md-toolbar {
    color: #d1d3d3;
    background: #343a40;
    border-radius: 11px 11px 0px 0px;
    display: inline-flex; }

#body #main div.skin-setting-bright #pageContent article md-content md-card {
  background: #eaeaea; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-header {
    background: #757d7e; }
    #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-header md-card-header-text .md-title {
      color: white;
      background: #757d7e; }
    #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-header md-card-header-text .md-subhead {
      color: white;
      background: #757d7e; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-title md-card-title-text .md-headline {
    color: #004854; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-title md-card-title-text .md-subhead {
    color: #004854; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-actions button {
    color: white; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card .md-button {
    color: white;
    background: #757d7e; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-content p, #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-content div, #body #main div.skin-setting-bright #pageContent article md-content md-card md-card-content span {
    color: #004854; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card mat-grid-tile {
    display: inline-flex;
    align-items: center; }
  #body #main div.skin-setting-bright #pageContent article md-content md-card md-toolbar {
    color: #d1d3d3;
    background: #757d7e;
    border-radius: 11px 11px 0px 0px;
    display: inline-flex; }

body.modal-on {
  overflow: hidden; }

body.modal-off {
  overflow: inherit; }

body #main #pageContent article .scan-station-results .pagination-top {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center; }

body #main #pageContent article .scan-station-results .flex-container {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center; }

body #main #pageContent article .scan-station-results .flex-item {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  margin: 0px auto;
  flex-wrap: wrap; }
  body #main #pageContent article .scan-station-results .flex-item select {
    width: 39%;
    margin: 0px 20px 0px 0px; }
  body #main #pageContent article .scan-station-results .flex-item button {
    padding: 10px; }

body #main #pageContent article .scan-station-results .pagination-bottom {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center; }
  body #main #pageContent article .scan-station-results .pagination-bottom .flex-item select {
    width: 230px; }
  body #main #pageContent article .scan-station-results .pagination-bottom label {
    margin-top: 10px; }

body #main #pageContent article .scan-station-front-end .one-third-block {
  margin: 0px 1.5% 30px 0px; }

body #main #pageContent article .scan-station-front-end .two-thirds-block {
  margin: 0px;
  width: 67%; }

body #main #pageContent article .invisible-wrap .bloc-setting-panel .box-title {
  margin: 0px 0px 15px 0px;
  padding: 0px; }

body #main #pageContent article .invisible-wrap .update-number-off-fields .one-third-block, body #main #pageContent article .invisible-wrap .update-number-off-fields .invisible-wrap {
  margin: 15px 0px; }

body #main #pageContent article #dialogContainer {
  display: inline-block;
  margin: 10px auto;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px 20px;
  width: 100%; }
  body #main #pageContent article #dialogContainer p, body #main #pageContent article #dialogContainer input {
    display: inline-block; }
  body #main #pageContent article #dialogContainer p {
    margin: 8px auto; }
  body #main #pageContent article #dialogContainer .alert-container {
    margin-top: 2px;
    margin-bottom: 1px; }
  body #main #pageContent article #dialogContainer .export-filtered-data {
    margin-top: 4px;
    float: left;
    border: none;
    padding: 10px 20px; }

body #main #pageContent article #dialogError, body #main #pageContent article #dialogSuccess {
  position: inherit;
  height: 70px;
  margin-top: 30px; }

body #main #pageContent article #showDeleteAllData {
  float: left;
  margin: 0px 0px 0px 20px;
  border: none;
  padding: 10px 20px; }
  body #main #pageContent article #showDeleteAllData:hover {
    background-color: #ee3124;
    font-weight: bold; }

body #main #pageContent article #close-container {
  width: 40px;
  display: inline-block;
  float: right;
  right: 20px;
  position: relative;
  cursor: pointer; }
  body #main #pageContent article #close-container #close-button {
    display: inline-block;
    font-size: 30px;
    color: white;
    width: 40px; }

body #main #pageContent article .field-parameter-information {
  position: fixed;
  width: 90% !important;
  left: 3% !important;
  top: 7%;
  z-index: 1;
  height: auto;
  overflow: auto;
  background: #374244;
  max-height: 90% !important;
  border-radius: 11px; }

body #main #pageContent article #background {
  background: black;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  /* IE 8 */
  filter: alpha(opacity=80);
  /* IE 5-7 */
  -moz-opacity: 0.8;
  /* Netscape */
  -khtml-opacity: 0.8;
  /* Safari 1.x */
  opacity: 0.8;
  width: 100%;
  position: fixed;
  left: 1px;
  top: 1px;
  height: 100vh;
  z-index: 2;
  display: none; }
  body #main #pageContent article #background.on {
    display: inline-block; }
  body #main #pageContent article #background.off {
    display: none; }

body #main #pageContent article .edit-new-field-container.accordian {
  border: 1px dashed;
  float: left;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  height: auto;
  margin-top: 0px !important;
  width: 100%; }

@media only screen and (max-width: 1150px) {
  body #main #pageContent article
body #main #pageContent article .scan-station-results .delete-scan-station-data button, #body #main #pageContent article .scan-station-results .delete-scan-station-data button {
    clear: none; } }

@media only screen and (max-width: 976px) {
  body #main #pageContent article .invisible-wrap.two-thirds-block, body #main #pageContent article .invisible-wrap.one-third-block, #body #main #pageContent article .invisible-wrap.two-thirds-block, #body #main #pageContent article .invisible-wrap.one-third-block {
    width: 100% !important;
    padding: 0px !important;
    margin-bottom: 20px; } }

body #main #pageContent article blocworx-authentication {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  body #main #pageContent article blocworx-authentication .template {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: center; }
  body #main #pageContent article blocworx-authentication .loading-view {
    display: flex;
    font-size: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center; }
  body #main #pageContent article blocworx-authentication p, body #main #pageContent article blocworx-authentication img {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-content: center; }
  body #main #pageContent article blocworx-authentication p {
    flex: 1;
    font-size: 30px;
    margin-bottom: 20px; }
  body #main #pageContent article blocworx-authentication img {
    flex: 1;
    height: auto;
    justify-content: space-evenly;
    display: inline-flex;
    width: 100px;
    max-height: 100px; }

body #main div.skin-setting-dark #pageContent article blocworx-authentication .template {
  background: #232d2f; }

body #main div.skin-setting-dark #pageContent article blocworx-authentication p, body #main div.skin-setting-dark #pageContent article blocworx-authentication img {
  color: #cedade; }

body #main div.skin-setting-bright #pageContent article blocworx-authentication .template {
  background: #ffffff; }

body #main div.skin-setting-bright #pageContent article blocworx-authentication p, body #main div.skin-setting-bright #pageContent article blocworx-authentication img {
  color: #004854; }

body #main #pageContent article blocworx-forbidden {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  flex: 1 1 auto; }
  body #main #pageContent article blocworx-forbidden .forbidden-access {
    width: 100%;
    text-align: center;
    font-size: 30px;
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 auto; }
    body #main #pageContent article blocworx-forbidden .forbidden-access .button {
      text-decoration: none;
      float: none;
      margin: 0px 10px 0px 0px; }
    body #main #pageContent article blocworx-forbidden .forbidden-access p {
      margin: 0px 0px 0px 10px; }

body #main #pageContent header blocworx-logo .logo {
  margin: 0px 22px;
  display: flex;
  width: 210px;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center; }

body #main #pageContent header blocworx-logo .logo-container {
  display: flex; }
  body #main #pageContent header blocworx-logo .logo-container img {
    margin: 0px;
    padding: 2px;
    max-height: 86px;
    max-width: 210px; }

body #main #pageContent blocworx-copyright {
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 10px 0px; }
  body #main #pageContent blocworx-copyright .copyright-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    min-height: 30px;
    flex-direction: row;
    flex-wrap: wrap; }
    body #main #pageContent blocworx-copyright .copyright-container .reload .reload-version {
      cursor: pointer;
      display: inline-flex;
      height: 100%; }
      body #main #pageContent blocworx-copyright .copyright-container .reload .reload-version span {
        top: 2px;
        position: relative;
        color: #418995;
        font-size: 15px;
        margin-top: 4px; }
    body #main #pageContent blocworx-copyright .copyright-container .images, body #main #pageContent blocworx-copyright .copyright-container .reload {
      height: 100%;
      display: inline-flex;
      align-items: flex-start; }

body #main div.skin-setting-dark #pageContent blocworx-copyright .copyright-container .reload .reload-version {
  color: #f0f4f5; }

body #main div.skin-setting-dark #pageContent blocworx-copyright .copyright-container .light {
  display: none; }

body #main div.skin-setting-bright #pageContent blocworx-copyright .copyright-container .reload .reload-version {
  color: #004854; }

body #main div.skin-setting-bright #pageContent blocworx-copyright .copyright-container .dark {
  display: none; }

body #main header navbar-toggle {
  margin-right: 18px; }

body #main #pageContent blocworx-navbar-mobile {
  display: none; }

body #main #pageContent .mobile .mobile-menu-click {
  float: right;
  width: 44px;
  margin-top: -3px; }

body #main #pageContent .mobile .mobile-menu-click {
  float: right;
  width: 44px;
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  right: 4%;
  top: 8px; }
  body #main #pageContent .mobile .mobile-menu-click span {
    float: left;
    width: 37px;
    height: 4px;
    background: #fff;
    margin-bottom: 6px;
    border-radius: 7px; }

body #main #pageContent article .customer-logo {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  float: none;
  background: #ffffff; }

body #main #pageContent article .customer-logo button {
  font-size: 13px;
  border: none;
  padding: 5px 16px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0; }

body #main #pageContent article blocworx-navbar #blocworxNavbar {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column; }

body #main #pageContent article blocworx-navbar .sidebar .sidebar-item.active span,
body #main #pageContent article blocworx-navbar .sidebar .sidebar-item.active svg path {
  color: #fff;
  fill: #fff; }

body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper, body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout {
  display: flex; }
  body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3, body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 {
    display: flex;
    padding: 0px 0px;
    margin: 0px;
    font-size: 13.75px;
    font-weight: normal; }
    body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3 span, body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 span {
      margin: 0px;
      padding: 0px;
      float: none; }
    body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3 span:nth-child(1), body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 span:nth-child(1) {
      display: flex;
      margin-right: 6px;
      flex-basis: 18%;
      align-items: center;
      justify-content: center; }
    body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper h3 span:nth-child(2), body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout h3 span:nth-child(2) {
      display: flex;
      flex-basis: 80%;
      justify-content: flex-start;
      align-items: center; }

body #main #pageContent article blocworx-navbar .sidebar .menu-item-wrapper-logout {
  float: left;
  width: 100%; }

body #main #pageContent article blocworx-navbar .sidebar .sidebar-item {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 3px;
  position: relative;
  z-index: 11;
  height: 45px; }
  body #main #pageContent article blocworx-navbar .sidebar .sidebar-item i, body #main #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
    font-size: 22px;
    opacity: 1;
    border: none;
    width: 28px; }
  body #main #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
    margin-top: 0px; }
  body #main #pageContent article blocworx-navbar .sidebar .sidebar-item #NAV_Logout {
    width: 25px; }
  body #main #pageContent article blocworx-navbar .sidebar .sidebar-item .sidebar-centered {
    margin-top: 6px; }

body #main #pageContent article blocworx-navbar .sidebar .username-text {
  display: inline-flex;
  clear: both;
  font-size: 16px;
  margin: 0px 0px 15px 0px;
  width: 100%;
  min-height: 50px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  align-content: stretch; }

body #main #pageContent article blocworx-navbar .sidebar .mobile-menu-spacing {
  min-height: 70px; }

body #main #pageContent article blocworx-navbar .sidebar .main-menu > div:last-child .sidebar-item {
  margin-bottom: 0px; }

body #main #pageContent article blocworx-navbar navbar-switch {
  display: flex;
  position: absolute;
  top: 0px;
  z-index: 999;
  flex: 1 1 auto; }
  body #main #pageContent article blocworx-navbar navbar-switch.open {
    justify-content: flex-start;
    padding-left: 16px;
    padding-top: 10px; }
  body #main #pageContent article blocworx-navbar navbar-switch:hover {
    max-width: 233px;
    background: transparent; }
  body #main #pageContent article blocworx-navbar navbar-switch div {
    border-radius: 5px;
    padding: 0px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row; }
    body #main #pageContent article blocworx-navbar navbar-switch div:hover {
      cursor: pointer;
      display: flex;
      padding: 0px;
      min-width: 110px;
      height: 20px;
      align-items: center; }
      body #main #pageContent article blocworx-navbar navbar-switch div:hover .text-hover {
        display: flex; }
  body #main #pageContent article blocworx-navbar navbar-switch i {
    padding: 2px;
    font-size: 13px; }
  body #main #pageContent article blocworx-navbar navbar-switch p {
    font-size: 13px; }
  body #main #pageContent article blocworx-navbar navbar-switch .text-hover {
    display: none; }

body #main div.skin-setting-dark #pageContent article blocworx-navbar {
  background: #232d2f;
  color: #f0f4f5; }
  body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar {
    background: #172124;
    color: #004854; }
    body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .menu-item-wrapper.home {
      border-top: 4px solid #ee3124; }
    body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item {
      background: #d1d3d3;
      color: #004854; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item h3 {
        color: #004854; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item .username-text {
        color: #f0f4f5; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item a {
        color: #ee3124; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item.active {
        background: #004854; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item i, body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
        color: #ee3124;
        fill: #ee3124; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover {
        background: #004854;
        color: #fff; }
        body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover i, body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover svg, body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover path {
          color: #fff;
          fill: #fff; }
        body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .sidebar-item:hover h3 {
          color: #fff;
          fill: #fff; }
    body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .refresh-version {
      color: #004854; }
      body #main div.skin-setting-dark #pageContent article blocworx-navbar .sidebar .refresh-version h3 {
        color: #418995; }

body #main div.skin-setting-dark #pageContent article navbar-switch {
  background: transparent; }
  body #main div.skin-setting-dark #pageContent article navbar-switch div {
    border: 1px dashed #cedade; }
  body #main div.skin-setting-dark #pageContent article navbar-switch.open {
    background: #172124; }

body #main div.skin-setting-bright #pageContent .sidebar .mobile-menu-click span {
  color: #004854 !important;
  background: #004854 !important; }

body #main div.skin-setting-bright #pageContent article blocworx-navbar {
  background: #fff;
  color: #004854; }
  body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar {
    background: #cedade;
    color: #004854; }
    body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .menu-item-wrapper.home {
      border-top: 4px solid #ee3124; }
    body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item {
      background: #fff;
      color: #004854; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item h3 {
        color: #004854; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item .username-text {
        color: #004854; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item a {
        color: #ee3124; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item.active {
        background: #004854; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item i, body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item svg {
        color: #ee3124;
        fill: #ee3124; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover {
        background: #004854;
        color: #fff; }
        body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover i, body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover svg, body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover path {
          color: #fff;
          fill: #fff; }
        body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .sidebar-item:hover h3 {
          color: #fff;
          fill: #fff; }
    body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .refresh-version {
      color: #004854; }
      body #main div.skin-setting-bright #pageContent article blocworx-navbar .sidebar .refresh-version h3 {
        color: #004854; }

body #main div.skin-setting-bright #pageContent article navbar-switch div {
  border: 1px dashed #004854; }

body #main div.skin-setting-bright #pageContent article navbar-switch.open {
  background: #cedade; }

@media only screen and (max-width: 979px) {
  body #main #pageContent .mobile.mobile-menu-click {
    float: right;
    width: 44px;
    margin-top: 20px;
    cursor: pointer;
    position: absolute;
    right: 4%;
    top: 8px; }
  body #main #pageContent .mobile-menu-click span {
    float: left;
    width: 37px;
    height: 4px;
    background: #fff;
    margin-bottom: 6px;
    border-radius: 7px; }
  body #main #pageContent .mobile-menu-click.in-sidebar span:nth-child(1) {
    transform: rotate(45deg) translate(2px, 10px);
    -webkit-transform: rotate(45deg) translate(0px, 8px);
    -moz-transform: rotate(45deg) translate(0px, 7px);
    -o-transform: rotate(45deg) translate(2px, 10px); }
  body #main #pageContent .mobile-menu-click.in-sidebar span:nth-child(2) {
    transform: rotate(-45deg) translate(0px, -8px);
    -webkit-transform: rotate(-45deg) translate(0px, -8px);
    -moz-transform: rotate(-45deg) translate(0px, -8px);
    -o-transform: rotate(-45deg) translate(0px, -8px); }
  body #main #pageContent .mobile-menu-click.in-sidebar span:nth-child(3) {
    opacity: 0; }
  body #main #pageContent article blocworx-navbar {
    display: none; }
    body #main #pageContent article blocworx-navbar.open {
      display: inline-flex; }
    body #main #pageContent article blocworx-navbar .sidebar {
      top: 0px;
      right: 0px;
      position: fixed;
      overflow: auto; }
  body #main #pageContent article navbar-switch {
    display: none !important; }
  body #main #pageContent blocworx-navbar-mobile {
    display: inline-flex; } }

body #main #pageContent article .scan-station-results .delete-scan-station-data {
  padding: 0px;
  margin: 15px 0px; }
  body #main #pageContent article .scan-station-results .delete-scan-station-data > button {
    margin-left: 20px; }

body #main #pageContent article table-blocworx {
  margin-bottom: 20px; }
  body #main #pageContent article table-blocworx .customer-list {
    margin-top: 20px; }

body #main #pageContent article form-blocworx .standard-box {
  margin-top: 30px; }

body #main #pageContent article blocworx-message {
  display: inline-flex;
  width: 100%; }
  body #main #pageContent article blocworx-message .message-box {
    width: 100%; }
  body #main #pageContent article blocworx-message .message-container {
    padding: 12px;
    margin: 10px 0px;
    color: #333;
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between; }
    body #main #pageContent article blocworx-message .message-container .message-header {
      flex-grow: 16;
      flex-shrink: 6;
      font-size: 20px;
      height: 40px;
      display: flex;
      align-items: center; }
    body #main #pageContent article blocworx-message .message-container .close {
      flex-grow: 0;
      flex-shrink: 4;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-width: 40px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 40px; }
      body #main #pageContent article blocworx-message .message-container .close:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.4);
        color: white; }
    body #main #pageContent article blocworx-message .message-container .message {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
      body #main #pageContent article blocworx-message .message-container .message .ico {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        max-width: 30px;
        width: 30px;
        align-content: center;
        font-size: 22px; }
    body #main #pageContent article blocworx-message .message-container.info {
      color: #204a8e;
      background: #c9ddff;
      border: 1px solid #4c699b; }
    body #main #pageContent article blocworx-message .message-container.success {
      color: #2b7515;
      background: #ecffd6;
      border: 1px solid #617c42; }
    body #main #pageContent article blocworx-message .message-container.warning {
      color: #756e15;
      background: #fffbd1;
      border: 1px solid #87803e; }
    body #main #pageContent article blocworx-message .message-container.error {
      color: #ba3939;
      background: #ffe0e0;
      border: 1px solid #a33a3a; }

body #main #pageContent lookup .noResultsFound {
  display: inline-block;
  margin: 20px; }

body #main #pageContent lookup .for-data-look-up {
  padding: 0px;
  box-sizing: border-box; }
  body #main #pageContent lookup .for-data-look-up button {
    float: left;
    padding: 7px 17px;
    font-size: 13px; }
  body #main #pageContent lookup .for-data-look-up td, body #main #pageContent lookup .for-data-look-up th {
    word-wrap: break-word;
    border: none;
    vertical-align: middle; }
  body #main #pageContent lookup .for-data-look-up .stations-result:nth-child(even) {
    margin-top: 20px; }
  body #main #pageContent lookup .for-data-look-up .stations-result .results-title {
    width: 100%; }

body #main #pageContent lookup .standard-box {
  margin: 10px auto; }

body #main #pageContent lookup .please-wait {
  display: inline-block;
  margin: 20px; }

body #main div.skin-setting-dark #pageContent lookup .refine-search {
  color: #004854; }

body #main div.skin-setting-dark #pageContent lookup h1, body #main div.skin-setting-dark #pageContent lookup h2, body #main div.skin-setting-dark #pageContent lookup h3, body #main div.skin-setting-dark #pageContent lookup h4, body #main div.skin-setting-dark #pageContent lookup p, body #main div.skin-setting-dark #pageContent lookup span {
  color: white; }

body #main div.skin-setting-bright #pageContent lookup .refine-search {
  color: #004854; }

body #main div.skin-setting-bright #pageContent lookup h1, body #main div.skin-setting-bright #pageContent lookup h2, body #main div.skin-setting-bright #pageContent lookup h3, body #main div.skin-setting-bright #pageContent lookup h4, body #main div.skin-setting-bright #pageContent lookup p, body #main div.skin-setting-bright #pageContent lookup span {
  color: #004854; }

body #grid-form-station .row {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px;
  background: none;
  margin-left: 0px;
  margin-right: 0px; }

body #grid-form-station .scanStationForm {
  width: 100%;
  height: auto !important; }

body .scan-form-edit #grid-form-station .row {
  border-bottom: 1px dashed #7a7a7a;
  border-left: 1px dashed #7a7a7a;
  height: 100%;
  width: 100%; }

body .scan-form-edit #grid-form-station .container .row > div {
  border-right: 1px dashed #7a7a7a; }

body .scan-form-edit #grid-form-station .container-fluid:first-child .row {
  border-top: 1px dashed #7a7a7a; }

body .scan-form-edit #grid-form-station .scan-station-edit-add .row > div {
  border-right: 1px dashed #7a7a7a; }

body .show-element-borders-1 #grid-form-station .row {
  border-right: 1px dashed #7a7a7a;
  border-bottom: 1px dashed #7a7a7a;
  border-left: 1px dashed #7a7a7a; }

body .show-element-borders-1 #grid-form-station .container .row > div {
  border-right: 1px dashed #7a7a7a; }

body .show-element-borders-1 #grid-form-station .container-fluid:first-child .row {
  border-top: 1px dashed #7a7a7a; }

body .standard-box.scan-form .container-fluid > .row > .col-md-12 {
  padding: 0px; }

body .scan-form-edit .container-fluid .row {
  min-height: 20px; }

body .add-new-item.ng-scope {
  background: #eaeaea;
  border: 1px solid #45505285;
  width: 180px;
  padding: 10px; }

body .skin-setting-dark .scan-form-edit #grid-form-station .container .row .add-new-item.ng-scope {
  background: #374244;
  border: 1px solid #ddd; }

@media only screen and (max-width: 976px) {
  body .add-new-item.ng-scope {
    width: 100%;
    position: relative;
    left: 0 !important; } }

body .add-new-item .select-width {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  flex: 1 1 auto; }
  body .add-new-item .select-width label {
    font-size: 13px;
    display: flex;
    margin: 0;
    padding: 0;
    float: none;
    width: 90%;
    word-break: break-word; }
  body .add-new-item .select-width .close-rules {
    font-size: 18px;
    display: flex;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 4px; }

body .child-element-add-field, body .child-element-add-item {
  font-size: 12px;
  padding: 5px 12px;
  margin-bottom: 10px; }

body .child-element:hover .delete.child-element-add-item, body .full-row-delete-reorder:hover .delete.child-element-add-item {
  opacity: 1; }

body .new-item-plus {
  min-height: 84px; }

body .child-element-add-field {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: auto;
  margin-bottom: 0px;
  z-index: 998;
  max-width: 85%;
  padding: 2px 0px 2px 10px; }

body .grid-fields {
  display: inline-block;
  width: 100%;
  margin: 0px 0px 2px 0px; }

body .scan-form-edit .container-fluid .row {
  min-height: 84px; }

body .customised-layout-0 .new-item-plus {
  max-width: 16.6% !important;
  flex: 0 0 23.333%; }

body .customised-layout-0 .add-new-item {
  width: 50% !important; }

body .child-id {
  position: absolute;
  right: 0px; }

body .customised-layout-0 .full-row-delete-reorder.col-md-11 {
  flex: 0 0 77.667%; }

body .new-item-plus .fa.fa-plus {
  border: none;
  padding: 9px !important;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  text-align: center;
  padding-top: 30px; }

body .new-item-plus .fa.fa-plus::before {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -6px; }

body .full-row-delete-reorder {
  position: absolute;
  right: 0px;
  width: 65px;
  background: #374244;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  opacity: 0;
  border: none;
  max-width: none !important;
  border: none !important;
  height: 100%; }

body .full-row-delete-reorder {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  right: 0px;
  bottom: 0px;
  height: 40px;
  width: 110px; }

body .reorder-grid-row {
  float: left;
  width: 100%;
  text-align: center;
  top: 15px;
  position: absolute;
  width: 110px;
  left: 0px; }

body .reorder-grid-row i {
  float: left; }

body .reorder-grid-row i + i {
  float: right; }

body .full-row-delete-reorder .delete.child-element-add-item {
  float: left;
  border: none;
  padding: 5px;
  margin-top: 6px;
  font-size: 9px;
  box-sizing: border-box;
  width: 70% !important;
  z-index: 998; }

body .row:hover .full-row-delete-reorder {
  opacity: 1; }

body .grid-add-new-row {
  margin: 20px 12px 10px 10px; }

body .child-element:hover .resize-element, body .child-element:hover .child-element-add-field, body .row-blocworx:hover .resize-element, body .row-blocworx:hover .child-element-add-field {
  opacity: 1; }

body .child-element-add-field:hover {
  left: 0px;
  margin: 0px;
  display: inline-block; }

@media only screen and (max-width: 976px) {
  .outer-container-edit-field:hover .edit-field-popup-on-hover {
    left: 0px;
    top: 4px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0px;
    display: inline-flex;
    z-index: 999;
    float: none;
    position: relative; }
    .outer-container-edit-field:hover .edit-field-popup-on-hover .invisible-wrap {
      width: 100%; } }

.child-element-add-field:hover {
  font-weight: bold; }
  @media only screen and (max-width: 976px) {
    .child-element-add-field:hover {
      font-weight: bold;
      float: none;
      left: -10px;
      width: 100%; } }

body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 30px;
  max-width: 130px;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  height: 50px; }

body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-left {
  flex: 1 1 80%;
  display: flex;
  width: 110px;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  height: 30px; }

body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-right {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  width: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  body #main #pageContent article .parent .content .row:hover grid-delete-row .delete-row-right i {
    cursor: pointer; }

body #main #pageContent article .parent .content .row:hover grid-delete-row button {
  width: 100px;
  display: flex;
  font-size: 11px;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0; }

body #main #pageContent article .parent .content grid-delete-row .delete-row-container {
  display: none; }

body #main #pageContent article .parent .content grid-delete-row .delete-row-left {
  display: none; }

body #main #pageContent article .parent .content grid-delete-row .delete-row-right {
  display: none; }

body #main #pageContent article .parent .content grid-delete-row button {
  display: none; }

#grid-semaphore {
  float: right;
  color: #004854; }
  #grid-semaphore span {
    float: left;
    margin-top: 0px;
    font-size: 19px;
    font-weight: 500; }
  #grid-semaphore .grid-semaphore-button {
    width: 53px;
    height: 26px;
    border: 1px solid #fff;
    float: right;
    margin-left: 10px;
    border-radius: 9px;
    position: relative;
    box-sizing: border-box;
    padding: 2px;
    cursor: pointer;
    background: #4d7c84; }
    #grid-semaphore .grid-semaphore-button div {
      float: left;
      width: 46%;
      height: 100%;
      border-radius: 15px; }
    #grid-semaphore .grid-semaphore-button > .gridOn {
      background: #faa21a;
      -webkit-transition: all .25s linear 0s;
      -moz-transition: all .25s linear 0s;
      -ms-transition: all .25s linear 0s;
      -o-transition: all .25s linear 0s;
      transition: all .25s linear 0s; }
    #grid-semaphore .grid-semaphore-button > .gridOff {
      background: #ee3124;
      -webkit-transform: translateX(119%);
      -moz-transform: translateX(119%);
      -ms-transform: translateX(119%);
      -o-transform: translateX(119%);
      transform: translateX(119%); }

body #main #pageContent article #scanStationHeader {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 11px 11px 0px 0px;
  display: flex;
  height: 50px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
  justify-content: space-around; }
  body #main #pageContent article #scanStationHeader .header-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0px;
    flex: 1 1 auto; }
  body #main #pageContent article #scanStationHeader .header-title {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex: 5 5 auto;
    height: 100%;
    align-items: center;
    padding: 0px 20px;
    justify-content: flex-start; }
  body #main #pageContent article #scanStationHeader .scan-station-header-close {
    cursor: pointer;
    z-index: 1;
    font-size: 30px;
    background: none;
    position: absolute;
    right: 0px;
    height: 50px;
    padding: 0px 20px;
    font-weight: bold;
    vertical-align: middle;
    line-height: 50px; }
  body #main #pageContent article #scanStationHeader .viewMode, body #main #pageContent article #scanStationHeader .editMode {
    order: 1; }
  body #main #pageContent article #scanStationHeader .scan-station-header-edit-data {
    display: flex;
    order: 2;
    flex: 1 1 auto;
    border-radius: 0px;
    background-color: rgba(247, 147, 54, 0.7); }
  body #main #pageContent article #scanStationHeader .scan-station-header-cancel-viewing, body #main #pageContent article #scanStationHeader .scan-station-header-cancel-edit {
    border-radius: 0px 11px 0px 0px;
    background-color: rgba(128, 34, 34, 0.6);
    display: flex;
    order: 3;
    flex: 1 1 auto; }
  body #main #pageContent article #scanStationHeader .viewMode, body #main #pageContent article #scanStationHeader .editMode, body #main #pageContent article #scanStationHeader .scan-station-header-edit-data,
  body #main #pageContent article #scanStationHeader .scan-station-header-cancel-edit, body #main #pageContent article #scanStationHeader .scan-station-header-cancel-viewing {
    justify-content: center;
    color: #fff !important; }
  body #main #pageContent article #scanStationHeader .viewMode, body #main #pageContent article #scanStationHeader .editMode, body #main #pageContent article #scanStationHeader .defaultMode {
    border-radius: 11px 0px 0px 0px; }

body #main #pageContent article .show-more-mode-true #scanStationHeader {
  background-color: rgba(124, 178, 197, 0.7); }

body #main #pageContent article .edit-mode-true #scanStationHeader {
  background-color: rgba(124, 178, 197, 0.7); }

body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled input, body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled textarea, body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled button, body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled select {
  color: #fff !important;
  opacity: 1 !important;
  background: #707070 !important;
  cursor: default; }

body #main #pageContent article .inner-standard-box-wrapper fieldset:disabled button.selected {
  border-color: #303030 !important;
  background: #565656 !important; }

body #main div.skin-setting-dark #pageContent article #scanStationHeader .scan-station-header-close {
  color: #ffffff; }

body #main div.skin-setting-dark #pageContent article .inner-standard-box-wrapper fieldset:disabled button:not(.selected):hover {
  border-color: #455052 !important; }

body #main div.skin-setting-bright #pageContent article #scanStationHeader .scan-station-header-close {
  color: #004854; }

body #main div.skin-setting-bright #pageContent article .inner-standard-box-wrapper fieldset:disabled button:not(.selected):hover {
  border-color: #343a40 !important; }

@media (min-width: 1921px) and (max-width: 2570px) {
  body #main #pageContent article .parent, #body #main #pageContent article .parent {
    max-width: 2531px; } }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body.skin-setting-bright .customised-layout-0 #scanStationHeader .scan-station-header-edit-data, body.skin-setting-bright .customised-layout-0 #scanStationHeader .scan-station-header-cancel-viewing, body.skin-setting-dark .customised-layout-0 #scanStationHeader .scan-station-header-edit-data, body.skin-setting-dark .customised-layout-0 #scanStationHeader .scan-station-header-cancel-viewing {
    width: 50%;
    padding: 0 8px;
    font-size: 12px; }
  body.skin-setting-bright .customised-layout-0 #scanStationHeader .scan-station-header-cancel-edit, body.skin-setting-dark .customised-layout-0 #scanStationHeader .scan-station-header-cancel-edit {
    font-size: 12px; }
  body.skin-setting-bright .customised-layout-0 #scanStationHeader .box-title .editMode, body.skin-setting-bright .customised-layout-0 #scanStationHeader .box-title .header-title, body.skin-setting-dark .customised-layout-0 #scanStationHeader .box-title .editMode, body.skin-setting-dark .customised-layout-0 #scanStationHeader .box-title .header-title {
    display: none; } }

@media (min-width: 481px) and (max-width: 767px) {
  body #main #pageContent article #scanStationHeader .scan-station-header-edit-data, body #main #pageContent article #scanStationHeader .scan-station-header-cancel-viewing, body #main #pageContent article #scanStationHeader .header-title {
    font-size: 14px;
    padding: 0px 20px; } }

@media (min-width: 320px) and (max-width: 480px) {
  body #main #pageContent article #scanStationHeader .header-title {
    font-size: 12px;
    padding: 0px 5px; }
  body #main #pageContent article #scanStationHeader .defaultMode {
    font-size: 14px;
    padding: 0px 20px; } }

#body #main #pageContent article .table-container {
  margin: 1em auto;
  width: 100%;
  border-collapse: collapse;
  display: inline-block;
  overflow: auto; }
  #body #main #pageContent article .table-container td {
    padding: 0.5em; }
  #body #main #pageContent article .table-container th {
    padding: 0.5em; }
  #body #main #pageContent article .table-container .flex-show-more {
    text-align: center;
    max-width: 0.1%; }
  #body #main #pageContent article .table-container .edit-data-link {
    text-align: center;
    max-width: 0.1%; }
  #body #main #pageContent article .table-container .export-column-header {
    text-align: center;
    max-width: 0.2%; }
  #body #main #pageContent article .table-container .edit-header {
    text-align: center;
    max-width: 0.1%; }
  #body #main #pageContent article .table-container .edit {
    text-align: center;
    max-width: 0.1%; }
  #body #main #pageContent article .table-container .export-column-data {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: space-between; }
    #body #main #pageContent article .table-container .export-column-data .invisible-wrap {
      margin-bottom: 0px; }
    #body #main #pageContent article .table-container .export-column-data in-data-export-button {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      flex-direction: row; }
  #body #main #pageContent article .table-container tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit; }

#body #main #pageContent article .flex-row {
  width: 1%; }

#body #main #pageContent article .flex-row,
#body #main #pageContent article .flex-cell {
  text-align: left; }

@media only screen and (max-width: 767px) {
  #body #main #pageContent article .flex-row {
    width: 100%; }
  #body #main #pageContent article .table-container th,
  #body #main #pageContent article .table-container td {
    border: 0; } }

#body #main #pageContent article .is-in-iframe.compact-view .form-results-section.table td, #body #main #pageContent article .is-in-iframe.compact-view .form-results-section.table th {
  padding: 2px;
  font-size: 12px;
  font-weight: 400; }

#body #main #pageContent article .form-results-section {
  margin-top: 20px; }
  #body #main #pageContent article .form-results-section td, #body #main #pageContent article .form-results-section th {
    vertical-align: middle; }
  #body #main #pageContent article .form-results-section .graph {
    text-align: center;
    width: 200px; }
  #body #main #pageContent article .form-results-section .actions {
    text-align: center;
    max-width: 100px;
    width: 100px;
    padding: 0; }
    #body #main #pageContent article .form-results-section .actions a {
      cursor: pointer; }
  #body #main #pageContent article .form-results-section.compact-view-1 {
    overflow-x: scroll; }
    #body #main #pageContent article .form-results-section.compact-view-1 td, #body #main #pageContent article .form-results-section.compact-view-1 th {
      padding: 2px;
      font-size: 12px;
      font-weight: 400; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1574px; }
  #body #main #pageContent article .form-results-section.word-break-true td, #body #main #pageContent article .form-results-section.word-break-true th {
    word-break: break-all; }

#body #main #pageContent article .scan-results {
  margin-bottom: 15px; }

#body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px dashed #0000002b; }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order div {
    display: flex;
    justify-content: center;
    text-align: center; }
    #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order div label {
      margin: 0px; }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements {
    width: 100%;
    min-height: 70px; }
    #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements div {
      float: none; }
    #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .scan-station-data-elements select {
      margin: 0px; }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-single-field {
    display: flex;
    align-items: center; }
  #body #main #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header {
    margin-bottom: 10px; }

#body #main div.skin-setting-dark #pageContent article .form-results-section::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

#body #main div.skin-setting-dark #pageContent article .form-results-section::-webkit-scrollbar-thumb {
  background: #142124; }

#body #main div.skin-setting-dark #pageContent article .form-results-section::-webkit-scrollbar-track {
  background: #455052; }

#body #main div.skin-setting-dark #pageContent article .form-results-section {
  scrollbar-face-color: #142124;
  scrollbar-track-color: #455052; }

#body #main div.skin-setting-dark #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header {
  background-color: #455052; }

#body #main div.skin-setting-bright #pageContent article .form-results-section::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

#body #main div.skin-setting-bright #pageContent article .form-results-section::-webkit-scrollbar-thumb {
  background: #343a40; }

#body #main div.skin-setting-bright #pageContent article .form-results-section::-webkit-scrollbar-track {
  background: #eaeaea; }

#body #main div.skin-setting-bright #pageContent article .form-results-section {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea; }

#body #main div.skin-setting-bright #pageContent article #scan-station-data-field-edit .manage-field-results-order .field-order-header {
  background-color: #eaeaea; }

@media only screen and (max-width: 367px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 310px; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 280px; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 705px; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 660px; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 718px;
    max-width: 870px; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1784px; } }

@media (min-width: 979px) and (max-width: 1025px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 496px; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1784px; } }

@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 708px;
    max-width: 1017px; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1574px; } }

@media (min-width: 1341px) and (max-width: 1920px) {
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 1585px; } }

@media (min-width: 1921px) {
  #body #main #pageContent article .form-results-section.custom-compact {
    max-width: 708px;
    max-width: 1017px; }
  #body #main #pageContent article .form-results-section.custom-full {
    overflow-x: scroll;
    max-width: 100%; } }

#body #main #pageContent article scan-station-results .field-count-box * {
  overflow-wrap: anywhere; }

#body #main #pageContent article scan-station-results .field-count-box h4 {
  font-size: 22px; }

@media only screen and (max-width: 367px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 18px; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 18px; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

#scanStationForm .mainFieldset {
  display: inline-block;
  width: 100%; }

body #main .close-popup {
  font-size: 28px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 1;
  cursor: pointer; }

body #main .field-rules-close-popup {
  margin-top: -40px;
  width: 25px !important;
  font-size: 28px;
  position: sticky;
  right: 20px;
  left: 96%;
  top: -23px;
  z-index: 1;
  line-height: 1;
  cursor: pointer; }

body #main .close-popup:hover {
  color: #f65d5d; }

body #main #pageContent article .new-edit-field-parameters {
  background: #374244;
  float: left;
  width: 100%;
  border-radius: 11px 11px 0px 0px; }
  body #main #pageContent article .new-edit-field-parameters .checkbox-text-right {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    justify-content: flex-start; }
    body #main #pageContent article .new-edit-field-parameters .checkbox-text-right input {
      float: none;
      clear: none;
      margin: 0px 10px 0px 0px;
      flex: 1 1 auto; }
    body #main #pageContent article .new-edit-field-parameters .checkbox-text-right span {
      flex: 1 1 100%; }
  body #main #pageContent article .new-edit-field-parameters .inner-standard-box-wrapper {
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 6px; }
  body #main #pageContent article .new-edit-field-parameters .box-title {
    padding: 0px; }
  body #main #pageContent article .new-edit-field-parameters > *:not(.box-title) {
    padding: 0px 20px; }

body #main #pageContent article .standard-box {
  float: left;
  background: #eaeaea;
  width: 100%;
  box-sizing: border-box;
  border-radius: 11px; }
  body #main #pageContent article .standard-box .box-title {
    color: #fff;
    width: 100%;
    float: left;
    box-sizing: border-box;
    padding: 10px 20px;
    /*margin: 10px 0px 10px 0px;*/
    font-size: 20.5px;
    font-weight: 600;
    border-radius: 11px 11px 0px 0px; }
  body #main #pageContent article .standard-box.my-active-jobs {
    padding-bottom: 18px; }

body #main #pageContent article .standard-box-show-more {
  height: 100%;
  top: 10px;
  padding-bottom: 10px !important; }

body #main #pageContent article .margin-box {
  margin: 10px 0px; }

body #main #pageContent article .data-tracking-fields {
  position: fixed;
  z-index: 999999;
  top: 2%;
  left: 2%;
  width: 95%;
  height: 95%;
  border-radius: 11px 11px 0px 0px; }
  body #main #pageContent article .data-tracking-fields .close-rules-scan-station-edit {
    position: absolute; }

body #main #pageContent article .inner-standard-box-wrapper {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 14px; }

body #main #pageContent article .invisible-wrap.first {
  margin-left: 0px !important; }

body #main #pageContent article .invisible-wrap.last {
  margin-left: 0px !important;
  margin-right: 0px !important; }

body #main #pageContent article .invisible-wrap.two-thirds-block {
  width: 67.5%; }

body #main #pageContent article .invisible-wrap.one-quarter-block {
  width: 22%; }

body #main #pageContent article .invisible-wrap.one-fifth-block {
  width: 20%; }

body #main #pageContent article .invisible-wrap.two-fifths-block {
  width: 40%; }

body #main #pageContent article .invisible-wrap.three-fifths-block {
  width: 60%; }

body #main #pageContent article .invisible-wrap.four-fifths-block {
  width: 80%; }

body #main #pageContent article .invisible-wrap.three-quarters-block {
  width: 75%; }

body #main #pageContent article .invisible-wrap.one-half-block {
  width: 48.4%; }

body #main #pageContent article .invisible-wrap.one-third-block {
  width: 31%; }

body #main #pageContent article .invisible-wrap.one-forth-block {
  width: 25.0%; }

body #main #pageContent article .invisible-wrap.last + .invisible-wrap.first {
  clear: both;
  margin-top: 28px; }

body #main #pageContent article .invisible-wrap.full-size-block-alert {
  position: fixed;
  z-index: 99999999;
  left: 5%;
  width: 90%;
  height: 90%;
  box-sizing: border-box;
  top: 1%;
  padding-bottom: 0px;
  overflow: hidden;
  margin: 0px auto;
  float: left; }

body #main #pageContent article .invisible-wrap.full-size-block {
  margin: 10px 0px;
  float: left;
  width: 100%; }
  body #main #pageContent article .invisible-wrap.full-size-block.job-list-container {
    margin-bottom: 31px; }

body #main #pageContent article .invisible-wrap .loading-view {
  width: 100%;
  float: left;
  margin: 0px 0px 30px 0px;
  font-size: 18px;
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); }

body #main #pageContent article .invisible-wrap .loading-view img {
  margin-top: 30px; }

body #main #pageContent article .overflow-container {
  overflow-y: auto;
  height: 100%;
  width: 100%; }

body #main #pageContent article .left-float {
  float: left; }

body #main #pageContent .modal-scrollable {
  overflow-y: auto;
  max-height: 89.7vh; }

body #main #pageContent .data-tracking-fields.edit-mode-true > div > div > div.close-popup {
  right: 40px;
  width: 30px;
  margin-top: -50px;
  z-index: 1;
  position: absolute;
  top: 58px; }

body #main #pageContent .data-tracking-fields.edit-mode-true > div > div > div.close-popup {
  right: 40px;
  width: 30px;
  margin-top: -50px;
  z-index: 1;
  position: absolute;
  top: 58px; }

body #main #pageContent .tick-to-show-hidden-fields {
  float: right;
  margin: 0px 16px 20px 0px;
  width: 232px; }

body #main div.skin-setting-dark #pageContent article .standard-box {
  background: #455052;
  padding: 0px; }
  body #main div.skin-setting-dark #pageContent article .standard-box .box-title {
    color: white;
    background-color: #343a40cf; }
  body #main div.skin-setting-dark #pageContent article .standard-box .bloc-setting-panel .box-title {
    color: #f0f4f5;
    background: inherit; }

body #main div.skin-setting-dark #pageContent article .inner-standard-box-wrapper h4 {
  color: #f0f4f5; }

body #main div.skin-setting-dark #pageContent article .standard-box::-webkit-scrollbar, body #main div.skin-setting-dark #pageContent article .invisible-wrap::-webkit-scrollbar, body #main div.skin-setting-dark #pageContent article .overflow-container::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

body #main div.skin-setting-dark #pageContent article .standard-box::-webkit-scrollbar-thumb, body #main div.skin-setting-dark #pageContent article .invisible-wrap::-webkit-scrollbar-thumb, body #main div.skin-setting-dark #pageContent article .overflow-container::-webkit-scrollbar-thumb {
  background: #455052; }

body #main div.skin-setting-dark #pageContent article .standard-box::-webkit-scrollbar-track, body #main div.skin-setting-dark #pageContent article .invisible-wrap::-webkit-scrollbar-track, body #main div.skin-setting-dark #pageContent article .overflow-container::-webkit-scrollbar-track {
  background: #232d2f; }

body #main div.skin-setting-dark #pageContent article .standard-box, body #main div.skin-setting-dark #pageContent article .invisible-wrap, body #main div.skin-setting-dark #pageContent article .overflow-container {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f; }

body #main div.skin-setting-dark #pageContent article .modal-scrollable::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

body #main div.skin-setting-dark #pageContent article .modal-scrollable::-webkit-scrollbar-thumb {
  background: #455052; }

body #main div.skin-setting-dark #pageContent article .modal-scrollable::-webkit-scrollbar-track {
  background: #232d2f; }

body #main div.skin-setting-dark #pageContent article .modal-scrollable {
  scrollbar-face-color: #455052;
  scrollbar-track-color: #232d2f; }

body #main div.skin-setting-bright #pageContent article .standard-box {
  background: #eaeaea; }
  body #main div.skin-setting-bright #pageContent article .standard-box .box-title {
    color: white;
    background-color: #455052b5; }
  body #main div.skin-setting-bright #pageContent article .standard-box .bloc-setting-panel .box-title {
    color: #004854;
    background: inherit; }

body #main div.skin-setting-bright #pageContent article .inner-standard-box-wrapper h4 {
  color: #004854; }

body #main div.skin-setting-bright #pageContent article .standard-box::-webkit-scrollbar, body #main div.skin-setting-bright #pageContent article .invisible-wrap::-webkit-scrollbar, body #main div.skin-setting-bright #pageContent article .overflow-container::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

body #main div.skin-setting-bright #pageContent article .standard-box::-webkit-scrollbar-thumb, body #main div.skin-setting-bright #pageContent article .invisible-wrap::-webkit-scrollbar-thumb, body #main div.skin-setting-bright #pageContent article .overflow-container::-webkit-scrollbar-thumb {
  background: #343a40; }

body #main div.skin-setting-bright #pageContent article .standard-box::-webkit-scrollbar-track, body #main div.skin-setting-bright #pageContent article .invisible-wrap::-webkit-scrollbar-track, body #main div.skin-setting-bright #pageContent article .overflow-container::-webkit-scrollbar-track {
  background: #eaeaea; }

body #main div.skin-setting-bright #pageContent article .standard-box, body #main div.skin-setting-bright #pageContent article .invisible-wrap, body #main div.skin-setting-bright #pageContent article .overflow-container {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea; }

body #main div.skin-setting-bright #pageContent article .modal-scrollable::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

body #main div.skin-setting-bright #pageContent article .modal-scrollable::-webkit-scrollbar-thumb {
  background: #343a40; }

body #main div.skin-setting-bright #pageContent article .modal-scrollable::-webkit-scrollbar-track {
  background: #eaeaea; }

body #main div.skin-setting-bright #pageContent article .modal-scrollable {
  scrollbar-face-color: #343a40;
  scrollbar-track-color: #eaeaea; }

@media only screen and (max-width: 367px) {
  body #main #pageContent article .user-buttons button, #body #main #pageContent article .user-buttons button {
    margin: 0px; }
  body #main #pageContent article .form-results-section, #body #main #pageContent article .form-results-section {
    overflow: auto;
    display: inline-block; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  body #main #pageContent article .user-buttons button, #body #main #pageContent article .user-buttons button {
    margin: 0px; }
  body #main #pageContent article .form-results-section, #body #main #pageContent article .form-results-section {
    overflow: auto;
    display: inline-block; } }

.angularjs-datetime-picker {
  z-index: 9; }

#body #main #pageContent article #addedFieldRules .rule-name, #body #main #pageContent article #addedFieldRules .rule-order {
  margin-bottom: 4px; }

#body #main #pageContent article #addedFieldRules .rule-details p {
  margin-bottom: 4px;
  font-size: 16px; }

#body #main #pageContent article .populate-other-fields-from-response .show-false {
  display: none; }

#body #main #pageContent article .existing-populating-rule-responses {
  float: left;
  width: 100%; }

#body #main #pageContent article .rule-details custom_response_print .print span {
  float: left;
  width: 100%; }

#body #main #pageContent article .edit-rule, #body #main #pageContent article update-rule {
  float: right;
  margin-right: 5px;
  cursor: pointer;
  color: #f79336b3;
  position: absolute;
  right: 22px;
  top: 3px; }
  #body #main #pageContent article .edit-rule .fa-edit, #body #main #pageContent article update-rule .fa-edit {
    border: none; }

#body #main #pageContent article .delete-rule, #body #main #pageContent article .cancel-edit-rule {
  float: right;
  margin-right: 5px;
  cursor: pointer;
  color: red;
  position: absolute;
  right: 0px;
  top: 3px; }

#body #main #pageContent article #superUserDebug {
  border: 10px solid #ff4500;
  padding: 5px 0px 5px 10px;
  margin: 25px 0px 25px 0px; }

#body #main #pageContent article custom_response_print .custom-response-container {
  padding: 6px 0px 2px 0px; }
  #body #main #pageContent article custom_response_print .custom-response-container .print {
    font-size: 16px; }
  #body #main #pageContent article custom_response_print .custom-response-container .custom-response p {
    margin-top: 8px; }

#body #main #pageContent article field-rule .print {
  margin: 4px 0px;
  width: 70%; }

#body #main #pageContent article field-rule .options {
  width: 29%; }
  #body #main #pageContent article field-rule .options .remove {
    width: 80px;
    font-size: 13px;
    padding: 10px;
    background: #8b0000 !important;
    display: inline-block; }

#body #main #pageContent article field-rule .print, #body #main #pageContent article field-rule .options {
  display: inline-block;
  vertical-align: middle;
  line-height: 24px; }

#body #main #pageContent article .newrule-form .error-box {
  padding: 11px;
  font-size: 13px;
  margin-top: 20px;
  text-align: left; }

#body #main #pageContent article .newrule-form .rule-order-input {
  display: none; }

#body #main #pageContent article .manage-conditional-status .title {
  margin-bottom: 10px; }

#body #main #pageContent article .manage-conditional-status .last {
  margin-bottom: 0px; }

@media only screen and (max-width: 479px) {
  #body #main .mobile-off {
    display: none !important; } }

@media only screen and (min-width: 480px) and (max-width: 768px) {
  #body #main .tablet-off {
    display: none !important; } }

@media only screen and (min-width: 769px) and (max-width: 979px) {
  #body #main .tablet-off {
    display: none !important; } }

body #main #pageContent article .parent .content .outer-container-edit-field .edit-field-popup-on-hover {
  position: absolute;
  overflow: hidden;
  display: none; }

body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover {
  left: -5px;
  top: 0px;
  width: 100%;
  height: auto;
  padding: 10px 10px;
  margin: 0px;
  display: inline-flex;
  z-index: 999;
  flex-direction: row;
  flex-wrap: wrap;
  float: left;
  max-width: 270px;
  min-width: 156px; }
  body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .title, body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements {
    width: 100%; }
  body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .title {
    display: flex;
    flex: 1 1 auto;
    padding: 4px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-left: 5px; }
    body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .title label {
      font-size: 15px;
      font-weight: bold;
      margin: 0px;
      padding: 2px; }
  body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center; }
    body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements .arrows {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      align-content: center;
      justify-content: space-evenly; }
      body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements .arrows .reorder-field-arrows {
        cursor: pointer;
        position: relative;
        margin: 5px; }
    body #main #pageContent article .parent .content .outer-container-edit-field:hover .edit-field-popup-on-hover .elements .buttons {
      display: flex;
      flex: 1 1 70%;
      flex-direction: column; }

body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: 0px;
  min-height: 265px; }
  body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview thead, body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch; }
  body #main #pageContent article .parent .content .outer-container-edit-field table.for-preview tr {
    display: flex;
    justify-content: space-around; }

body #main div.skin-setting-dark #pageContent article .parent .content .outer-container-edit-field .edit-field-popup-on-hover .title {
  background-color: #0001; }

body #main div.skin-setting-bright #pageContent article .parent .content .outer-container-edit-field .edit-field-popup-on-hover .title {
  background-color: #fff4; }

.pie-chart-container canvas {
  display: none; }
  .pie-chart-container canvas.chartjs-render-monitor {
    display: inline-block; }

.pie-chart-container label .pie-chart-semaphore {
  color: inherit; }
  .pie-chart-container label .pie-chart-semaphore .pie-chart-text {
    color: inherit; }

.pie-chart-container .pie-chart-semaphore {
  float: right;
  color: #004854; }
  .pie-chart-container .pie-chart-semaphore span {
    float: left;
    margin-top: 0px;
    font-size: 19px;
    font-weight: 500; }
  .pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button {
    width: 53px;
    height: 26px;
    border: 1px solid #fff;
    float: right;
    margin-left: 10px;
    border-radius: 9px;
    position: relative;
    box-sizing: border-box;
    padding: 2px;
    cursor: pointer;
    background: #4d7c84; }
    .pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button div {
      float: left;
      width: 46%;
      height: 100%;
      border-radius: 15px; }
    .pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button > .pieChartOn {
      background: #faa21a;
      -webkit-transition: all .25s linear 0s;
      -moz-transition: all .25s linear 0s;
      -ms-transition: all .25s linear 0s;
      -o-transition: all .25s linear 0s;
      transition: all .25s linear 0s; }
    .pie-chart-container .pie-chart-semaphore .pie-chart-semaphore-button > .pieChartOff {
      background: #ee3124;
      -webkit-transform: translateX(119%);
      -moz-transform: translateX(119%);
      -ms-transform: translateX(119%);
      -o-transform: translateX(119%);
      transform: translateX(119%); }

.pie-chart-container-print {
  max-width: 200px !important;
  width: 200px !important; }

body #main .front-page-side .icon-link.for-pages a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border: 2px solid #ffffff;
  right: 25px;
  content: "";
  bottom: 8px;
  opacity: 0.3; }

body #main .front-page-side .icon-link.for-pages a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left; }

body #main .front-page-side .icon-link.for-pages.link-type-url a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  border-left: 6px solid #fff;
  right: 25px;
  content: "";
  bottom: 7px;
  opacity: 0.3;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

body #main .front-page-side .icon-link.for-pages.link-type-url a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left; }

body #main .front-page-side .icon-link.for-pages.link-type-module a::after {
  display: none; }

body #main .edit-page-side .icon-link-wrapper.for-pages a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border: 2px solid #ffffff;
  right: 25px;
  content: "";
  bottom: 8px;
  opacity: 0.3; }

body #main .edit-page-side .icon-link-wrapper.for-pages a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left; }

body #main .edit-page-side .icon-link-wrapper.for-pages.link-type-url a:not(.delete-page-link)::after {
  position: absolute;
  width: 9px;
  height: 9px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  border-left: 6px solid #fff;
  right: 25px;
  content: "";
  bottom: 7px;
  opacity: 0.3;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

body #main .edit-page-side .icon-link-wrapper.for-pages.link-type-url a:not(.delete-page-link) {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  float: left; }

body #main .edit-page-side .icon-link-wrapper.for-pages.link-type-module a::after {
  display: none; }

body #main .single-page-in-list button {
  font-size: 13px;
  padding: 7px 12px; }

body #main button i {
  border: none; }

body #main .manage-page-pop-up {
  position: absolute;
  right: 50px;
  width: 357px;
  z-index: 1; }
  body #main .manage-page-pop-up button {
    float: left;
    margin-top: 10px;
    padding-left: 18px;
    margin-left: 0px;
    font-size: 14px;
    clear: both !important;
    padding-right: 18px; }
  body #main .manage-page-pop-up button.delete {
    float: left;
    margin-top: 10px;
    padding-left: 18px;
    margin-left: 0px;
    font-size: 14px;
    clear: both !important;
    padding-right: 18px; }
  body #main .manage-page-pop-up .invisible-wrap.full-size-block {
    margin-bottom: 0px; }
  body #main .manage-page-pop-up a {
    font-size: 17px;
    font-weight: normal;
    float: left;
    width: 100%;
    margin-top: 8px; }
  body #main .manage-page-pop-up div {
    font-size: 16px;
    float: left;
    color: #fff;
    font-size: 16px;
    font-weight: normal; }

body #main .box-title.for-popup {
  padding-left: 10px;
  padding-bottom: 0px; }

body #main .delete-page-link.delete {
  height: 28px;
  line-height: 0px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 44px !important;
  position: absolute;
  right: 0px;
  bottom: -11px;
  font-size: 19px;
  background: #d03939 !important;
  border-radius: 29px;
  padding-top: 11px;
  width: 44px;
  text-align: center;
  padding: 0px !important;
  cursor: pointer; }
  body #main .delete-page-link.delete i {
    position: absolute;
    margin-left: -11px;
    top: 9px; }
  body #main .delete-page-link.delete:hover {
    background: #a60f0f !important; }

body #main .page-url-select {
  float: left;
  width: 100%;
  font-size: 11px;
  margin-bottom: 7px;
  font-weight: bold;
  cursor: pointer; }

body #main .invisible-wrap.standard-box.edit-page-details {
  width: 100%;
  padding-top: 22px;
  margin: 10px 0px; }

body #main .add-section-1st {
  float: left;
  box-sizing: border-box;
  font-size: 22px;
  font-weight: bold;
  padding-top: 42px;
  padding-bottom: 42px;
  width: 21%;
  text-align: center; }

body #main .add-section-2nd {
  float: left;
  padding-left: 3%;
  width: 79%;
  background: #455052; }
  body #main .add-section-2nd input {
    float: left;
    margin-bottom: 0px;
    width: 81%;
    margin-bottom: 36px;
    margin-top: 38px; }
  body #main .add-section-2nd button {
    float: left;
    margin-top: 36px;
    margin-left: 3.3%;
    width: 12%;
    text-align: center;
    border: none; }

body #main .edit-page-details .invisible-wrap.one-third-block.first {
  margin-top: 0px; }

body #main .invisible-wrap.full-size-block.add-section-element {
  background: #384547;
  border-radius: 14px !important;
  overflow: hidden; }

body #main .edit-page-details-link {
  color: #faa21a !important;
  font-size: 16px;
  font-weight: lighter;
  margin-left: 20px;
  cursor: pointer; }

body #main .edit-pages-template .section-block {
  border: 3px dotted #0e505b !important;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  padding: 19px;
  border-radius: 10px; }

body #main div.skin-setting-bright #pageContent .edit-pages-template .section-block {
  border: 3px dotted #7d8687 !important; }

body #main div.skin-setting-bright #pageContent .add-section-2nd {
  background: #eaeaea; }
  body #main div.skin-setting-bright #pageContent .add-section-2nd input {
    background: #c7cbcc !important; }

body #main div.skin-setting-bright #pageContent .add-section-1st {
  background: #cfdade; }

body #main div.skin-setting-bright #pageContent .invisible-wrap.full-size-block.add-section-element {
  background: #eaeaea; }

body #main div.skin-setting-bright #pageContent .edit-page-details input {
  background: #c7cbcc !important; }

body #main div.skin-setting-bright #pageContent .edit-page-details select {
  background: #c7cbcc !important; }

body #main div.skin-setting-bright #pageContent .edit-page-details textarea {
  background: #c7cbcc !important; }

.select-icon-box .link-icon i {
  border: none;
  font-size: 39px; }

.name-of-icon {
  font-size: 10px;
  float: left;
  width: 100%;
  text-align: center; }

.select-icon-box .link-icon {
  width: 100%;
  height: 90px; }

.select-icon-box .icon-link:nth-child(9n) {
  margin-right: 0px !important; }

.select-icon-box .icon-link svg {
  width: 50px; }

.select-icon-box .icon-link {
  width: 8.43% !important;
  clear: none;
  cursor: pointer;
  background: none;
  margin-right: 3%;
  height: auto !important; }

.skin-setting-bright .select-icon-box .icon-link {
  color: #004854; }

.icon-link span {
  line-height: initial;
  display: inline-block;
  vertical-align: middle; }

.select-icon-box .section-block .icon-link:hover {
  opacity: .7;
  background: none; }

.select-icon-box .invisible-wrap.full-size-block.section-block:nth-child(2n) .icon-link {
  background: none;
  color: #faa21a; }

.select-icon-box .invisible-wrap.full-size-block.section-block:nth-child(3n) .icon-link {
  background: none;
  color: #ee3124; }

#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line, #blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line-right, #blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-first-line {
  margin: 10px auto; }

#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-first-line {
  margin-top: 0px; }

#blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line-right {
  margin-left: 10px;
  display: inline-block; }
  #blocworx .content .invisible-wrap icon-form .inner-standard-box-wrapper p.icon-line-right button {
    float: left;
    vertical-align: middle;
    line-height: 30px;
    margin: 1px auto; }

@media only screen and (max-width: 1999px) {
  body #main #pageContent article .sub-header-wrapper + div > div:nth-child(3n) .icon-link, #body #main #pageContent article .sub-header-wrapper + div > div:nth-child(3n) .icon-link {
    margin-right: 0px !important; }
  body #main #pageContent article .icon-link:nth-child(3n), #body #main #pageContent article .icon-link:nth-child(3n) {
    margin-right: 0px; } }

.single-page-in-list button {
  font-size: 13px;
  padding: 7px 12px; }

button i {
  border: none; }

.manage-page-pop-up {
  position: absolute;
  right: 50px;
  width: 357px;
  z-index: 1; }

.box-title.for-popup {
  padding-left: 10px;
  padding-bottom: 0px; }

.selected-icon-display i {
  border: none; }

.manage-page-pop-up button, .manage-page-pop-up button.delete {
  float: left;
  margin-top: 10px;
  padding-left: 18px;
  margin-left: 0px;
  font-size: 14px;
  clear: both !important;
  padding-right: 18px; }

#blocworx.skin-setting-dark #full-calendar .fc-button {
  border-color: var(--fc-button-border-color, #2C3E50); }

#blocworx.skin-setting-dark #full-calendar .fc-button-primary {
  color: var(--fc-button-text-color, #fff);
  background: none #2C3E50; }

#blocworx.skin-setting-dark #full-calendar th {
  background-color: white;
  border: 1px solid var(--fc-border-color, #ddd); }

#blocworx.skin-setting-dark #full-calendar tbody tr {
  background-color: white; }

#blocworx.skin-setting-dark #full-calendar a {
  color: #444; }
  #blocworx.skin-setting-dark #full-calendar a p {
    color: #111; }

#blocworx.skin-setting-dark #full-calendar .fc-toolbar-title {
  color: #CCC; }

#blocworx.skin-setting-dark #full-calendar .fc-daygrid-event {
  display: block;
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8); }
  #blocworx.skin-setting-dark #full-calendar .fc-daygrid-event p {
    padding: 0px 10px 0px 10px;
    color: white; }
    #blocworx.skin-setting-dark #full-calendar .fc-daygrid-event p:hover {
      color: #111; }

#blocworx.skin-setting-light #full-calendar .fc-button {
  border-color: var(--fc-button-border-color, #2C3E50); }

#blocworx.skin-setting-light #full-calendar .fc-button-primary {
  color: var(--fc-button-text-color, #fff);
  background: none #2C3E50; }

#blocworx.skin-setting-light #full-calendar th {
  background-color: white;
  border: 1px solid var(--fc-border-color, #ddd); }

#blocworx.skin-setting-light #full-calendar tbody tr {
  background-color: white; }

#blocworx.skin-setting-light #full-calendar a {
  color: #444; }
  #blocworx.skin-setting-light #full-calendar a p {
    color: #111; }

#blocworx.skin-setting-light #full-calendar .fc-toolbar-title {
  color: #CCC; }

#blocworx.skin-setting-light #full-calendar .fc-daygrid-event {
  display: block;
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8); }
  #blocworx.skin-setting-light #full-calendar .fc-daygrid-event p {
    padding: 0px 10px 0px 10px;
    color: white; }
    #blocworx.skin-setting-light #full-calendar .fc-daygrid-event p:hover {
      color: #111; }

#blocworx #full-calendar {
  height: auto;
  width: 100%;
  overflow: hidden; }
  #blocworx #full-calendar .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px; }
  #blocworx #full-calendar table tbody td {
    cursor: pointer; }
  #blocworx #full-calendar .fc-daygrid-event-harness a:hover {
    cursor: default;
    background: transparent; }

page-bar h1 + h2 {
  float: left;
  clear: both;
  font-size: 19px;
  font-weight: normal;
  margin-bottom: 20px; }

page-bar h1 + h2 a, page-bar .edit-station {
  color: #ee3124;
  text-decoration: none; }

#blocworx .page-bar {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-top: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  position: relative;
  color: #fff;
  display: inline-block;
  vertical-align: top; }
  #blocworx .page-bar img {
    width: 40px;
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
    margin-right: 13px;
    position: absolute;
    right: 0px; }
  #blocworx .page-bar h1 {
    font-size: 27.5px;
    color: #f0f4f5;
    float: left;
    font-weight: 600;
    margin: 10px 0px 10px;
    vertical-align: top;
    display: inline-block; }
  #blocworx .page-bar h3 {
    clear: both;
    font-weight: lighter;
    margin-top: 6px;
    float: left;
    display: inline-block;
    vertical-align: top; }
  #blocworx .page-bar .links {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    clear: both;
    padding-bottom: 23px;
    padding-left: 13px;
    padding-top: 14px; }
    #blocworx .page-bar .links a {
      color: #06c492;
      text-decoration: navajowhite;
      border: 2px solid #06c492;
      padding: 6px 23px 8px 23px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      margin-left: 25px; }
      #blocworx .page-bar .links a.active {
        cursor: default; }
      #blocworx .page-bar .links a.active + #blocworx .page-bar .links a:hover {
        color: #0b3c4c;
        background: #06c492; }

#blocworx .skin-setting-dark .page-bar h1 {
  color: #f0f4f5; }

#blocworx .content.job-page .page-bar img {
  margin-top: 5px; }

@media (min-width: 979px) and (max-width: 1340px) {
  #blocworx .page-bar .links a {
    padding: 6px 16px 8px 16px;
    margin-left: 20px;
    font-size: 13px;
    margin-top: 3px;
    float: left; } }

@media (max-width: 979px) {
  #blocworx .page-bar {
    width: 100%;
    height: auto;
    padding-bottom: 0px;
    margin-left: auto; }
    #blocworx .page-bar img {
      width: 28px; }
    #blocworx .page-bar .links {
      width: 100%;
      float: left;
      padding-top: 17px;
      padding-left: 29px;
      background: #eaeef0;
      border-top: 23px solid #0b3c4c; }
      #blocworx .page-bar .links a {
        padding: 4px 16px 6px 23px;
        float: left;
        margin-left: 12px;
        margin-bottom: 12px;
        font-size: 12px; }
        #blocworx .page-bar .links a.active {
          color: #fff; }
  #blocworx .compliance-content .page-bar {
    width: 90% !important;
    margin-left: 5% !important; } }

.skin-setting-bright .template-box p, .skin-setting-bright .template-box .pdf-or-word {
  color: #004854 !important; }

#blocworx .inner-box-wrapper .template-list-single {
  margin: 10px auto;
  width: 100%; }
  #blocworx .inner-box-wrapper .template-list-single a {
    display: inline-block; }

#blocworx .inner-box-wrapper .template-list-single:first-child {
  margin-top: 0px; }

#blocworx .inner-box-wrapper .template-list-single:last-child {
  margin-bottom: 0px; }

#blocworx .delete-template {
  cursor: pointer;
  color: red;
  float: right;
  position: relative; }

#blocworx #downloadTemplate {
  top: 30px;
  z-index: 1; }
  #blocworx #downloadTemplate .template-box {
    margin-bottom: 30px; }

#downloadPdfBtn {
  display: none; }

.export-pdf-button img {
  width: 60px;
  margin-bottom: 20px; }

#body #main #pageContent article .job-setup .module-options > .invisible-wrap {
  border-radius: 10px;
  margin: 10px auto; }
  #body #main #pageContent article .job-setup .module-options > .invisible-wrap.dashed {
    border: 1px dashed;
    position: relative; }
  #body #main #pageContent article .job-setup .module-options > .invisible-wrap .show-field {
    display: inline-flex !important; }
  #body #main #pageContent article .job-setup .module-options > .invisible-wrap .fas:not(.fa-trash) {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    font-size: 30px; }
    #body #main #pageContent article .job-setup .module-options > .invisible-wrap .fas:not(.fa-trash).fa-minus {
      font-color: #d1d3d3; }
    #body #main #pageContent article .job-setup .module-options > .invisible-wrap .fas:not(.fa-trash).fa-plus {
      font-color: #152124;
      margin-right: 25px; }
  #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: stretch;
    padding: 0px;
    border: 0px;
    margin-bottom: 30px;
    background: none; }
    #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .title-container, #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .fields-container {
      display: flex; }
    #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .title-container {
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
      flex-basis: 20%;
      background-color: #344547; }
    #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .fields-container {
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
      background-color: #435052;
      flex-basis: 80%;
      padding: 30px 40px;
      justify-content: space-between; }
    #body #main #pageContent article .job-setup .module-options > .invisible-wrap .standard-box .module-button {
      color: #fff;
      font-size: 17.5px;
      padding: 8px 28px;
      display: block;
      margin: 0 auto;
      cursor: pointer;
      float: right;
      text-decoration: none;
      filter: none;
      font-weight: 500;
      border-radius: 10px;
      background: #004952;
      border: 3px solid #004952;
      width: 130px; }

#body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap {
  border-radius: 10px;
  margin: 10px auto; }
  #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap.dashed {
    border: 1px dashed;
    position: relative; }
  #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .show-field {
    display: inline-flex !important; }
  #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash) {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    font-size: 30px; }
    #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-minus {
      font-color: #d1d3d3; }
    #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-plus {
      font-color: #152124;
      margin-right: 25px; }
  #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: stretch;
    padding: 0px;
    border: 0px;
    margin-bottom: 30px;
    background: none; }
    #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .title-container, #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
      display: flex; }
    #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .title-container {
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
      flex-basis: 20%;
      background-color: #344547; }
    #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
      background-color: #435052;
      flex-basis: 80%;
      padding: 30px 40px;
      justify-content: space-between; }
    #body #main #pageContent article .job-page .module-options advanced-reports > .invisible-wrap .standard-box .module-button {
      color: #fff;
      font-size: 17.5px;
      padding: 8px 28px;
      display: block;
      margin: 0 auto;
      cursor: pointer;
      float: right;
      text-decoration: none;
      filter: none;
      font-weight: 500;
      border-radius: 10px;
      background: #004952;
      border: 3px solid #004952;
      width: 130px; }

#body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap {
  border-radius: 10px;
  margin: 10px auto; }
  #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap.dashed {
    border: 1px dashed;
    position: relative; }
  #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .show-field {
    display: inline-flex !important; }
  #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash) {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    font-size: 30px; }
    #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-minus {
      font-color: #d1d3d3; }
    #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .fas:not(.fa-trash).fa-plus {
      font-color: #152124;
      margin-right: 25px; }
  #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: stretch;
    padding: 0px;
    border: 0px;
    margin-bottom: 30px;
    background: none; }
    #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .title-container, #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
      display: flex; }
    #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .title-container {
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
      flex-basis: 20%;
      background-color: #344547; }
    #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .fields-container {
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
      background-color: #435052;
      flex-basis: 80%;
      padding: 30px 40px;
      justify-content: space-between; }
    #body #main #pageContent article .scan-station-front-end .module-options advanced-reports > .invisible-wrap .standard-box .module-button {
      color: #fff;
      font-size: 17.5px;
      padding: 8px 28px;
      display: block;
      margin: 0 auto;
      cursor: pointer;
      float: right;
      text-decoration: none;
      filter: none;
      font-weight: 500;
      border-radius: 10px;
      background: #004952;
      border: 3px solid #004952;
      width: 130px; }

#body #main #pageContent article .icon-link-wrapper .delete-page-link.delete {
  right: -17px;
  z-index: 992;
  opacity: 0; }

#body #main #pageContent article .icon-link-wrapper:hover .update-order {
  opacity: 1; }

#body #main #pageContent article .icon-link-wrapper:hover .delete-page-link.delete {
  opacity: 1; }

#body #main #pageContent article .update-order {
  position: absolute;
  transform: none !important;
  padding: 0px 8px;
  background: none;
  border-radius: 9px;
  background: #389138;
  border: none;
  top: -1px;
  opacity: 0;
  width: 26px;
  height: 26px; }
  #body #main #pageContent article .update-order:hover {
    background: #189119;
    background: #2B842B !important; }
  #body #main #pageContent article .update-order.left {
    left: 0px; }
  #body #main #pageContent article .update-order.right {
    right: -1px;
    left: auto; }

#body #main #pageContent article .module-options > .dashed > .box-title {
  font-size: 22px;
  font-weight: bold;
  position: relative;
  top: 0px;
  left: 28px; }

#body #main #pageContent article .module-options .dashed > .hide-field + .actions-container {
  cursor: pointer !important;
  left: 0px;
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%; }
  #body #main #pageContent article .module-options .dashed > .hide-field + .actions-container .fas.fa-plus {
    margin-right: 23px; }

#body #main #pageContent article .module-options .invisible-wrap.module-grey-item .show-field {
  display: block !important; }

#body #main #pageContent article .for-section .full-size-block {
  width: 100%; }
  #body #main #pageContent article .for-section .full-size-block input {
    width: 80%;
    width: 80%;
    margin-bottom: 0px;
    padding-top: 11px;
    padding-bottom: 12px;
    padding-left: 18px; }

#body #main #pageContent article .actions-container .fas.fa-minus {
  cursor: pointer; }

#body #main #pageContent article .actions-container .fas {
  transition: none !important; }

#body #main #pageContent article .for-station .half-size-block {
  width: 48.4%; }

#body #main #pageContent article .select-icon-click {
  margin-left: 20px; }
  #body #main #pageContent article .select-icon-click:hover {
    cursor: pointer; }

#body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item {
  background: #455052;
  position: relative; }
  #body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item .fa-plus {
    position: absolute;
    cursor: pointer;
    color: #172124;
    right: 0px;
    top: 10px; }
  #body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item .fa-minus {
    margin-right: 0px; }
  #body #main #pageContent article .invisible-wrap.full-size-block.module-grey-item > .standard-box {
    margin-bottom: 0px !important; }

#body #main #pageContent article .module-grey-item .standard-box {
  display: block !important; }

#body #main #pageContent article .module-grey-item .fas.fa-minus {
  position: absolute;
  color: #172124;
  right: 25px;
  top: 10px; }

#body #main #pageContent article .edit-section-name {
  border: none;
  font-size: 14px; }

#body #main #pageContent article .section-name-field .update-data {
  border: none;
  margin-top: -14px;
  font-size: 14px; }

#body #main #pageContent article .position-edit-section-name {
  display: inline-block;
  position: absolute;
  right: 10px; }

#body #main #pageContent article .section-name {
  display: inline-block; }

#body #main #pageContent article .sub-header-wrapper .section-name-field input {
  width: 86%; }

#body #main #pageContent article .sub-header-wrapper .section-name-field button {
  position: relative;
  top: 15px;
  line-height: 30px;
  vertical-align: middle; }

#body #main #pageContent article .sub-header-wrapper .section-name-field input {
  line-height: 30px;
  vertical-align: middle; }

#body #main #pageContent article .module-component .invisible-wrap {
  padding: 20px; }
  #body #main #pageContent article .module-component .invisible-wrap .expand-retract {
    margin-right: 24px;
    margin-top: 18px; }
  #body #main #pageContent article .module-component .invisible-wrap .box-title {
    background-color: inherit; }
  #body #main #pageContent article .module-component .invisible-wrap .fa-plus {
    right: 0px; }
  #body #main #pageContent article .module-component .invisible-wrap .fa-minus {
    margin-right: 0px; }

#body #main div.skin-setting-dark #pageContent article .content .for-data-look-up .standard-box {
  background: #232d2f; }

#body #main div.skin-setting-bright #pageContent article .content .for-data-look-up .standard-box {
  background: #e0e0e0; }

#body #main div.skin-setting-bright #pageContent article .invisible-wrap.full-size-block.module-grey-item {
  background: #eaeaea; }

#body #main div.skin-setting-bright #pageContent article .module-options > .invisible-wrap .standard-box .box-title {
  color: #004854; }

#body #main div.skin-setting-bright #pageContent article .module-options > .invisible-wrap .standard-box .fields-container {
  background-color: #eaeaea !important; }

#body #main div.skin-setting-bright #pageContent article .module-options > .invisible-wrap .standard-box .title-container {
  background-color: #cfdade !important; }

@media only screen and (max-width: 367px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article scan-station-results .field-count-box h4 {
    font-size: 22px; } }

body #main #pageContent article edit-data .editLinkButton {
  cursor: pointer; }

body #main #pageContent article edit-data .box-title .edit-switch {
  display: flex; }

body #main #pageContent article edit-data .box-title input {
  display: inline-flex; }

body #main #pageContent article edit-data .box-title label {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 16px; }

body #main #pageContent article td[edit-data-link] .editLinkButton {
  margin: 0 auto;
  text-align: center; }

body #main #pageContent article th.edit-data-link, body #main #pageContent article td[edit-data-link] {
  display: table-cell;
  text-align: center; }

#blocworx .form-results-section tbody tr td {
  border: none; }
  #blocworx .form-results-section tbody tr td .fa-search-plus {
    color: #333333; }

#blocworx .form-results-section tbody tr td[show-more-data="link"], #blocworx .form-results-section tbody tr td[edit-data-link] {
  cursor: pointer; }
  #blocworx .form-results-section tbody tr td[show-more-data="link"]:hover, #blocworx .form-results-section tbody tr td[edit-data-link]:hover {
    background-color: #aaa;
    filter: alpha(opacity=60); }
    #blocworx .form-results-section tbody tr td[show-more-data="link"]:hover .fas, #blocworx .form-results-section tbody tr td[edit-data-link]:hover .fas {
      color: #ee3124; }

#blocworx search-data {
  float: left;
  width: 100%;
  margin: 15px auto; }

#blocworx in-data-export-button .invisible-wrap.full-size-block {
  margin-bottom: 0px; }

#blocworx in-data-export-button .export {
  padding: 4px 7px;
  border-radius: 9px;
  color: #fff;
  cursor: pointer; }

#blocworx in-data-export-button .export.pdf {
  background: #c04343;
  color: #fff; }

#blocworx in-data-export-button .export.doc {
  background: #5555c4;
  color: #fff; }

#blocworx in-data-export-button .generated-pdf-button {
  display: none; }

#blocworx in-data-export-button .template-box {
  margin-top: 20px; }

#blocworx in-data-export-button .standard-box {
  text-align: left; }

#blocworx in-data-export-button i {
  float: left; }

#blocworx in-data-export-button download {
  position: relative;
  left: -80px;
  width: 200px;
  max-width: none; }

body #main #pageContent article .radio-button-from-station-field-type,
body #main #pageContent article .checkbox-button-from-station-field-type {
  float: left;
  width: 100%;
  margin-bottom: 6px;
  font-size: 17px; }

body #main #pageContent article checkbox-from-station .checkbox-label {
  width: auto;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 0px;
  display: inline-block;
  float: none; }

body #main #pageContent article checkbox-from-station .submit-button {
  font-size: 13px;
  padding: 6px;
  position: relative;
  vertical-align: middle;
  margin-bottom: 0px;
  height: 40px;
  display: inline-block;
  float: none;
  background: #008000; }

body #main #pageContent article checkbox-from-station .clean-all {
  background: #ee3124; }

body #main #pageContent article checkbox-from-station .list-all-values-selected {
  padding: 0px;
  margin: 0px;
  color: #4fb14f99; }

body #main #pageContent article checkbox-from-station .list-all-values {
  opacity: .7;
  padding: 0px;
  margin: 6px auto; }

#blocworx qr-code i {
  font-size: 30px; }

#blocworx qr-code label {
  text-transform: capitalize; }

#blocworx qr-code button {
  border-radius: 10px;
  float: left; }

#blocworx qr-code .btn-text {
  padding: 8px 28px; }

#blocworx qr-code .btn-qrcode {
  float: left;
  padding: 5px; }

#blocworx qr-code canvas {
  width: 100%; }

#blocworx qr-code .close-qr-scanner {
  background-color: rgba(0, 0, 0, 0.3);
  display: inline-block;
  width: 100%;
  border-radius: 11px 11px 0px 0px;
  line-height: 50px;
  height: 50px;
  min-height: 50px; }
  #blocworx qr-code .close-qr-scanner i {
    float: right;
    position: relative;
    right: 5px;
    padding: 4px 1px; }

#blocworx qr-code .canvas-qr-scanner {
  margin-top: 0px;
  position: relative;
  top: -10px; }

#blocworx qr-code .qr-results {
  width: 60%;
  display: inline-block;
  font-size: 12px;
  margin-left: 6px; }

body #main #pageContent article radio-button .field-options {
  display: inline-flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  margin: 0px 0px 20px 0px; }
  body #main #pageContent article radio-button .field-options p {
    display: inline-flex;
    align-items: center;
    margin: 0px; }
    body #main #pageContent article radio-button .field-options p label {
      float: none;
      margin: 0px;
      padding: 0px; }

body #main #pageContent article radio-button .radio-button-input {
  position: relative;
  top: 0;
  margin-right: 10px;
  margin-bottom: 10px; }

body #main #pageContent article radio-button .clean-radio-action {
  cursor: pointer;
  padding: 0px;
  font-size: 15px; }

body #main #pageContent article radio-button a {
  text-decoration: none; }

body #main #pageContent article radio-button .loadingData {
  display: block;
  margin-top: 20px;
  margin-left: 0px; }

#body #main #pageContent article wysiwyg .tiny-mce-container {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999999;
  position: fixed;
  top: 0px;
  left: 0;
  height: 97%;
  height: 94%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  float: none; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .close-modal {
    z-index: 9999999;
    color: white; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-header {
    color: #fff;
    padding: 10px 20px;
    font-size: 20.5px;
    font-weight: 600;
    border-radius: 11px 11px 0px 0px;
    z-index: 999999999;
    width: 100%;
    flex: 1 1 auto;
    justify-content: space-between;
    display: flex;
    max-height: 60px;
    align-items: center; }
  #body #main #pageContent article wysiwyg .tiny-mce-container > .mce-panel {
    height: auto !important; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons {
    width: 100%;
    display: inline-block;
    border-radius: 0px 0px 11px 11px;
    height: 80px;
    max-height: 100px; }
    #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons button {
      margin: 20px; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .mce-btn-group .mce-splitbtn button {
    float: left; }
    #body #main #pageContent article wysiwyg .tiny-mce-container .mce-btn-group .mce-splitbtn button.mce-open {
      float: right; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .mce-panel {
    border: none; }

#body #main div.skin-setting-dark #pageContent article .tiny-mce-container .tiny-mce-header, #body #main div.skin-setting-dark #pageContent article .tiny-mce-container .tiny-mce-buttons {
  color: white;
  background-color: #343a40; }

#body #main div.skin-setting-bright #pageContent article .tiny-mce-container .tiny-mce-header, #body #main div.skin-setting-bright #pageContent article .tiny-mce-container .tiny-mce-buttons {
  color: white;
  background-color: #455052; }

@media only screen and (max-width: 367px) {
  #body #main #pageContent article .modal-scrollable {
    overflow-y: auto; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons {
    height: 86px; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  #body #main #pageContent article .modal-scrollable {
    overflow-y: auto; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons {
    height: 80px; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article .modal-scrollable {
    overflow-y: auto; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons {
    height: 80px; } }

@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article .modal-scrollable {
    overflow-y: auto; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons {
    height: 80px; } }

@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article .modal-scrollable {
    overflow-y: auto; }
  #body #main #pageContent article wysiwyg .tiny-mce-container .tiny-mce-buttons {
    height: 80px; } }

#blocworx mindee .file-upload-container input {
  width: 35%; }

#blocworx mindee .file-upload-container button {
  width: 47%;
  padding-top: 9px;
  padding-bottom: 11px;
  height: 100%;
  box-sizing: border-box;
  float: left;
  margin-top: -3px; }

#blocworx mindee img, #blocworx mindee iframe {
  width: 100% !important;
  margin-top: 20px;
  margin-bottom: 20px; }

#blocworx mindee iframe {
  height: 100vh; }

#blocworx mindee .show-preview {
  position: relative;
  clear: both; }

#blocworx mindee .show-preview i {
  cursor: pointer;
  position: absolute;
  bottom: 33px;
  right: 16px;
  background: white;
  font-size: 25px;
  background: none;
  color: #000; }

#blocworx mindee .show-preview.full-screen i {
  position: fixed;
  top: 8%;
  right: 7%;
  z-index: 9999;
  border-radius: 5px;
  color: #000;
  bottom: auto; }

#blocworx mindee .show-preview.full-screen {
  position: fixed;
  z-index: 999;
  top: 5%;
  width: 90%;
  left: 5%;
  height: 90%;
  overflow: auto; }

#blocworx submit-button-field .submit-button.hide {
  display: block; }

#blocworx submit-button-field .scan-station-buttons {
  padding: 0px; }

#blocworx submit-button-field button {
  width: 100%; }

#blocworx submit-button-field .delete {
  display: none !important; }

#blocworx mail-alert .file-upload-container input {
  width: 35%; }

#blocworx mail-alert .file-upload-container button {
  width: 47%;
  padding-top: 9px;
  padding-bottom: 11px;
  height: 100%;
  box-sizing: border-box;
  float: left;
  margin-top: -3px; }

#blocworx mail-alert img, #blocworx mail-alert iframe {
  width: 100% !important;
  margin-top: 20px;
  margin-bottom: 20px; }

#blocworx mail-alert iframe {
  height: 100vh; }

#blocworx mail-alert .show-preview {
  position: relative;
  clear: both; }

#blocworx mail-alert .show-preview i {
  cursor: pointer;
  position: absolute;
  bottom: 33px;
  right: 16px;
  background: white;
  font-size: 25px;
  background: none;
  color: #000; }

#blocworx mail-alert .show-preview.full-screen i {
  position: fixed;
  top: 8%;
  right: 7%;
  z-index: 9999;
  border-radius: 5px;
  color: #000;
  bottom: auto; }

#blocworx mail-alert .show-preview.full-screen {
  position: fixed;
  z-index: 999;
  top: 5%;
  width: 90%;
  left: 5%;
  height: 90%;
  overflow: auto; }

#blocworx mail-alert .fixed-value {
  font-weight: bold;
  font-style: italic; }

#blocworx mail-alert .delete-button {
  color: red;
  cursor: pointer; }

#blocworx mail-alert .small-margin {
  margin-right: 25px; }

#body #main #pageContent article file-builder .loading-image {
  margin-top: 11px;
  text-align: right;
  float: right; }

#body #main #pageContent article file-builder .file-builder-scan-station-data {
  display: inline-flex;
  align-items: center;
  justify-content: space-around; }

#body #main #pageContent article file-builder .file-builder-modal {
  position: fixed;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99999999999999;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px; }
  #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-header {
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    display: inline-flex;
    color: white;
    width: 100%;
    height: 50px; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-header h2 {
      display: inline-flex;
      align-items: center;
      align-content: center;
      flex: 1 1 auto;
      height: 50px;
      margin-left: 10px; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-header .file-builder-modal-header-button {
      font-size: 30px;
      display: flex;
      align-content: space-around;
      align-items: center;
      margin-right: 10px; }
  #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-container {
    width: 100%;
    display: inline-flex;
    height: 100%;
    align-items: flex-start; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-container table {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-container td .align-left {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      align-content: center;
      overflow-wrap: break-word;
      white-space: normal; }
  #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons {
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: inline-flex;
    color: white;
    flex: 1 1 100%;
    justify-content: flex-start; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons h2 {
      font-size: 20px;
      padding: 20px; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons .modal-button-left {
      display: flex;
      align-content: center;
      align-items: center; }
    #body #main #pageContent article file-builder .file-builder-modal .file-builder-modal-buttons .modal-button-right {
      display: flex;
      align-content: center;
      align-items: center; }

#body #main div.skin-setting-dark #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-header {
  background-color: #343a40; }

#body #main div.skin-setting-dark #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-buttons {
  background-color: #455052; }

#body #main div.skin-setting-dark #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-container {
  background-color: #455052; }

#body #main div.skin-setting-bright #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-header {
  background-color: #757d7e; }

#body #main div.skin-setting-bright #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-buttons {
  background-color: #eaeaea; }

#body #main div.skin-setting-bright #pageContent article file-builder .file-builder-scan-station-data .file-builder-modal .file-builder-modal-container {
  background-color: #eaeaea; }

body #main #pageContent article source-bloc {
  margin: 0px 0px 20px 0px;
  display: inline-block;
  width: 100%; }
  body #main #pageContent article source-bloc .flex-container input {
    margin: 0px; }

body #main #pageContent article matching-data {
  margin: 0px 0px 20px 0px;
  display: inline-block;
  width: 100%; }
  body #main #pageContent article matching-data .flex-container {
    display: inline-flex;
    align-content: center;
    align-items: stretch; }
  body #main #pageContent article matching-data .flex-child-button {
    margin: 0px 0px 0px 10px; }
  body #main #pageContent article matching-data .spaced {
    align-items: flex-end;
    justify-content: flex-end; }

body #main #pageContent article exported-fields {
  margin: 0px 0px 20px 0px;
  display: inline-block;
  width: 100%; }
  body #main #pageContent article exported-fields .flex-child-button, body #main #pageContent article exported-fields .flex-child-select {
    margin: 10px 0px; }
  body #main #pageContent article exported-fields .flex-child-select {
    margin-right: 10px; }
  body #main #pageContent article exported-fields .flex-child-button, body #main #pageContent article exported-fields .flex-child-x {
    margin-left: 10px; }

body #main #pageContent .field-digital-signature-pad .signature-pad {
  float: left; }

body #main #pageContent .field-digital-signature-pad button:not(.delete) {
  float: left;
  clear: both; }

#blocworx barcode-generator .field-inner-container img {
  margin-top: 20px; }

.skin-setting-dark .mce-container {
  background: #011f25 !important;
  color: #fff !important;
  border-bottom-color: #013c48; }
  .skin-setting-dark .mce-container * {
    background: #011f25 !important;
    color: #fff !important; }
  .skin-setting-dark .mce-container .mce-btn-group:not(:first-child) {
    border-left-color: #013c48; }

.skin-setting-dark .mce-widget {
  background: #011f25 !important;
  color: #fff !important; }
  .skin-setting-dark .mce-widget * {
    background: #011f25 !important;
    color: #fff !important; }

.skin-setting-dark .mce-reset {
  background: #011f25 !important;
  color: #fff !important; }

.skin-setting-bright .mce-container {
  background: #f0f4f5 !important;
  color: #595959 !important; }
  .skin-setting-bright .mce-container * {
    background: #f0f4f5 !important;
    color: #595959 !important; }

.skin-setting-bright .mce-widget {
  background: #f0f4f5 !important;
  color: #595959 !important; }
  .skin-setting-bright .mce-widget * {
    background: #f0f4f5 !important;
    color: #595959 !important; }

.skin-setting-bright .mce-reset {
  background: #f0f4f5 !important;
  color: #595959 !important; }

.hide-in-form-sub-bloc-accordian label, .hide-in-form-sub-bloc-accordian .box-title {
  display: none; }

.hide-in-form-sub-bloc-accordian .box-title + .accordian-sub-bloc-container {
  border: none !important; }

.sub-bloc-manual-trigger-button {
  width: calc(100% - 50px);
  border: none;
  text-align: center;
  margin-top: 25px;
  margin-left: 25px;
  padding: 10px 10px 10px 10px; }

.is-in-iframe #scanStationHeader .box-title .header-title {
  display: inline !important; }

.is-in-iframe .content.manage-users {
  padding: 2% !important; }

.hide-customer {
  display: none; }

users .standard-box {
  position: relative; }

users .space-between-standard-box {
  margin-top: 15px;
  margin-bottom: 15px; }

.invisible-wrap.full-size-block.show-profiles-pop-up {
  top: 1%;
  padding-bottom: 0px;
  overflow: hidden;
  margin: 0px auto; }
  .invisible-wrap.full-size-block.show-profiles-pop-up .standard-box {
    overflow-y: auto;
    left: 1%;
    width: 98%;
    height: 98%;
    top: 0%; }

.show-profiles-pop-up .standard-box .profile-content {
  padding: 0px;
  margin: 0px; }
  .show-profiles-pop-up .standard-box .profile-content .inner-standard-box-wrapper {
    padding: 0px 28px;
    margin: 0px; }

.show-profiles-pop-up .standard-box .inner-standard-box-wrapper {
  padding: 0px 20px; }

#iframe .edit-switch {
  margin: 0px;
  padding: 0px;
  width: 97% !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap; }
  #iframe .edit-switch input {
    padding: 0px;
    margin: 0px !important; }

#iframe .for-checkbox {
  margin: 0px;
  padding: 0px;
  position: relative;
  left: 17px;
  width: 97%; }

body #main #pageContent article .show-false {
  opacity: 0;
  height: 0px;
  display: none; }

body #main #pageContent article roles-and-permissions > div {
  margin: 10px auto; }

body #main #pageContent article roles-and-permissions .inner-standard-box-wrapper {
  position: relative; }
  body #main #pageContent article roles-and-permissions .inner-standard-box-wrapper.show-true {
    padding-bottom: 30px;
    background: inherit;
    overflow: hidden;
    width: 100%;
    z-index: 1; }

body #main #pageContent article roles-and-permissions .show-visible {
  overflow: visible;
  opacity: 1;
  padding: 0px 25px 0px 30px;
  max-height: 100%;
  margin-top: 10px;
  padding-bottom: 30px !important;
  background: inherit;
  width: 100%;
  top: 41px;
  z-index: 1;
  position: absolute;
  float: left;
  box-sizing: border-box; }

body #main #pageContent article roles-and-permissions .full-size-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

@media only screen and (max-width: 976px) {
  body #main #pageContent article roles-and-permissions .full-size-block, #body #main #pageContent article roles-and-permissions .full-size-block {
    flex-direction: column; } }

body #scheduleCustomerData h2, body #scheduleCustomerData p, body #scheduleCustomerData .flexcontainer {
  margin: 10px auto; }

body #scheduleCustomerData .flexcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center; }
  body #scheduleCustomerData .flexcontainer button {
    float: none;
    margin: 0; }
  body #scheduleCustomerData .flexcontainer .message {
    width: 100%; }
  body #scheduleCustomerData .flexcontainer .success {
    color: green; }
  body #scheduleCustomerData .flexcontainer .fail {
    color: red; }

body #scheduleCustomerData .oneline {
  width: 100%; }

body #scheduleCustomerData .flexchild {
  flex: 1 1 auto; }

body #main #pageContent article .content .reports .existing-automated-report {
  word-break: break-all; }

body #main #pageContent article .content .reports .more-data-pop-up {
  overflow: unset; }
  body #main #pageContent article .content .reports .more-data-pop-up tobdy {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    table-layout: fixed; }
  body #main #pageContent article .content .reports .more-data-pop-up table tbody {
    display: block;
    max-height: 800px;
    overflow-y: scroll; }
  body #main #pageContent article .content .reports .more-data-pop-up table thead, body #main #pageContent article .content .reports .more-data-pop-up table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; }

body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up {
  background-color: #374244;
  -webkit-box-shadow: 0px 4px 20px 9px #232d2f;
  -moz-box-shadow: 0px 4px 20px 9px #232d2f;
  box-shadow: 0px 4px 20px 9px #232d2f; }
  body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-thumb {
    background: #455052; }
  body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-track {
    background: #232d2f; }
  body #main div.skin-setting-dark #pageContent article .content .reports .more-data-pop-up tbody {
    scrollbar-face-color: #455052;
    scrollbar-track-color: #232d2f; }

body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up {
  background: #f0f4f5;
  -webkit-box-shadow: 0px 4px 20px 9px #455052;
  -moz-box-shadow: 0px 4px 20px 9px #455052;
  box-shadow: 0px 4px 20px 9px #455052; }
  body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-thumb {
    background: #343a40; }
  body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody::-webkit-scrollbar-track {
    background: #eaeaea; }
  body #main div.skin-setting-bright #pageContent article .content .reports .more-data-pop-up tbody {
    scrollbar-face-color: #343a40;
    scrollbar-track-color: #eaeaea; }

@media only screen and (max-width: 976px) {
  body #main #pageContent article .content .reports .more-data-pop-up table tbody, #body #main #pageContent article .content .reports .more-data-pop-up table tbody {
    max-height: 850px; } }

@media only screen and (max-width: 480px) {
  body #main #pageContent article .content .reports .more-data-pop-up table tbody, #body #main #pageContent article .content .reports .more-data-pop-up table tbody {
    max-height: 500px; } }

@media only screen and (min-width: 2000px) {
  body #main #pageContent article .icon-link-wrapper, #body #main #pageContent article .icon-link-wrapper {
    width: 23%; }
    body #main #pageContent article .icon-link-wrapper .icon-link, #body #main #pageContent article .icon-link-wrapper .icon-link {
      width: 100%; }
  body #main #pageContent article .icon-link, #body #main #pageContent article .icon-link {
    width: 23.5%; }
    body #main #pageContent article .icon-link:nth-child(4n), #body #main #pageContent article .icon-link:nth-child(4n) {
      margin-right: 0px; }
  body #main #pageContent article .sub-header-wrapper + div > div:nth-child(4n) .icon-link, #body #main #pageContent article .sub-header-wrapper + div > div:nth-child(4n) .icon-link {
    margin-right: 0px !important; } }

#blocworx detect-inactivity .standard-box.detect-inactivity-popup {
  position: fixed;
  top: 19%;
  left: 25%;
  width: 47% !important;
  height: 300px;
  overflow: auto;
  text-align: center;
  z-index: 9999; }

@media only screen and (max-width: 979px) {
  #blocworx detect-inactivity .standard-box.detect-inactivity-popup {
    position: fixed;
    z-index: 999999;
    top: 5%;
    left: 5%;
    width: 90% !important;
    height: 90%;
    overflow: auto;
    text-align: center; } }

body #main #pageContent article management-blocworx {
  width: 100%;
  height: 100%;
  display: flex; }

#body #main #pageContent article th.results-box-field {
  width: 0.1%;
  max-width: 0.1%;
  text-align: center; }

#body #main #pageContent article td.results-box-field {
  width: 0.1%;
  max-width: 0.1%;
  text-align: center; }

#body #main #pageContent article .simple-modal {
  --gutter: 14px;
  --modal-color: #153740;
  --soft-color: #fafafa;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  padding-top: 2%; }
  #body #main #pageContent article .simple-modal__content {
    width: 95vw;
    height: 90vh;
    max-height: 900px;
    max-width: 1800px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column; }
    #body #main #pageContent article .simple-modal__content header {
      min-height: 60px;
      height: 60px;
      color: #ffffff;
      background-color: var(--modal-color);
      display: grid;
      align-items: center;
      grid-template-columns: auto 90px;
      justify-content: space-between; }
      #body #main #pageContent article .simple-modal__content header h4 {
        margin: 0;
        text-align: left;
        padding: var(--gutter); }
      #body #main #pageContent article .simple-modal__content header span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 20px;
        opacity: .8;
        cursor: pointer;
        padding: var(--gutter); }
        #body #main #pageContent article .simple-modal__content header span:hover {
          opacity: 1;
          background-color: #04121452; }
    #body #main #pageContent article .simple-modal__content .modal-main {
      flex: 1;
      text-align: left;
      overflow: auto;
      padding: var(--gutter); }
    #body #main #pageContent article .simple-modal__content footer {
      height: auto;
      text-align: right;
      border-top: solid 1px #cccccc;
      padding: var(--gutter);
      background-color: var(--soft-color); }
      #body #main #pageContent article .simple-modal__content footer button, #body #main #pageContent article .simple-modal__content footer input {
        margin: 0;
        border: none; }
        #body #main #pageContent article .simple-modal__content footer button:not(:last-child), #body #main #pageContent article .simple-modal__content footer input:not(:last-child) {
          margin-right: var(--gutter); }
      #body #main #pageContent article .simple-modal__content footer button {
        display: none; }

#body #main #pageContent article .modal-off {
  display: none; }

#body #main #pageContent article .modal-on {
  display: flex; }

#body #main div.skin-setting-dark #pageContent article .results-box-field thead tr th {
  color: #fff; }

#body #main div.skin-setting-dark #pageContent article .simple-modal {
  --modal-color: #232d2f;
  --soft-color: #fafafa; }
  #body #main div.skin-setting-dark #pageContent article .simple-modal__content {
    background-color: #ffffff; }
    #body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main {
      background-color: #455052; }
      #body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar {
        width: 10px;
        height: 10px; }
      #body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-thumb {
        background: #455052; }
      #body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-track {
        background: #232d2f; }
      #body #main div.skin-setting-dark #pageContent article .simple-modal__content .modal-main {
        scrollbar-face-color: #455052;
        scrollbar-track-color: #232d2f; }
    #body #main div.skin-setting-dark #pageContent article .simple-modal__content header {
      background-color: var(--modal-color); }
      #body #main div.skin-setting-dark #pageContent article .simple-modal__content header h4, #body #main div.skin-setting-dark #pageContent article .simple-modal__content header span {
        color: #fff; }
    #body #main div.skin-setting-dark #pageContent article .simple-modal__content span:hover {
      background-color: #04121452; }

#body #main div.skin-setting-bright #pageContent article .results-box-field thead tr th {
  color: #004854; }

#body #main div.skin-setting-bright #pageContent article .simple-modal {
  --modal-color: #343a40db;
  --soft-color: #fafafa; }
  #body #main div.skin-setting-bright #pageContent article .simple-modal__content {
    background-color: #ffffff; }
    #body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main {
      background-color: #dfe7e98a; }
      #body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar {
        width: 10px;
        height: 10px; }
      #body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-thumb {
        background: #343a40; }
      #body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main::-webkit-scrollbar-track {
        background: #eaeaea; }
      #body #main div.skin-setting-bright #pageContent article .simple-modal__content .modal-main {
        scrollbar-face-color: #343a40;
        scrollbar-track-color: #eaeaea; }
    #body #main div.skin-setting-bright #pageContent article .simple-modal__content header {
      background-color: var(--modal-color); }
      #body #main div.skin-setting-bright #pageContent article .simple-modal__content header h4, #body #main div.skin-setting-bright #pageContent article .simple-modal__content header span {
        color: #fff; }
    #body #main div.skin-setting-bright #pageContent article .simple-modal__content span:hover {
      background-color: #04121452; }

@media only screen and (max-width: 367px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 8%; }
    #body #main #pageContent article .simple-modal__content {
      max-width: 340px; } }

@media only screen and (min-width: 367px) and (max-width: 768px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 8%; }
    #body #main #pageContent article .simple-modal__content {
      max-width: 740px; } }

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 8%; }
    #body #main #pageContent article .simple-modal__content {
      max-width: 960px; } }

@media (min-width: 979px) and (max-width: 1024px) {
  #body #main #pageContent article .simple-modal {
    padding-top: 3%; }
    #body #main #pageContent article .simple-modal__content {
      max-width: 900px; } }

@media (min-width: 1025px) and (max-width: 1340px) {
  #body #main #pageContent article .simple-modal__content {
    max-width: 1240px; } }

#body #main #pageContent article advanced-reports .unselectFields {
  background-color: tomato; }

#body #main #pageContent article .edit-theme input[type="checkbox"] {
  width: auto;
  float: none;
  clear: both;
  margin: auto;
  margin-top: 5px;
  margin-right: 5px; }

#body #main #pageContent article .menu-logo-image .customer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  background: none; }

#body #main #pageContent article .blocworx-content .preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 50px;
  background: #ffffff14;
  width: 100%; }

#body #main #pageContent article .blocworx-content .preview-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #343a40cf; }

#body #main div.skin-setting-dark #pageContent article .blocworx-content .preview-header {
  background-color: #343a40cf; }

#body #main div.skin-setting-bright #pageContent article .blocworx-content .preview-header {
  background-color: #455052b5; }

#body #main #pageContent header .header {
  background-size: cover;
  width: 100%;
  max-width: 1900px;
  background: url("images/header-bg.png");
  background-repeat: no-repeat;
  border-bottom: 3px solid #ee3124;
  box-sizing: border-box;
  background-size: cover;
  min-height: 90px;
  height: 100%;
  background-position-x: center;
  background-position-y: center; }
  #body #main #pageContent header .header ul {
    float: right;
    margin: 28px 0px 0px 0px;
    padding: 0px; }
    #body #main #pageContent header .header ul li {
      float: left;
      margin-right: 29px;
      list-style: none; }
      #body #main #pageContent header .header ul li a {
        text-decoration: none; }
        #body #main #pageContent header .header ul li a span {
          font-size: 19px;
          color: #fff;
          position: relative; }
      #body #main #pageContent header .header ul li:hover a span {
        text-shadow: 0px 0px 0px #fff; }
    #body #main #pageContent header .header ul li:last-child {
      margin-right: 0px; }

@media (min-width: 1340px) and (max-width: 2570px) {
  #body #main #pageContent header .header {
    max-width: 2570px; } }

@media (min-width: 2570px) and (max-width: 3850px) {
  #body #main #pageContent header .header {
    max-width: 3850px; } }

#body #main #pageContent .header-language {
  top: -10px; }

#body #main #pageContent blocworx-languages {
  display: inline-block;
  position: relative;
  width: 100%; }
  #body #main #pageContent blocworx-languages .switch .fa-language {
    font-size: 40px; }
  #body #main #pageContent blocworx-languages .switch .language-list {
    border: 1px dashed;
    padding: 10px;
    display: inline-block;
    float: left;
    z-index: 9999;
    position: absolute;
    margin: 0px;
    top: 40px;
    right: -40px; }
    #body #main #pageContent blocworx-languages .switch .language-list li {
      padding: 5px;
      float: none;
      cursor: pointer;
      margin: 0px; }
  #body #main #pageContent blocworx-languages .select-options {
    margin: 10px 0px; }

#body #main div.skin-setting-dark #pageContent blocworx-languages .fa-language {
  color: #fff; }

#body #main div.skin-setting-dark #pageContent blocworx-languages .language-list {
  background-color: #232d2f; }
  #body #main div.skin-setting-dark #pageContent blocworx-languages .language-list li:hover {
    background-color: #0C3A42;
    color: #fff; }

#body #main div.skin-setting-bright #pageContent blocworx-languages .fa-language {
  color: #fff; }

#body #main div.skin-setting-bright #pageContent blocworx-languages .language-list {
  background-color: #fff; }
  #body #main div.skin-setting-bright #pageContent blocworx-languages .language-list li:hover {
    background-color: #0C3A42;
    color: #fff; }
