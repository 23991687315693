@media (max-width: 979px) {

    .hide-for-mobile {
        display:none;
    }

    .sidebar {
        position: fixed;
    }

    html, body {
        width: 100%;
    }

    .mobile {
        display: block;
    }

    .pop-up-version-update {
        width: 80%;
        left: 10%;
        top: 30%;
        padding: 11% 7% 1% 7%;
    }

  .sidebar {
    transform: translateX(190px);
    -webkit-transform: translateX(233px);
    -o-transform: translateX(190px);
    -moz-transform: translateX(233px);
    right: 0px;
    position: fixed;
  }
    .sidebar.open {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -o-transform: translateX(0px);
        -moz-transform: translateX(0px);
        overflow: auto;
    }

    .content.full-width-content {
        padding-left: 5%;
    }

    .login-page form {
        width: 100%;
    }

    .standard-box.login-page {
        width: 100%;
        margin-left: 0px !important;
        box-sizing: border-box;
    }



    .standard-box {
        width: 100%;
    }

    .standard-box.digital-signature button {
        float: left;
        margin-bottom: 11px;
    }

    .invisible-box.stations {
        width: 100%;
        margin-bottom: 21px;
    }

    .icon-link {
        width: 100%;
    }

    .standard-box.scan-form {
        width: 100%;
    }

    .standard-box.scan-results, .standard-box.sop-files {
        margin-bottom: 25px;
    }

    .standard-box.create-job {
        margin-left: 0;
        width: 100%;
        margin-top: 30px;
    }

    .inner-standard-box-wrapper {
        padding: 0px 28px 28px 27px;
    }

    .manage-users .invisible-box {
        width: 100%;
        margin-right: 0px;
    }

    .manage-users .icon-link {
        margin-top: 50px;
    }

    .standard-box.sign-in-out, .standard-box.log-anti-static-strap {
        width: 100%;
        margin-right: 0px;
    }

    .standard-box.sign-in-out {
        margin-bottom: 30px;
    }



    .invisible-box.job-setup-items {
        width: 100%;
    }

    .standard-box.import-job {
        width: 100%;
    }

    .invisible-box.stations.admin {
        width: 100%;
    }

    .standard-box.new-station {
        width: 100%;
    }

    .standard-box.data-tracking-fields {
        width: 100%;
    }

    .station-sign-offs input {
        width: 55%;
    }



    .standard-box.update-job {
        width: 100%;
    }

    .invisible-wrap {
        margin-right: 0px;
        margin-left: 0px;
    }

    .invisible-wrap.two-thirds-block,  .invisible-wrap.one-third-block {
        width: 100%;
    }

    .operation-station-setup-wrapper {
        width: 100%;
        margin-right: 0px;
    }

    .operation-station {
        width: 100%;
        margin-right: 0px;
    }


    .customised-layout-1 .show-setting-1.field-outer-container {
        width: 100% !important;
        clear: both !important;
        border: 0px !important;
        margin: 0px !important;
    }

    .field-type-label {
        display: none !important;
    }

    .job-list-container .job-list-header-titles, .data-sorting .job-list-header-titles {
        display: none;
    }

    .job-list-container .job-single-in-list div, .data-sorting .job-single-in-list div {
        width: 100% !important;
    }

    .job-list-container .job-single-in-list , .data-sorting .job-single-in-list {
        margin-bottom: 20px;
    }

    .job-list-container .job-single-in-list div .mobile, .data-sorting .job-single-in-list div .mobile {
        display: inline !important;
        font-weight: bold;
    }

    .invisible-wrap.one-quarter-block {
        width: 100%;
    }

    .edit-switch {
        float: left;
        font-size: 16px;
        margin-left: 25px;
        padding: 0px;
        margin-left: 4%;
        margin-top: 10px;
    }

    .inner-standard-box-wrapper.show-filtering-options {
        float: left;
        width: 100%;
        margin-top: 20px;
    }

    .report-list-section {
        width: 100%;
        border: none !important;
    }

    .report-list-section + .report-list-section {
        margin-left: 0px;
        margin-top: 30px;
    }

    .report-list-item .date-container {
        max-width: none;
    }

    .refine-search {
        width: 100%;
        margin-left: 0px;
    }

    .refine-search div {
        width: 100%;
        margin-bottom: 10px;
    }

    .for-live-data-selection .invisible-wrap.one-half-block {
        width: 100%;
    }

    .invisible-wrap.one-half-block {
        width: 100%;
    }

    .job-page .invisible-wrap + .one-third-block {
        margin-top: 25px;
    }

    .show-filtering-options select {
        float: left;
        width: 100%;
        margin-left: 15px;
        margin-top: 40px;
    }

    .data-sorting th {
        float: left;
        width: 100%;
        text-align: left;
    }

    .data-sorting th select {
        width: 100% !important;
        max-width: none;
    }

    .for-data-look-up td.full-width-true {
        overflow: auto;
        height: 300px;
    }

    .full-width-true thead {
        display: none !important;
    }

    .help-message-box {
        height: 100px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .main-help-text {
        margin-right: 0px !important;
        width: 100% !important;
        text-align: center !important;
    }

    .help-message-box button {
        display: block;
        float: none !important;
        margin: 15px auto !important;
        position: relative;
        top: 10px;
    }

    .mobile.help-mode-ul {
        float: left;
        margin-left: 20px;
        font-size: 16px;
    }

    .file-upload-container input {
        width: 100%;
    }
    .file-upload-container button {
        display: none;
    }

    .covid-19-pop-up {
        left: 0px;
        margin-left: 0px;
    }

    .help-message-box {
        display: none;
    }

    .sidebar-switches .help-mode-container:first-child {
        display: none;
    }

    .compliance-content.home-page.ng-scope {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 100px !important;
        padding-left: 0px !important;
    }


    .compliance-content .invisible-wrap.two-thirds-block {
        width: 100% !important;
    }

    .chart-container {
        margin-left: 15% !important;
    }

    .invisible-wrap.one-third-block.graph-and-message.last {
        overflow: visible;
        margin-right: 0px !important;
    }



    .go-to-section-button {
        margin-left: 5% !important;
    }

    .field-type-results-box .field-inner-container {
        overflow-x: auto;
    }

    .load-more-data-button + .btn.btn-primary + .btn.btn-primary {
        clear: both;
    }

    .go-to-section-button {
        margin: 0px;
    }

    .go-to-section-button.review.link {
        padding: 0px 0px 0px 10px !important;
        margin: 0px;
        float: right;
        font-size: 18px;
        text-align: right;
        margin-right: 10% !important;
        margin-top: 0px !important;
    }

    .company-information-slide .next {
        margin-left: 16px;
        margin-right: 5%;
    }

    .compliance-content .breadcrumbs span {
        display: none;
    }

    .main-question {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

    .answer-buttons button + button {
        clear: left;
        float: left;
        margin-top: 12px;
        margin-left: 5% !important;
    }

    .answer-buttons button {
        margin-left: 5% !important;
    }

    .not-answered-header {
        margin-left: 5% !important;
        width: 90% !important;
    }

    .previous {
        float: left;
        margin-left: 5%;
    }

    button.next {
        margin-right: 5%;
    }

    .sample-answer-box {
        padding: 5% !important;
        margin-right: 0px !important;
    }

    .answers-box h3.show {
        margin-left: 5%;
        margin-bottom: -53px;
    }

    .date-box-data-wrapper {
        padding-top: 68%;
        padding-left: 5%;
        font-size: 1.9vw;
    }

    .scan-station-results.two-thirds-block .edit-switch input {
        right: 7px;
        margin-right: 13px;
    }


}