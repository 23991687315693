@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800');

body.skin-setting-dark {
    background: #232d2f;
}

.section-block .link-icon i {
    font-size: 31px;
    border: none;
    text-align: center;
    margin-top: 7px;
    margin-left: 5px;
}

a {
    color: #ee3124;
}

.top-menu-icon {
    width: 32px;
}

.logo a span {
    float: left;
    width: 100%;
    font-size: 14px;
}



.logo img {
    width: 210px;
    padding: 0px;
    margin-top: 18px;
    margin-bottom: 18px;
}

.top-menu-icon {
    width: 23px;
    margin-top: 2px;
}

.login-logout a {
    background: #fbbe5e;
    background: -moz-linear-gradient(top, #fbbe5e 0%, #faa21b 100%);
    background: -webkit-linear-gradient(top, #fbbe5e 0%, #faa21b 100%);
    background: linear-gradient(to bottom, #fbbe5e 0%, #faa21b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbbe5e', endColorstr='#faa21b', GradientType=0);
    padding: 10px 29px;
    border-radius: 12px;
    margin-top: -3px !important;
    margin-left: 10px;
}

.login-logout a:hover {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f9b44d+0,f99909+100 */
    background: #f9b44d; /* Old browsers */
    background: -moz-linear-gradient(top, #f9b44d 0%, #f99909 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f9b44d 0%, #f99909 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f9b44d 0%, #f99909 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9b44d', endColorstr='#f99909', GradientType=0); /* IE6-9 */

}

.help-mode-container {
    margin-top: 2px;
}

.skin-setting-dark .content {
    background: #232d2f;
}

.skin-setting-dark .sidebar {
    background: #172124;
}

.refresh-version {
    color: #004854;
    font-size: 15px !important;
}

.refresh-version h3 {
    color: #004854;
    font-size: 15px;
}

.skin-setting-dark .refresh-version h3 {
    color: #418995;
}

.refresh-version svg path {
    fill: #004854;
}

.skin-setting-dark .refresh-version svg path {
    fill: #418995;
}

.refresh-version svg {
    width: 30px;
    margin-top: -4px;
}

.menu-item-wrapper:last-child a {
    margin-bottom: 0px;
}

.sidebar + div {
    margin-left: 172px;
}

.sidebar .account, .sidebar .version {
    float: left;
    /* margin-left: 26px; */
    clear: both;
    margin-bottom: 0px;
    width: 100%;
}
.is-in-iframe .standard-box.scan-results {
    margin-bottom: 0px;
}


.is-in-iframe .invisible-wrap.one-third-block.first.scan-station-entry {
    margin-bottom: -44px;
}


.is-in-iframe .scan-station-results .pagination select {
    display: none;
}

.is-in-iframe .page-number + label {
    display: none;
}

.is-in-iframe.compact-view label {
    font-size: 11px;
}

.skin-setting-dark .is-in-iframe .standard-box {
    background: #323D3F;
}

.is-in-iframe #scanStationHeader .scan-station-header-close, body.skin-setting-dark .customised-layout-1 #scanStationHeader .scan-station-header-close {
    display: none;
}



.is-in-iframe .standard-box {
    border-radius: 0px;
}

.upload-new-logo {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 52px;
    padding: 52px 20px 20px 20px;
    border: 1px solid;
    margin-top: 20px;
}

.upload-new-logo p {
    position: absolute;
    top: 20px;
    font-size: 12px;
    color: #fff;
}

.skin-setting-bright .upload-new-logo p {
    color: #004854;
}

.customer-logo:hover button {
    opacity: 1;
}

.sidebar .account span span {
    color: #ee3124 !important;
    font-size: 21px;
    font-weight: 600;
}



.skin-setting-dark .sidebar .account span.username-text {
    color: #f0f4f5;
}

.menu-item-wrapper {
    float: left;
    width: 100%;
}

.menu-item-wrapper.home {
    border-top: 4px solid #ee3124;
    border-radius: 3px 3px 0px 0px;
}

.username-text span {
    color: #b7b9bb !important;
    margin-left: 0px !important;
    margin-top: -1px !important;
    float: left !important;
}

thead tr {
    background: none !important;
}

td, th {
    padding: 7px;
    color: #004854;
    font-size: 15px;
    font-weight: 600;
}

.logo a {
    font-size: 23.75px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    height: auto;
}

input.ng-touched.ng-empty, input.ng-invalid-email, .ng-submitted input.ng-invalid {

}

.icon-link {
    float: left;
    box-sizing: border-box;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    margin-bottom: 30px;
    background: #18b3a4;
    text-align: left;
    width: 32%;
    margin-right: 2%;
    position: relative;
    padding: 0px;
    height: 87px;
    line-height: 81px;
    border-radius: 10px;
}

.link-icon {
    float: left;
    width: 18%;
    height: 100%;
    text-align: center;
}

.icon-link svg {
    width: 39px;
}

.icon-link svg path, .icon-link svg g, .icon-link svg circle {
    fill: white !important;
    stroke: white !important;
}

.link-text {
    float: left;
    background: #004854;
    width: 78%;
    height: 87px;
    box-sizing: border-box;
    padding-left: 20px;
    border-radius: 10px;
    position: relative;
    /* line-height: 23px; */
    /* padding-top: 10%; */
    display: flex;
    align-items: center;
    line-height: 24px;
    padding-right: 18px;
    color: #fff;
}

.link-icon {
    color: #fff;
}

.job-page .link-text {
    padding-left: 0px;

}

.link-text::after {
    position: absolute;
    content: "";
    border-top: 8px solid transparent;
    border-left: 8px solid #004854;
    border-bottom: 8px solid transparent;
    background: none;
    right: -8px;
    top: 50%;
    margin-top: -8px;
}

.icon-link span {
    line-height: 21px;
    display: inline-block;
    vertical-align: middle;
}

.icon-link .raquo {
    line-height: 1.2;
}

.operation-station-setup-wrapper {
    position: relative;
}

.operation-station-setup-wrapper .delete {
    position: absolute;
}

.sub-header-wrapper {
    float: left;
    width: 100%;
}

.sub-header-wrapper h2 {
    float: left;
    width: 100%;
    font-size: 23.33px;
    font-weight: 600;
    margin-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
    margin-bottom: 16px;
    color: #18b3a4;
}

.invisible-wrap.full-size-block.section-block {
    border-bottom: 3.5px dotted #d7e2e4;
    padding-bottom: 0px;
    margin-bottom: 20px !important;
}

.refresh-version {
    position: relative;
    /* bottom: 20px; */
    /* left: 20px; */
    /* margin-top: 20px; */
    /* float: left; */
    cursor: pointer;
    margin: 20px 0 -20px 0px;
    display: inline-flex;
}

.invisible-wrap.full-size-block.section-block:nth-child(2n) h2 {
    color: #faa21a;
}

.invisible-wrap.full-size-block.section-block:nth-child(2n) .icon-link {
    background: #faa21a;
}

.invisible-wrap.full-size-block.section-block:nth-child(3n) h2 {
    color: #ee3124;
}

.invisible-wrap.full-size-block.section-block:nth-child(3n) .icon-link {
    background: #ee3124;
}

.job-setup .invisible-wrap.full-size-block, .home-admin .invisible-wrap.full-size-block, .home-page .invisible-wrap.full-size-block {
    margin-bottom: 0px;
}

.icon-link .raquo {
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -22px;
}

.existing-templates{
    padding: 20px;
    height: 120px;
}

.icon-link img {
    width: 29px;
    float: left;
}

.update-box input {
    float: left;
    clear: both;
}

.update-box {
    float: left;
    width: 100%;
    background: #f3f3f3;
    padding: 20px;
    box-sizing: border-box;
}

.page-header {
    float: left;
    width: 100%;
}

.update-close-button {
    font-size: 27px;
    float: right;
    margin-top: -19px;
    cursor: pointer;
}

.invisible-box.stations {
    float: left;
    margin-right: 40px;
    margin-bottom: 400px;
    width: 327px;
}

.container {
    padding: 0px;
    height: 100%;
    max-width : unset;
    margin: 0;
    width: 100%;
    display: inline-block;
}

a:hover {
    text-decoration: none;
}

.prompt {
    position: absolute;
    z-index: 1;
    width: 300px;
    background: #EE2C2C;
    color: #fff;
    padding: 50px;
    text-align: center;
    font-size: 20px;
    box-sizing: border-box;
    left: 50%;
    margin-left: -150px;
    top: 50%;
    margin-top: -150px;
}

.no-permission-pop-up {
    background: #FF3939;
    width: 300px;
    height: auto;
    z-index: 1;
    position: absolute;
    left: 50%;
    margin-left: -150px;
    margin-top: 150px;
    color: #fff;
    font-size: 24px;
    box-sizing: border-box;
    padding: 41px;
    text-align: center;
}

.no-permission-pop-up div {
    background: #fff;
    margin-top: 10px;
    color: #FF3939;
    float: left;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    padding: 10px 0px;
}

.navbar-brand {
    float: left;
    height: auto;
    width: 100px;
    padding-top: 6px !important;
}

.navbar-brand img {
    width: 100%;
}

.nav.navbar-nav {
    margin-top: 15px;
    margin-left: 20px;
}

.navbar.navbar-inverse {
    position: relative;
    height: 81px !important;

}

.navbar.navbar-inverse::before, .navbar.navbar-inverse::after {
    content: " inherit";
    position: absolute;
    height: 81px !important;
    width: 2002px;
    background: #222222;
    left: -2000px;
    top: -1px;
}

.navbar.navbar-inverse::after {
    right: -2000px;
    left: auto;
}

.page-header {
    margin-top: 0px;
    margin-bottom: 0px;
}

.h2, h2 {
    font-size: 25px;
    margin-bottom: 0px;
}

.pointer {
    cursor: pointer;
}

.date-container {
    position: relative;
    float: left;
    width: 100%;
    max-width: 300px;
    clear: both;
    margin-top: 30px;
}

.date-container .angular-date-picker, .angularjs-datetime-picker {
    position: absolute;
    top: -40px;
    display: none;
    z-index: 1;
}

.welcome-text {
    color: #fff;
    margin-top: 17px;
    font-size: 12px;
}

.main-report {
    white-space: pre;
}

.report-date-select {
    position: relative;
    z-index: 1;
}

.well.for-performance {
    overflow: hidden;
}

.for-performance td {
    border-top: 1px solid #000000 !important;
}

.for-performance th {
    border-bottom: 2px solid #000000 !important;
}

.station-password-cover, .background-cover {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    left: 0px;
    z-index: 9;
}

.skin-setting-dark .background-cover {
    background: rgba(0, 0, 0, 0.8);
}

.station-password-cover .form-group.well {
    width: 500px;
    margin-left: -250px;
    left: 50%;
    position: absolute;
    top: 101px;
    background: #b32525;
    color: #fff;
}

.background-cover .message {
    width: 300px;
    position: absolute;
    text-align: center;
    top: 200px;
    left: 50%;
    margin-left: -150px;
    font-size: 30px;
    font-weight: bold;
}

input[type="radio"] {
    float: left;
    clear: both;
    width: auto;
    margin: 3px 9px 0 0;
}

.form-control[type="radio"] {
    width: auto;
    float: left;
    clear: both;
    margin-right: 10px;
    /* margin-top: 19px; */
    padding: 0px;
    height: auto;
}

.pallet-data .duplicate {
    background: #f66;
    color: #fff;
}

.pallet-data .serial-number-not-empty {
    background: yellow;
}

.pallet-data .incorrect-box-number {
    background: orange;
}

.pallet-data td.checked {
    background: green !important;
    color: #fff !important;
}

label {
    float: left;
    margin-right: 20px;
    width: 100%;
    color: #004854;
    font-size: 15px;
    font-weight: 600;
    word-break: break-word;
}

.skin-setting-dark label {
    color: #f0f4f5;
}

.job-link {
    font-size: 25px;
    float: left;
    width: 91%;
    padding-left: 32px;
    margin-bottom: 13px;
    color: #686868;
    clear: both;
    border: 1px solid #c1c7db;
    padding: 13px 15px 8px 15px;
    box-sizing: border-box;
    margin-left: 4.5%;
    margin-right: 24px;
}

.job-link-details {
    font-size: 15px;
    line-height: 1.4;
    float: left;
    margin-bottom: 7px;
    color: #c1c7db;
}

.job-link a {
    float: left;
    width: auto;
    color: #fff;
    margin-right: 43px;
    text-decoration: none;
    font-size: 20px;
}

.job-link a span, .job-setup-link a span, .job-name a {
    color: #ee3124;
}

.job-items a {
    border: 1px solid #ddd;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    float: left;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
}

.job-items {
    float: left;
    width: 100%;
    margin-top: 31px;
}

.job-items a.complete {
    background: #1c761c;
    color: #fff;
    border-color: #1c761c;
}

.job-items a.wip {
    background: #bc7a00;
    color: #fff;
    border-color: #bc7a00;
}

.show-form-button {
    border-color: #fff;
}

.signature canvas {
    display: block;
    height: 200px !important;
    margin-bottom: 25px !important;
    background: #fff;
    width: 568px !important;
}

.field-rules {
    width: 400px;
    position: fixed;
    background: #f0f4f5;
    /* border: 1px solid #000; */
    padding: 30px;
    z-index: 999999;
    border-radius: 10px;
    margin-left: 0px;
    right: 0px;
    top: 35px;
    overflow: auto;
    height: 700px;
    -webkit-transform: translateX(400px);
    -moz-transform: translateX(400px);
    -ms-transform: translateX(400px);
    -o-transform: translateX(400px);
    transform: translateX(400px);
    border: 1px solid #000;
}

.field-rules.side-click-true {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}



.show-field-rule-parameters.side-click-true {
    -webkit-transform: translateX(-394px);
    -moz-transform: translateX(-394px);
    -ms-transform: translateX(-394px);
    -o-transform: translateX(-394px);
    transform: translateX(-394px);
}

.skin-setting-dark .field-rules {
    background: #455052;
}

.field-rules .close-rules {
    position: fixed;
    right: 12.5%;
    top: 32px;
    z-index: 2;
}

/*.field-rules-hover {*/
/*    width: 500px;*/
/*    position: absolute;*/
/*    background: #e1e5e6;*/
/*    !* border: 1px solid #000; *!*/
/*    padding: 30px;*/
/*    z-index: 1;*/
/*    border-radius: 10px;*/
/*    margin-left: -350px;*/
/*    left: 50%;*/
/*    top: 100px;*/
/*}*/

.hide {
    display: none;
}

.add-rule {
    /* border: 1px solid #000; */
    margin-top: 10px;
    float: left;
    margin-bottom: 30px;
}

.newrule-form {
    padding: 30px;
    margin-top: 13px;
    margin-bottom: 15px;
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.newrule-form-left {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.fields-preview .form-group {
    padding: 15px;
    border: 1px solid #ddd;
}

.close-rules {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 30px;
    cursor: pointer;
    z-index: 1;
    color: #004854 !important;
}

.skin-setting-dark .close-rules {
    color: #f0f4f5 !important;
}

.close-rules-scan-station-edit {
    float: right;
    right: 20px;
    top: 8px;
    font-size: 30px;
    cursor: pointer;
    z-index: 1;
    color: #004854 !important;
}

.close-rules.black {
    color: #000 !important;
}

.item-list-div {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.well {
    float: left;
    width: 100%;
}

.user-list .list-group-item {
    float: left;
    width: 100%;
}

.user-sign-off {
    float: left;
    width: 100% !important;
    margin-bottom: 10px;
}

.standard-box.language-settings, .standard-box.language-settings-input {
    float: left;
    width: 316px;
    margin-right: 30px;
}

.user-sign-off span {
    float: left;
    width: 40%;
}

.signed-in-tick {
    float: none !important;
    width: auto !important;
}

.field-rules label, .field-rules select, .field-rules input {
    width: 100%;
    word-break: break-word;
}

.report-list-item {
    float: left;
    width: 100%;
    /* border: 1px solid #ddd; */
    box-sizing: border-box;
    padding: 25px;
    margin-top: 20px;
    font-size: 20px;
}

.report-list-item button {
    float: left;
    clear: both;
}

.report-list-item label {
    width: 300px;
    font-size: 15px;
}

.form-group.date-container {
    margin-top: 20px;
    display: inline-block;
}

.navbar-inverse .navbar-brand {
    color: #c8c8c8;
    width: 244px;
    text-transform: uppercase;
    padding-top: 21px !important;
    font-size: 15px;
}

.navbar-brand span {
    float: left;
    width: 100%;
    text-transform: none;
    font-size: 12px;
}

.manage-users-role-select {
    float: left;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.reports h2 {
    /* margin-top: 40px; */
    float: left;
    border-bottom: 3px solid;
    padding-bottom: 10px;
    width: 100%;
}


.list-of-sidebar-pages {
    width: 100%;
}

.list-of-sidebar-pages.no-bottom-margin {
    margin-bottom: 0px;
}

.sidebar-item svg path {
    color: #ee3124;
    fill: #ee3124;
}

.sidebar-item.active img {
    opacity: 1;
}

.report-list-section button + button {
    margin-left: 0px;
}

.standard-box.outsideUser {
    opacity: .5;
}

.standard-box-scroll {
    float: left;
    width: 100%;
    overflow: auto;
}

.standard-box.login-page {
    width: 493px !important;
    float: none !important;
    margin: 47px auto !important;
    text-align: center;
    padding: 26px 15px 14px 15px;
    margin-top: 40px;
    margin-left: 40px;
    height: auto;
    overflow: hidden;
    max-width: 100%;
}

.login-page img {
    width: 46px;
}

.login-page h1 {
    font-weight: normal;
    font-size: 28px;
}

.login-page form {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.login-page h3 {
    font-size: 23.2px;
    font-weight: normal;
    text-align: center;
}

input, select, textarea {
    float: left;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 18px;
    background: none;
    border: 2px solid #8e94a6;
    font-size: 15px;
    font-weight: 300 !important;
    color: #004854;
    font-family: 'Montserrat', sans-serif;
}

.skin-setting-bright input, .skin-setting-bright select, .skin-setting-bright textarea {
	background: #c7cacc !important;
}

select {
    padding: 12px 10px 12px 10px;
}

.field-inner-container input, .field-inner-container select, .field-inner-containertextarea {
    margin-bottom: 20px;
}

button, input[type="submit"], .standard-box input[type="button"], .button {
    color: #fff;
    font-size: 17.5px;
    padding: 8px 25px;
    display: block;
    margin: 0 auto;
    width: auto;
    cursor: pointer;
    float: right;
    color: #fff;
    text-decoration: none;
    background: #4d7f88;
    background: -moz-linear-gradient(top, #4d7f88 0%, #004854 100%);
    background: -webkit-linear-gradient(top, #4d7f88 0%, #004854 100%);
    background: linear-gradient(to bottom, #4d7f88 0%, #004854 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d7f88', endColorstr='#004854', GradientType=0);
    border: 3px solid #f0f4f5;
    border-radius: 10px;
    font-weight: 500;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.skin-setting-dark button, .skin-setting-dark input[type="submit"], .skin-setting-dark .standard-box input[type="button"], .skin-setting-dark .button {
    border-color: #455052;
}

.rules-button {
    border-color: #374244 !important;
}

.skin-setting-dark .job-single-in-list button {
    border-color: #dfe7e9;
}

.skin-setting-dark .job-single-in-list button {
    border-color: #dfe7e9;
}

.skin-setting-bright .job-single-in-list button {
    border-color: #dfe7e9;
}

.skin-setting-dark .standard-box tr:nth-of-type(2n+1) button,
.skin-setting-dark .more-data-pop-up tr:nth-of-type(2n+1) button,
.skin-setting-dark .job-single-in-list:nth-of-type(2n+1) button,
.skin-setting-dark .sop-sign-off-table tr:nth-of-type(2n+1) button {
    border-color: #c8cbcb;
}

.skin-setting-bright .standard-box tr:nth-of-type(2n+1) button,
.skin-setting-bright .more-data-pop-up tr:nth-of-type(2n+1) button,
.skin-setting-bright .job-single-in-list:nth-of-type(2n+1) button,
.skin-setting-bright .sop-sign-off-table tr:nth-of-type(2n+1) button {
    border-color: #fff;
}

button:hover, input[type="submit"]:hover, .standard-box input[type="button"]:hover, .button:hover, .btn-primary:hover {
    background: #004854;
    border-color: #00080e !important;
    border-size: 3px;
}

.job-page .link-icon {
    background: #004854;
    border-radius: 10px 0px 0px 10px;
}

.job-page .link-text {
    border-radius: 0px 10px 10px 0px;
}

.customer-logo::before {
    content: "Your Logo can go here, contact us now to set this up at no cost.";
    text-align: center;
    float: left;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
    display: none;
}

.job-page .icon-link svg path {
    fill: #18b3a4;
}

.job-page .invisible-wrap.full-size-block.section-block:nth-child(2n) .icon-link svg path {
    fill: #faa21a;
}

.job-page .invisible-wrap.full-size-block.section-block:nth-child(3n) .icon-link svg path {
    fill: #ee3124;
}

.standard-box input[type="button"].red-button, button.red-button {
    background: #e84b68 !important;
}

.standard-box input[type="button"].red-button:hover, button.red-button:hover {
    background: #ff002f !important;
}

thead tr th {
    padding-bottom: 8px;
    color: #004854;
    font-weight: 600;
    position: relative;
}

.skin-setting-dark thead tr th {
    color: #f0f4f5;
}

.data-filtering-wrapper .users-selected-list > span {
    width: 100%;
    float: left;
}

.data-filtering-wrapper:hover .users-selected-list {
    display: block;
}

.data-filtering-wrapper {
    position: relative;
}

.data-filtering-wrapper .users-selected-list {
    position: absolute;
    display: none;
    background: #043e48;
    top: 59px;
    left: 0px;
    padding: 12px;
    box-sizing: border-box;
    width: 100%;
}

.data-filtering-wrapper select {
    cursor: pointer;
}

.standard-box.scan-results .inner-standard-box-wrapper > div {
    margin-bottom: 0px;
}

.open-formception-data-filtering.ng-binding {
    cursor: pointer;
}

.formception-container .invisible-wrap.two-thirds-block {
    width: 65.7%;
}

.submit-rule {
    float: left;
    clear: both;
    margin-top: 20px;
}

.add-user-to-job select {
    width: 100%;
}

.standard-box.anti-static-training-sign-off input {
    width: auto;
}

.standard-box input[type="button"] {
    float: left;
    width: auto;
    font-size: 15.2px;
}

.scan-station-front-end h1 + h2 a {
    font-size: 16px;
}


.breadcrumbs {
    font-size: 13.75px;
    color: #004854;
}

.skin-setting-dark .breadcrumbs {
    color: #f0f4f5;
}

.breadcrumbs a, .green-link {
    font-size: 13.75px;
    color: #ee3124;
    text-decoration: none;
}

.sidebar .sub-menu li {
    float: left;
    width: 100%;
    list-style: none;
    margin-left: 17px;
    margin-top: 20px;
    position: relative;
}

.sidebar .sub-menu .bullet {
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border: 1px solid #10a8ab;
    float: left;
    border-radius: 10px;
    margin-right: 9px;
    position: relative;
    background: #2c3c4a;
}

.sidebar .sub-menu li span + span {
    margin-top: -4px;
}

.sidebar .sub-menu li span + span a {
    color: #fff;
    text-decoration: none;
}

.sidebar .sub-menu li.active span + span a, .sidebar .sub-menu li:hover span + span a {
    color: #10a8ab;
}

.sidebar .sub-menu {
    float: left;
    margin-bottom: 25px;
}

.sidebar .sub-menu ul {
    padding: 0px;
    margin: 0px;
}

.job-link a:hover {
    color: #cdcdcd;
}

.job-link a:hover span {
    color: #3ae9bb;
}

.sidebar .sub-menu li span {
    color: #fff;
    font-size: 12.5px;
    float: left;
}

.sidebar .sub-menu li {
    z-index: 10;
}

.sidebar .sub-menu li + li {
    z-index: 9;
}

.sidebar .sub-menu li + li + li {
    z-index: 8;
}

.sidebar .sub-menu li + li + li + li {
    z-index: 7;
}

.sidebar .sub-menu li + li + li + li + li {
    z-index: 6;
}

.sidebar .sub-menu li + li + li + li + li + li {
    z-index: 5;
}

.sidebar .sub-menu li + li + li + li + li + li + li {
    z-index: 4;
}

.sidebar .sub-menu li + li + li + li + li + li + li + li {
    z-index: 3;
}


.standard-box.sign-in-out, .standard-box.log-anti-static-strap {
    float: left;
    width: 40%;
    margin-right: 20px;
}

.standard-box.sign-in-out .inner-standard-box-wrapper > div > span, .standard-box.log-anti-static-strap .inner-standard-box-wrapper > div > span {
    float: left;
    width: 100%;
}

table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

tr {
    background: #dfe7e9;
}

.skin-setting-dark tr {
    background: #cedade;
}

.standard-box tr:nth-of-type(2n+1), .more-data-pop-up tr:nth-of-type(2n+1), .job-single-in-list:nth-of-type(2n+1), .sop-sign-off-table tr:nth-of-type(2n+1) {
    background: #ffffff;
}

.skin-setting-dark .standard-box tr:nth-of-type(2n+1), .skin-setting-dark .more-data-pop-up tr:nth-of-type(2n+1), .skin-setting-dark .job-single-in-list:nth-of-type(2n+1), .skin-setting-dark .sop-sign-off-table tr:nth-of-type(2n+1) {
    background: #c8cbcb;
}

.load-details button {
    border-color: #dfe7e9;
}

.job-single-in-list:nth-of-type(2n+1) .load-details button {
    border-color: #fff;
}

.skin-setting-dark .job-single-in-list:nth-of-type(2n+1) .load-details button {
    border-color: #c8cbcb;
}

.standard-box.sop-files {
    clear: both;
    margin-right: 50px;
    margin-bottom: 30px;
}

.admin .job-link a:first-child {
    margin-right: 9px;
}

.standard-box.import-job button, .standard-box.close-job button {
    float: left;
    clear: both;
}

.delete {
    background: #e03232 !important;
}

.delete:hover {
    background: #be1010 !important;
}

.stations.admin .delete {
    margin-bottom: 41px;
}

.new-field {
    float: left;
    width: 40%;
}

.standard-box.data-tracking-fields {
    width: calc(100% - 172px);
}

.fields-preview {
    float: right;
    width: 51%;
}

.fields-preview button {
    float: left;
    margin-right: 20px;
    margin-bottom: 40px;
    margin-top: 10px;
}

.fields-preview button.rules-button {
    clear: both;
}

.field-count-box .arrow {
    border-color: #fff;
    margin-left: 4px;
    position: relative;
    top: -2px;
}

.field-count-box .arrow.up {
    top: 1px;
}

.rules-button {
    /* background: #6f6fa5 !important; */
    border-color: #e1e5e6;
}


.fields-preview + button {
    float: left;
    width: 300px !important;
    margin-bottom: 40px;
    margin-top: -9px;
    clear: both;
}

.standard-box.digital-signature .delete {
    float: left;
}

input[type="checkbox"] {
    width: auto;
    float: left;
    clear: both;
    margin: auto;
    margin-top: 5px;
    margin-right: 5px;
}

.manage-users .invisible-box {
    float: left;
    width: 475px;
    margin-right: 40px;
}

.list-group.user-list {
    list-style: none;
    padding: 0px;
    float: left;
    width: 100%;
}

.user-list .list-group-item {
    float: left;
    width: 100%;
    border: 1px solid #ddd;
    box-sizing: border-box;
    padding: 22px;
    margin-bottom: 27px;
}

.user-list button {
    float: left;
    font-size: 15.4px;
    width: 120px;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 15px;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

.icon-link:hover {
    background: #ee3124;
    color: #fff;
    border-color: #fff;
}

.icon-link.last {
    margin-right: 0px;
}

.icon-link:hover img {
    filter: brightness(0) invert(1);
}

.standard-box.upload-files {
    clear: both;
    margin-top: 30px;
}

.standard-box.add-user-to-job {
    margin-top: 30px;
}

.standard-box.update-job {
    width: 50%;
}

.inner-standard-box-wrapper a, .more-data-pop-up a {
    color: #ee3124;
    cursor: pointer;
}

.inner-standard-box-wrapper a:hover {
    color: #c10400;
}

tr.exception {
    background: #fc7676 !important;
    color: #fff !important;
}

input:disabled {
    opacity: .4;
}

.stock-station input:disabled {
    opacity: 1;
    border: 0px !important;
}

.tickbox-container {
    float: left;
    width: 100%;
    padding-bottom: 19px;
    color: #455263;
    font-weight: lighter;
}

.tickbox-container > input {
    float: left;
    margin-right: 11px;
}

.error-box {
    float: left;
    color: #fff;
    background: #e94e49;
    /* border: 4px solid #e94e49; */
    padding: 16px 40px 18px 56px;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* -moz-box-sizing: border-box; */
    box-sizing: border-box;
    border-left: 56px solid #e28582;
    border-radius: 11px;
}

.error-box::before {
    position: absolute;
    content: "!";
    left: -32px;
    top: 0px;
    font-size: 29px;
    font-weight: 900;
    height: 40px;
    top: 50%;
    margin-top: -20px;
}

.error-box img {
    width: 30px;
    position: absolute;
    left: 11px;
    top: 7px;
}

.standard-box.job-settings {
    margin-top: 30px;
}

.checkbox-wrapper{
    width: 100%;
}

i {
    /*border: solid black;*/
    /*border-width: 0 3px 3px 0;*/
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.ng-move {
    -webkit-transition: opacity .25s ease 0s;
    transition: opacity .25s ease 0s;
    opacity: 0;
}

.undo-button {
    float: left;
}

.skin-setting-dark .standard-box h3 {
    color: #f0f4f5;
}

.confirm-update {
    float: left;
    clear: both;
}

.update-data tr {
    background: none !important;
}


.updating {
    position: fixed;
    z-index: 99;
    background: rgba(255, 255, 255, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
}

td.full-width-true {
    position: fixed;
    top: 50px;
    left: 50%;
    width: 800px !important;
    max-width: none !important;
    z-index: 9999;
    background: none;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    border-radius: 12px;
    height: 400px;
    overflow: auto;
    margin-left: -200px;
}

td.full-width-true button {
    border-color: #e1e5e6;
}

.update-close-button + table + div label {
    margin-top: 30px;
}

td.full-width-true > button {
    display: none;
}

.update-data input {
    border-width: 1px !important;
    width: 100% !important;
}

.updating img {
    position: absolute;
    left: 50%;
    top: 30%;
    margin-left: -50px;
}

.sidebar-item.admin, .menu-item-wrapper.admin > a {
    margin-top: 0px;
}

.log-anti-static-strap > .inner-standard-box-wrapper > div {
    float: left;
    width: 100%;
    clear: both;
    padding-bottom: 10px;
    padding-top: 10px;
}

.standard-box.close-job {
    width: 457px;
}

.customer-name-logo {
    float: left;
    font-size: 12px;
    clear: both;
    margin-top: -19px;
    margin-left: 3px;
    color: #fff;
}

.customer-name-logo img {
    position: relative;
    left: 3px;
    top: 2px;
}

.delete-rule {
    float: right;
    margin-right: 5px;
    cursor: pointer;
    color: red;
    position: absolute;
    right: 0px;
    top: 3px;
}

.added-rules {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 22px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    position: relative;
    padding-right: 44px;
    border-radius: 10px;
}

.rule-details {
    font-size: 12px;
    color: #ffffff;
}

.skin-setting-bright .rule-details {
    color: #004854;
}

.angularjs-datetime-picker > .adp-month > button.adp-prev, .angularjs-datetime-picker > .adp-month > button.adp-next {
    display: inline !important;
    width: auto !important;
    padding: 5px;
    color: #004854 !important;
}

.version-update-required {
    position: fixed;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    z-index: 99;
}

.pop-up-version-update {
    width: 50%;
    position: absolute;
    left: 25%;
    text-align: center;
    background: #f0f4f5;
    top: 30%;
    box-sizing: border-box;
    padding: 2% 7% 1% 7%;
    border-radius: 10px;
}

.skin-setting-dark .pop-up-version-update {
    color: #232d2f;
}

.pop-up-version-update .close-rules {
    color: #004854 !important;
}

.refresh-button {
    border-color: #f0f4f5 !important;
}

.refresh-button {
    display: block;
    width: 58%;
    clear: both;
    margin: 0 auto;
    float: none;
    margin-top: 23px;
    margin-bottom: 22px;
    padding: 12px;
}

.job-link .counts {
    width: 100%;
    font-size: 10px;
    /* max-width: 241px; */
    float: left;
    clear: both;
}

.count-station {
    float: left;
    width: 50% !important;
    margin-bottom: 20px;
}

.count-station:nth-child(2n+1) {
    clear: both;
}

.count-totals {
    background: #20294c !important;
}

.move-left button {
    float: left;
    margin-top: 10px;
    font-size: 12px;
    padding: 7px 14px;
}

.count-station-title {
    float: left;
    width: 100% !important;
    font-size: 17px;
    color: #c1c7db;
    background: #000825;
    box-sizing: border-box;
    padding: 10px 10px 10px 17px;
}

.existing-signature {
    width: 200px;
    margin-bottom: 30px;
    margin-top: 25px;
    background: #fff;
}

.invisible-wrap {
    float: left;
    margin-right: 1.5%;
    margin-left: 1.5%;
    overflow: hidden;
}

.sidebar .sub-menu li.active span.bullet, .sidebar .sub-menu li:hover span.bullet {
    background: #10a8ab;
}

.sidebar .sub-menu .bullet::before {
    width: 1px;
    height: 30px;
    top: -30px;
    left: 4.5px;
    position: absolute;
    background: #10a8ab;
    content: "";
}

.sub-menu.orange .bullet::before {
    background: #fbb24c;
}

.sidebar .sub-menu.orange li.active span.bullet, .sidebar .sub-menu.orange li:hover span.bullet {
    background: #fbb24c;
}

.sidebar-item.orange-border.active, .sidebar-item.orange-border:hover {
    background: #fbb24c;
    border-left: 7px solid #fbb24c;
}

.sidebar-item.orange-border {
    border-color: #fbb24c;
}

.sidebar .sub-menu.orange .bullet {
    border: 1px solid #fbb24c;
}

.sidebar .sub-menu.orange li.active span + span a, .sidebar .sub-menu.orange li:hover span + span a {
    color: #fbb24c;
}

.sub-menu.cyan .bullet::before {
    background: #19b392;
}

.sidebar .sub-menu.cyan li.active span.bullet, .sidebar .sub-menu.cyan li:hover span.bullet {
    background: #19b392;
}

.sidebar-item.cyan-border.active, .sidebar-item.cyan-border:hover {
    background: #19b392;
    border-left: 7px solid #19b392;
}

.sidebar-item.cyan-border {
    border-color: #19b392;
}

.sidebar .sub-menu.cyan .bullet {
    border: 1px solid #19b392;
}

.sidebar .sub-menu.cyan li.active span + span a, .sidebar .sub-menu.cyan li:hover span + span a {
    color: #19b392;
}

.sidebar-item.red-border {
    border-color: #e64c66;
}

.sub-menu.purple .bullet::before {
    background: #bd6eab;
}

.sidebar .sub-menu.purple li.active span.bullet, .sidebar .sub-menu.purple li:hover span.bullet {
    background: #bd6eab;
}

.sidebar-item.purple-border.active, .sidebar-item.purple-border:hover {
    background: #bd6eab;
    border-left: 7px solid #bd6eab;
}

.sidebar-item.purple-border {
    border-color: #bd6eab;
}

.sidebar .sub-menu.purple .bullet {
    border: 1px solid #bd6eab;
}

.sidebar .sub-menu.purple li.active span + span a, .sidebar .sub-menu.purple li:hover span + span a {
    color: #bd6eab;
}

.sidebar-item.green-border {
    border-color: #5fb25f;
}

.sidebar-item.green-border.active, .sidebar-item.green-border:hover {
    background: #5fb25f;
    border-left: 7px solid #5fb25f;
}

.sidebar-item.blue-border {
    border-color: #4fc3f6;
}

.sidebar-item.blue-border.active, .sidebar-item.blue-border:hover {
    background: #4fc3f6;
    border-left: 7px solid #4fc3f6;
}

.content.full-width-content {
    max-width: none;
    padding-left: 0px;
}

.operation-station {
    border: 1px solid #50597a;
    background: #3b4465;
    color: #fff !important;
    font-size: 18.5px;
    text-decoration: none;
    padding: 24px 19px 26px 25px;
    width: 31%;
    float: left;
    box-sizing: border-box;
    margin-right: 2%;
    position: relative;
    margin-bottom: 20px;
}

.operation-station:hover {
    background: #464f70;
}

.operation-station:hover .arrow {
    right: 20px;
}

.operation-station .arrow {
    width: 25px;
    border-bottom: 2px solid #10a8ab;
    position: absolute;
    right: 29px;
    top: 50%;
    margin-top: -.5px;
}

.operation-station .arrow::after {
    width: 15px;
    border-bottom: 2px solid #10a8ab;
    position: absolute;
    content: "";
    top: -4px;
    transform: rotate(31deg);
    right: -1px;
}

.operation-station .arrow::before {
    width: 15px;
    border-bottom: 2px solid #10a8ab;
    position: absolute;
    content: "";
    bottom: -6px;
    transform: rotate(-31deg);
    right: -1px;
}

#echart-dataset-series-layout-by {
    width: 100%;
}

.arrow.up, .arrow.down {
    border-color: #c1c7db;
}

.operation-station-setup-wrapper {
    position: relative;
    float: left;
    width: 31%;
    margin-right: 2%;
    margin-bottom: 20px;
}

.operation-station-setup-wrapper .operation-station {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    position: relative;
}

.operation-station-setup-wrapper .delete {
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    padding: 4px 14px;
}

.operation-station:nth-child(3n), .operation-station-setup-wrapper:nth-child(3n) {
    margin-right: 0px;
}

.job-title-details {
    float: left;
    width: 100%;
}

.job-title-details a, .job-name a, .job-setup-link a {
    font-size: 17px;
    width: 100%;
    float: left;
    margin-bottom: 3px;
    text-decoration: none;
}

.icon-link.disabled {
    opacity: .4;
    cursor: default;
}

.breadcrumbs a:hover {
    color: #c60900 !important;
}

.one-third-block:not(.scan-station-entry) .icon-link {
    width: 100%;
    margin-right: 0px;
}

.form-group {
    float: left;
    width: 100%;
}

.generate-sample-button {
    float: left;
    margin-bottom: 20px;
}

.more-data-pop-up, .take-photo-pop-up {
    position: fixed;
    z-index: 2;
    background: #f0f4f5;
    padding: 20px;
    width: 80%;
    left: 10%;
    border: none;
    top: 6%;
    overflow: auto;
    max-height: 95%;
    overflow: auto;
    border-radius: 10px;
}

.skin-setting-dark .more-data-pop-up, skin-setting-dark .take-photo-pop-up {
    background: #455052;

}

.take-photo-pop-up.status-open {
    position: fixed;
    z-index: 1;
    background: #3b4465;
    padding: 20px;
    width: 80%;
    left: 10%;
    top: 10%;
    overflow: auto;
    width: 640px;
    height: 543px;
    overflow: hidden;
    left: 50%;
    margin-left: -320px;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.load-more-data-button {
    margin-bottom: 20px;
}

.more-data-pop-up td {
    font-size: 12px;
}

.more-data-pop-up td {
    font-size: 12px;
}

.field-count-table {
    float: left;
    width: 100%;
}

.field-count-box h4 {
    cursor: pointer;
}

.station-title-for-report {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}

.report-list-section {
    float: left;
    width: 47.5%;
    box-sizing: border-box;
    border: none;
    padding: 3%;
    background: #e4e8e9;
    border-radius: 10px;
}

.skin-setting-dark .report-list-section {
    background: #374244;
}

.report-list-section button {
    border-color: #e4e8e9;
}

.skin-setting-dark .report-list-section button {
    border-color: #374244;
}

.report-list-section + .report-list-section {
    margin-left: 5%;
}

.report-options-list div {
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.report-options-list {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.bg-success {
    float: right;
    width: 100%;
    text-align: right;
    background: #5e925e;
    box-sizing: border-box;
    color: #fff;
}

.new-field input[type="radio"], .edit-field-popup input[type="radio"] {
    float: left;
    width: auto;
    margin-bottom: 0px;
}

.outer-container-edit-field {
    position: relative;
}

.customised-layout-0 .outer-container-edit-field {
    float: left;
    width: 100%;
}



.and-gate-wrapper {

    float: left;
    width: 100%;
    margin-bottom: 21px;

}

.outer-container-edit-field:hover > div:not(.edit-field-popup-on-hover), .outer-container-edit-field:hover h2, .outer-container-edit-field:hover p {
    opacity: .3 !important;
}

.outer-container-edit-field:hover {
    border-color: #1f3354;
}

.field-rules-hover .invisible-wrap {
    margin-bottom: 0px;
}

.outer-container-edit-field .reorder-field-arrows {
    float: left;
}

.outer-container-edit-field .rules-button, .outer-container-edit-field .edit-field-popup-on-hover .delete {
	width: 100% !important;
	margin-bottom: 5px;
	font-size: 11px;
}

.new-field button {
    clear: both;
    margin: 0px 35px 20px 35px;
    border: none !important;
}

.report-list-section p {
    font-size: 15px;
    width: 100%;
    border-bottom: 1px solid;
    padding-bottom: 20px;
}

.manage-page-roles {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 27px;
    max-height: 1.2em;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    -webkit-transition: 1s;
    transition: 1s;
    overflow: hidden;
}

.manage-page-roles.open {
    max-height: none;
}

.manage-page-roles label:hover {
    cursor: pointer;
    color: #d8dbe6;
}

.user-buttons button {
    width: auto;
    margin-left: 21px;
    font-size: 15px;
    padding: 8px 16px !important;
}

.cartolytics-instance {
    float: left;
    width: 90%;
    margin-top: 7px;
    background: #20243f;
    box-sizing: border-box;
    padding: 15px 10px;
    border-radius: 0px 5px 5px 0px;
}

.cartolytics-instance > span {
    color: #ffff;
    margin-left: 10px;
    position: relative;
    top: -2px;
}

.language-li {
    position: relative;
}

.language-drop-down {
    max-height: 0px;
    position: absolute;
    top: 27px;
    background: #4f4f7a;
    width: auto;
    padding: 0px;
    left: 30px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1;

}

.language-li:hover .language-drop-down, .language-drop-down:hover {
    max-height: 100px;
    padding: 12px 20px 10px 10px;
}

.language-item {
    font-size: 13px;
    width: 100%;
    float: left;
    margin-bottom: 3px;
    /* border-bottom: 1px solid #b4b0c4; */
    /* padding-bottom: 2px; */
    cursor: pointer;
}

.language-item:hover {
    color: #deeaf6;
}

#adp-time {
    display: none;
}

.show-import-button.ng-scope {
    width: 100%;
    margin-top: 18px;
}

.job-list-header-titles {
    float: left;
    width: 100%;
}

.job-list-header-titles div, .job-single-in-list div {
    float: left;
    width: auto;
    padding: 10px;
    margin: auto;
    vertical-align: middle;
    box-sizing: border-box;
}

.job-single-in-list {
    float: left;
    width: 100%;
    background: #dfe7e9;
}

.hourInput, .minutesInput {
    padding: 0px !important;
    margin: 0px !important;
}

.job-name {
    width: 18% !important;
}

.job-single-in-list .job-setup-link, .job-single-in-list .customer, .job-single-in-list .job-number, .job-single-in-list .reference-no {
    width: 10% !important;
}

.job-single-in-list.rma-titles .job-setup-link,
.job-single-in-list.rma-titles .customer,
.job-single-in-list.rma-titles .job-number,
.job-single-in-list.rma-titles .reference-no,
.job-single-in-list.rma-listing .job-setup-link,
.job-single-in-list.rma-listing .customer,
.job-single-in-list.rma-listing .job-number,
.job-single-in-list.rma-listing .reference-no {
    width: 11% !important;
}

.job-created-date {
    width: 10% !important;
}

.load-details button {
    font-size: 12px;
    padding: 7px 20px;
}

.show-more-job-details {
    float: left;
    width: 100%;
    clear: both;
}

.show-more-job-details {
    float: left;
    width: 100% !important;
    clear: both;
    background: #3b4465;
    border: 1px solid #616983;
    box-sizing: border-box;
}

.job-single-in-list.is-active-1 + .is-active-0::before {
    content: "Other Incomplete Jobs";
    float: left;
    width: 100%;
    font-size: 20px;
    margin-top: 22px;
    padding-bottom: 15px;
    border-bottom: 1px solid #5c678c;
}

.standard-box.scan-form.job-status-0, .standard-box.scan-form.limit-reached-1 {
    position: relative;
}

.standard-box.scan-form.job-status-0::before, .standard-box.scan-form.limit-reached-1::before {
    content: "Job Completed: Station Disabled";
    color: #fff;
    opacity: .7;
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0px;
    height: 100%;
    background: #181e26;
    top: 0px;
    box-sizing: border-box;
    padding-top: 50px;
    text-align: center;
    font-size: 28px;
}

.standard-box.scan-form.limit-reached-1::before {
    content: "Scan Limit Reached";
}

.field-status {
    margin: 0px !important;
    font-size: 12px !important;
    padding: 7px 15px;
    float: right !important;
}

.field-status-0 {
    opacity: 0.3;
}

.count-limit {
    float: left;
    width: auto;
    margin-left: 27px;
    margin-bottom: 10px;
    background: green;
    padding: 10px;
    color: #fff;
}

.update-scan-limit {
    margin-bottom: 13px;
    float: left;
    margin-top: 10px;
}

.update-scan-limit + .delete {
    margin-top: 10px;
}

.day {
    float: left;
    padding-bottom: 50px;
    border-left: 1px dashed;
    padding-right: 22px;
    padding-left: 5px;
    margin-top: 27px;
    line-height: .5;
    position: relative;
    font-size: 11px;
}

.capacity-line {
    width: 4300px;
    position: relative;
    float: left;
}

.capacity-line-container {
    overflow: auto;
}

.capacity-line-container .standard-box {
    width: auto;
}

.day span {
    position: absolute;
    top: -19px;
    left: 5px;
    font-size: 15px;
}

.capacity-line .job {
    bottom: 0px;
    position: absolute;
    height: 42px;
    padding-bottom: 10px;
    color: #fff;
    z-index: 2;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
}

.capacity-line-container .standard-box {
    margin-bottom: 35px;
}

.capacity-line-container h2 {
    border-bottom: 0px;
    padding-bottom: 0px;
    margin-bottom: 9px;
}

.capacity-line-container .standard-box {
    margin-bottom: 19px;
}

.demo-reporting-page button {
    float: left;
}

#video, #grab-frame, #take-photo {
    width: 100%;
    height: 400px;
    float: left;
}

.file-upload-container {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 0px;
    margin-bottom: 0px;
}

.file-upload-container input {
    width: 66%;
}

.file-upload-container button {
    float: left;
    text-align: center;
    padding: 0px;
    box-sizing: border-box;
    height: 46px;
    width: 63px;
    margin-left: 3%;
}

.file-upload-container label {
    font-weight: bold;
}

.file-upload-container button img {
    display: block;
    width: 34px;
    margin: 0 auto;
    /* max-width: 85%; */
}

.take-photo-pop-up.status-closed {
    width: 1px;
    transform: translateX(-450px);
}

.take-photo-pop-up video {
    width: 100%;
}

#onTakePhotoButtonClick {
    text-align: center;
    float: left;
    display: block;
    float: none;
    margin-top: 24px;
    margin-bottom: 16px;
    width: 208px;
    padding: 20px 0px;
}

.photo-preview {
    position: fixed;
}

.assign-users-list div:hover {
    background: #c1c7db;
    color: #3b4465;
}

#canvas {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.assign-users-list div {
    float: left;
    margin-right: 10px;
    padding: 4px 12px;
    border: 1px solid;
    border-radius: 7px;
    margin-bottom: 10px;
    cursor: pointer;
}

.error-box.mobile {
    position: fixed;
    top: 0px;
    z-index: 99;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 23px 5% 26px 5% !important;
    font-size: 16px;
}

.checklist-title-row, .checklist-item-row {
    float: left;
    width: 100%;
    padding-bottom: 7px;
    border-bottom: 1px solid #5d5d83;
}

.checklist-item-row .second, .checklist-item-row .first, .checklist-item-row .third {
    float: left;
    width: 31%;
}

.repair-section .checklist-item-row .second, .repair-section .checklist-item-row .first, .repair-section .checklist-item-row .third, .repair-section .checklist-item-row .fourth {
    float: left;
    width: 22%;
}

.checklist-item-row.titles {
    font-weight: bold;
    margin-top: 19px;
}

.checklist-item-row input {
    border-width: 1px;
    width: 80%;
}

.checklist-item-row.PASS {
    position: relative;
    opacity: .2;
}

.checklist-item-row.PASS::before {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0px;
    content: "";
    background: transparent;
    opacity: .3;
}

.checklist-item-row:not(.titles) {
    padding-top: 16px;
    padding-bottom: 0px;
}

.add-new-customer button {
    float: left;
    margin-bottom: 20px;
}

.add-new-customer input {
    float: left;
    width: auto;
    margin-right: 20px;
}

.newrule-form-right h4 {
    width: 100%;
    float: left;
}

.populate-other-fields-from-response {
    float: left;
    width: 100%;
}

.populate-other-fields-from-response input {
    margin-top: 7px;
}

.error-box.yellow {
    background: #e6e60d;
    color: #4d4d21;
    border-color: #b7b700;
}

.grey-button {
    background: grey !important;
}

.audit-item button {
    float: left;
    margin-right: 10px;
    opacity: .5;
}

.audit-item button.enabled {
    opacity: 1;
}

.audit-item-title {
    float: left;
    margin-right: 25px;
    margin-top: 5px;
}

.audit-item .description {
    float: left;
    width: auto;
    margin-top: 10px;
}

.audit-item .description input {
    width: 100%;
    height: 38px;
    box-sizing: border-box;
}

.audit-item {
    float: left;
    width: 100%;
}

.audit-item-statuses {
    clear: both;
    margin-top: 10px !important;
    float: left;
}

.quality-box {
	width: 100%;
	float: left;
	margin-bottom: 30px;
	box-sizing: border-box;
	padding-left: 8%;
	padding-right: 8%;
}

.sub-header-wrapper .delete {
    float: none;
    margin: 0;
    clear: none !important;
    display: inline;
    margin-left: 20px;
    font-size: 13px;
    padding: 4px 17px;
    border: none;
}

.fields-preview > div {
    border-bottom: 1px solid;
    float: left;
    width: 100%;
    margin-bottom: 24px;
}

.field-button {
    background: #58cb78;
    background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#58cb78', endColorstr='#35b835', GradientType=0);
    float: left;
    width: 100%;
    font-weight: 600;
    border-radius: 15px;
    border: 3px solid #eaeaea;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.skin-setting-dark .field-button {
    border-color: #455052;
}

.multi-button {
    float: left;
    background: #58cb78;
    background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#58cb78', endColorstr='#35b835', GradientType=0);
    margin-right: 5px;
    margin-bottom: 9px;
    color: #fff !important;
    padding: 6px 22px;
    border-radius: 14px;
    font-size: 15px;
    font-weight: 600;
    box-sizing: border-box;
    border: 3px solid #eaeaea;
    transition: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -o-transition: none !important;
}

.skin-setting-dark .multi-button {
    border-color: #455052;
}

.multi-button-container {
    float: left;
    width: 100%;
    margin-bottom: 19px;
}

.multi-button.selected, .multi-button:hover, .field-button:hover, .field-button.selected {
    opacity: 1;
    border: 3px solid #007304;
    /* margin-left: 0px; */
    /* margin-top: -3px; */
    background: #10b43e;
}

.field-button.selected {
    background-color: #007E08;
    border-color: #000;
}

.manage-conditional-status, .newrule-form {
    float: left;
    width: 100%;
    border: none;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 44px;
    background: #dee2e3;
    border-radius: 15px;
}

.skin-setting-dark .manage-conditional-status, .skin-setting-dark .newrule-form {
    background: #374244;

}

.manage-conditional-status button, .newrule-form button {
    border-color: #dee2e3;
}

.skin-setting-dark .manage-conditional-status button, .skin-setting-dark .newrule-form button {
    border-color: #374244;
}

.icon-link-wrapper .delete {
    float: left;
    margin-left: 0px;
}

.icon-link-wrapper .icon-link {
    margin-bottom: 0px;
    width: 100%;
}

.icon-link-wrapper {
    float: left;
    width: 30%;
    margin-right: 2%;
    margin-bottom: 20px;
    position: relative;
}

.multi-button.delete {
    background: red !important;
}

.plain-data {
    float: left;
    width: 100%;
    margin-bottom: 26px;
    font-size: 19px;
}

.open-file-link {
    cursor: pointer;
}

.load-details {
    float: right !important;
}

.job-list-header-titles .customer, .job-list-header-titles .job-number, .job-list-header-titles .reference-no {
    width: 11% !important;
}

.job-list-header-titles.rma-titles .customer, .job-list-header-titles.rma-titles .job-number, .job-list-header-titles.rma-titles .reference-no {
    width: 11% !important;
}

.job-list-header-titles .job-setup {
    width: 10% !important;
}

.job-single-in-list .reference-no {
    width: 11% !important;
}

.job-single-in-list .reference-no {
    width: 13% !important;
}

.analyics-chart {
    float: left;
    width: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 20px;
}

canvas {
    max-width: 100% !important;
}

.sidebar-item.orange-border.active span {
    color: #513f00;
}

.sidebar-item.orange-border.active img {
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(899%) hue-rotate(25deg) brightness(94%) contrast(101%);
}

.skin-setting-dark .rule-broken, .skin-setting-bright .rule-broken {
    border: 2px solid red !important;
    background: #b71f1f !important;
    color: white !important;
    font-weight: bold !important;
}

.skin-setting-dark .rule-broken::-webkit-input-placeholder, .skin-setting-dark .rule-broken::-moz-placeholder, .skin-setting-dark .rule-broken::placeholder,
.skin-setting-bright .rule-broken::-webkit-input-placeholder, .skin-setting-bright .rule-broken::-moz-placeholder, .skin-setting-bright .rule-broken::placeholder {
    color: #fff !important;
}

.job-count-data-container {
    float: left;
    overflow: auto;
    width: 100%;
    max-height: 500px;
}

.job-single-in-list .user-name {
    width: 68px;
    text-align: center;
}

.job-count-data-container .job-list-header-titles {
    /* margin-top: 127px; */
    border-top: 1px solid;
    padding-top: 96px;
    margin-bottom: 18px;
    border-bottom: 1px solid;
    padding-bottom: 25px;
}

.job-list-header-titles .user-name {
    transform: rotate(90deg);
    margin-right: -109px;
    position: relative;
    left: -51px;
    width: 177px;
}

.job-count-data-container .job-name, .job-count-data-container .job-number {
    width: 200px !important;
}

.job-count-data-container .job-single-in-list:hover {
    background: #464870;
    cursor: pointer;
}

.station-count-data {
    float: left !important;
    width: 100% !important;
    clear: both;
}

.station-count-data .job-name, .station-count-data .user-name {
    position: relative;
    left: -10px;
}

.waiting-for-count-data {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 52px;
    border-bottom: 1px solid;
    padding-bottom: 40px;
    border-top: 1px solid;
    padding-top: 40px;
}

.error-box.generic-error {
    text-align: left;
}

.sop-sign-off-table .unsigned {
    color: red;
    cursor: pointer;
}

.sop-sign-off-table .operator-signed {
    color: orange;
    cursor: pointer;
}

.sop-sign-off-table .sop-completed {
    color: #11f611;
    cursor: pointer;
}

.sop-digital-signature img {
    width: 100%;
}

.sop-digital-signature {
    background: #fff;
    width: 300px;
    margin-top: 20px;
}

.for-sop-sign-offs h3 {
    margin-bottom: 0px;
}

.signature {
    float: left;
}

.checklist-item-row.PASS {
    opacity: .4;
}

.column-name {
    float: left;
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #0c131f;
}

.columns {
    width: 100%;
    float: left;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    background: #3e3e90;
    padding: 14px 10px 22px 196px;
}

.row-name {
    float: left;
    margin-left: -171px;
    max-width: 163px;
    min-height: 50px;
}

.row-data {
    float: left;
    width: 50%;
    text-align: center;
    border-left: 1px solid #0c131f;
    box-sizing: border-box;
    min-height: 50px;
    padding-top: 12px;
}

.for-live-data-selection select {
    float: left;
    width: 40%;
    margin-right: 5%;
}

.for-live-data-selection .invisible-wrap.one-half-block {
    width: 47.4%;
}

.for-live-data-selection select {
    float: left;
    width: 100%;
}

.report-list-item.for-automated-reports {
    margin-bottom: 30px;
}

.new-automated-report-station {
    float: left;
    clear: both;
    border-bottom: 1px solid #6a6dae;
    padding-bottom: 10px;
    padding-top: 10px;
}

.new-automated-report-station {
    float: left;
    clear: both;
    border-bottom: 1px solid #727285;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
}

.new-automated-report-station p {
    margin: 0px;
    width: 100%;
}

.new-automated-report-station > div > div {
    float: left;
    width: auto;
    margin-right: 16px;
    margin-left: 0px;
    margin-top: 6px;
}

.new-automated-report-station > div {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    border-left: 1px solid;
    margin-left: 9px;
}

.live-data-pop {
    position: fixed;
    z-index: 9999;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #29296b;
    top: 0px;
}

.pop-up-logo {
    position: absolute;
    width: 197px;
    text-align: center;
    font-size: 24px;
    padding-top: 7px;
    color: #fff;
    /* background: #0e1124; */
    padding-bottom: 12px;
}

.pop-up-logo img {
    width: 164px !important;
    max-width: none;
}

.scan-station-front-end h1 + h2 {
    float: left;
    clear: both;
    font-size: 19px;
    font-weight: normal;
}

.scan-station-front-end h1 + h2 a, .edit-station {
    color: #ee3124;
    text-decoration: none;
}

.include-counts-checks {
    float: left;
    /* clear: both; */
    width: auto;
    margin-top: 10px;
    font-size: 16px;
    margin-right: 22px;
}

.field-count-box {
    float: left;
    width: 100%;
    /* margin-top: 40px; */
    clear: both;
}

.include-counts-checks + .field-count-box {
    margin-top: 40px;
}

.include-counts-checks input {
    float: left;
    margin-right: 10px;
    position: relative;
    top: -3px;
}

.performace-data-required input {
    margin-left: 10px;
    /* float: left; */
    margin-right: 5px;
}

.refine-search {
    float: left;
    width: 81%;
    margin-left: 20px;
}

.refine-search div {
    float: left;
    margin-right: 22px !important;
}

.job-list-header-titles div:not(.job-setup) {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

.job-list-header-titles div:not(.job-setup)::after {
    content: " ↑↓";
}

/* Analytics */
#graphContainer, #graphContainerFullScreen {
    height: 500px;
    margin-top: 30px;
    color: white;
    margin-bottom: 20px;
}

#graphContainerFullScreen {
    margin-top: 70px;
}

.analytics-graph-style-container {
    width: 45%;
    height: auto;
    opacity: 0.8;
    border: 1px solid #616983;
    cursor: pointer;
}

.analytics-graph-style-img {
    width: 100%;
    height: auto;
}

.cartolytics-instance img {
    float: left;
    width: 20%;
}

.customised-layout-1 .scan-station-entry {
    width: 100% !important;
}

.customised-layout-1 .custom-field-width-1 {
    width: 10%;
}

.customised-layout-1 .custom-field-width-11 {
    width: 14.2857%;
}

.customised-layout-1 .custom-field-width-2 {
    width: 20%;
}

.customised-layout-1 .custom-field-width-3 {
    width: 33.33%;
}

.customised-layout-1 .custom-field-width-4 {
    width: 40%;
}

.customised-layout-1 .custom-field-width-5 {
    width: 50%;
}

.customised-layout-1 .custom-field-width-6 {
    width: 60%;
}

.customised-layout-1 .custom-field-width-7 {
    width: 70%;
}

.customised-layout-1 .custom-field-width-8 {
    width: 80%;
}

.customised-layout-1 .custom-field-width-9 {
    width: 90%;
}

.customised-layout-1 .custom-field-width-10 {
    width: 100%;
}

.customised-layout-1 .field-outer-container {
    float: left;
    /* border: 1px solid #8a8b9d; */
    box-sizing: border-box;
    padding-top: 10px;
    margin-bottom: 8px;
    border: none;
}

/*.field-outer-container {*/
/*    !*margin-bottom: 21px !important;*!*/
/*}*/

.customised-layout-1 .multi-button-container {
    margin-bottom: 0px !important;
}

.customised-layout-1 input {
    margin-bottom: 0px;
}

.customised-layout-1 .custom-field-next-line-1 {
    clear: both;
}

.customised-layout-1 input, .customised-layout-1 select, .customised-layout-1 textarea {
    border: 0px;
    background: #ffffff;
    border-radius: 5px;
    color: #004854;
}

.customised-layout-1 .field-outer-container {
    float: left;
}

.customised-layout-1 .field-outer-container {
    float: left;
    min-height: 71px;
    padding-bottom: 10px;
}

.customised-layout-1 .row .field-outer-container {
    width: 100%;
}

.show-for-customised-1 {
    display: block;
}

.show-for-customised-0 {
    display: none;
}

.customised-layout-1 .field-outer-container + .field-outer-container:not(.custom-field-next-line-1):not(.custom-field-width-10) {
    border-left: 0px;
}

.show-for-customised-1 {
    display: block;
    font-weight: bold !important;
}

.customised-layout-1 .scan-station-results {
    float: left;
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 0px;
    margin-bottom: 15px !important;
}

.customised-layout-1 .scan-results + .scan-results {
    margin-top: 15px;
}

.analytics-graph-style-container.float-left {
    float: left;
}

.analytics-graph-style-container.float-right {
    float: right;
}

.analytics-graph-style-container.active {
    opacity: 1;
    border: 1px solid #ffffff;
}

.analytics-graph-style-title {
    margin-bottom: 0.5em;
    text-align: center;
}

.data-display-template {
    cursor: pointer;
}

.edit-automated-report {
    float: none;
    font-size: 12px;
    padding: 4px 21px;
    margin: 0px;
    display: inline;
    margin-left: 10px;
}

.existing-automated-report {
    float: left;
    width: 100%;
    margin-bottom: 8px;
}

.edit-automated-report-wrapper {
    float: left;
    width: 100%;
    border: 1px solid;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 17px;
}

.close-report-edit {
    color: white;
    float: right;
    cursor: pointer;
    font-weight: bold;
}

.top-form-counter {
    float: left;
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
    color: #fff;
    margin-left: 30px;
}

.fields-preview button.rules-button + .rules-button {
    clear: none;
}

.BW-special-counts {
    float: left;
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
    color: #fff;
}

.sidebar-item .two-lines {
    margin-top: -4px;
}

.clear-both {
    clear: both;
}

.button.next-previous-button {

    color: #fff !important;
    margin-left: 20px;
    text-decoration: none !important;
}

.button.next-previous-button.next {
    float: right;
}

.button.next-previous-button.previous {
    float: left !important;
    margin-left: 0px !important;
}

.position-relative {
    position: relative;
}

.rma-titles > div {
    font-weight: bold;
    color: #fff;
}

.rma-titles .job-name, .rma-listing .job-name {
    width: 11% !important;
}

.manage-rma h2 {
    font-weight: normal;
}

.manage-rma .inner-standard-box-wrapper {
    width: 100%;
}

.create-rma-request-message {
    color: #a2bfa2;
    font-size: 24px;
}

.unit-listing {
    width: 100%;
    /* border: 1px solid; */
    float: left;
    /* padding: 20px; */
    box-sizing: border-box;
    margin-bottom: 6px;
}

.failure-info-column {
    float: left;
    width: 25%;
    box-sizing: border-box;
    padding-right: 1%;
    min-height: 1px;
}

.unit-listing .unit-quantity {
    width: 28%;
    display: inline-block;
}

.unit-type, .unit-quantity {
    float: left;
    width: 50%;
}

.files-list li {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.files-list a {
    text-decoration: navajowhite;
    margin-bottom: 4px;
    /* float: left; */
    /* width: 100%; */
    clear: both;
}

.unit-listing .unit-quantity + .unit-quantity {
    width: 20% !important;
}

.files-list {
    width: 100% !important;
}

.rma-admin-file-add {
    margin-left: 5px;
}

.received-unit {
    margin-bottom: 5px !important;
}

.unit-listing + button {
    float: left;
    margin-top: 9px;
}

.wrapper-no-padding {
    padding: 0px !important;
}

.request-rma .invisible-wrap.full-size-block {
    margin-bottom: 30px;
}

.failure-info-column input {
    border-width: 1px;
    margin-bottom: -5px;
}

.unit-type, .unit-quantity {
    float: left;
    width: 50%;
    padding-right: 4%;
    box-sizing: border-box;
}

.failure-info-column.heading {
    font-weight: bold;
    margin-bottom: 15px;
}

.failure-info-column .delete {
    float: left;
}

.failure-info-column input[type="file"] {
    padding: 0px;
}

.unit-listing input, .unit-listing select {
    border-width: 1px !important;
    margin-bottom: 0px;
}

/*.job-page:not(.stock-management) .operation-station {
    padding: 9px 20px;
    font-size: 15px;
}*/

.job-page:not(.stock-management) .operation-station-setup-wrapper {
    float: left;
    /* width: 100% !important; */
    margin-bottom: 5px;
}

.total-price {
    float: left;
    width: 100%;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
}

.job-name.for-customer {
    width: 100% !important;
}

.submitted-status-0 {
    /*background: #aa4763 !important;*/
    /*background: #e9b6bc !important;*/
    background: #f4cd86 !important;
}

.my-active-jobs .job-list-header-titles div:not(.job-setup) {
    color: #004854;
    font-weight: 600;
    cursor: pointer;
}

.skin-setting-dark .my-active-jobs .job-list-header-titles div:not(.job-setup) {
    color: #f0f4f5;
}

.job-single-in-list div {
    overflow-wrap: break-word;
    font-weight: 600;
}

.my-active-jobs .job-list-header-titles div:not(.job-setup)::after {
    content: " ↑↓";
}

.scan-station-results-block-display {
    box-sizing: border-box;
    float: left;
    padding: 20px;
    width: 100%;
    border: 1px solid;
    border-radius: 15px;
}

.skin-setting-dark .scan-station-results-block-display h3 {
    color: #fff;
}

.skin-setting-bright .scan-station-results-block-display h3 {
    color: #004854;
}

.single-result {
    float: left;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    padding-top: 10px;
}

.field-title {
    float: left;
    width: 60%;
    clear: both;
    box-sizing: border-box;
    padding-right: 50px;
}

.value {
    float: left;
}

.my-active-jobs .job-status {
    width: 8% !important;
}

.my-active-jobs .customer {
    width: 8% !important;
}

.my-active-jobs .assigned-users {
    width: 10% !important;
}

.my-active-jobs .unit-type {
    width: 13% !important;
}

.support-about h2 {
    /* color: #fff; */
    font-size: 20px;
    font-weight: 600;
    margin-top: 0px;
}

.edit-station-background {
    position: absolute;
    left: 0px;
    height: 100%;
    z-index: 99999;
    width: 100%;
    background: rgba(32, 36, 63, .8);
    box-sizing: border-box;
    padding-left: 30px;
    padding-top: 100px;
}

.job-single-in-list div {
    overflow-wrap: break-word;
}

.edit-station-background {
    font-size: 34px;
    color: #fff;
}

.active-text button {
    margin: 0px 35px !important;
    display: inline;
    float: none;
    /* font-size: 16px !important; */
}

.active-text {
    font-size: 18px;
}

.operation-station .revision {
    font-weight: 300;
    font-size: 14px;
}

.field-outer-container.field-type-seperator {
    width: 100% !important;
    border: 0px !important;
    height: auto;
    padding-top: 50px !important;
    padding-bottom: 54px !important;
    float: left;
    margin: 0px !important;
}

.field-outer-container .seperator-splitter {
    border-bottom: 1px solid #8a8b9d;
    height: 0px !important;
    width: calc(100% + 20px);
    margin-left: -10px;
    float: left;
}

.multi-button:nth-child(1), .fields-preview .multi-button:nth-child(1),
.multi-button:nth-child(8), .fields-preview .multi-button:nth-child(8),
.multi-button.green-colour, .fields-preview .multi-button.green-colour {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f36f66+0,ee3124+100 */
    background: #58cb78;
    background: -moz-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: -webkit-linear-gradient(top, #58cb78 0%, #35b835 100%);
    background: linear-gradient(to bottom, #58cb78 0%, #35b835 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#58cb78', endColorstr='#35b835', GradientType=0);
}

.multi-button:nth-child(1):hover, .multi-button:nth-child(1).selected, .fields-preview .multi-button:nth-child(1):hover, .fields-preview .multi-button:nth-child(1).selected,
.multi-button:nth-child(8):hover, .multi-button:nth-child(8).selected, .fields-preview .multi-button:nth-child(8):hover, .fields-preview .multi-button:nth-child(8).selected,
.multi-button.green-colour.selected, .multi-button.green-colour:hover, .fields-preview .multi-button.green-colour:hover,  .fields-preview .multi-button.green-colour.selected {
    background: #007E08;
    border-color: #000;
}

.multi-button:nth-child(2):hover, .multi-button:nth-child(2).selected, .fields-preview .multi-button:nth-child(2):hover, .fields-preview .multi-button:nth-child(2).selected, .multi-button:nth-child(9):hover, .multi-button:nth-child(9).selected, .fields-preview .multi-button:nth-child(9):hover, .fields-preview .multi-button:nth-child(9).selected, .multi-button.red-colour.selected, .multi-button.red-colour:hover, .fields-preview .multi-button.red-colour.selected:hover {
    background: #b60000;
    border-color: #3E0000;
}

.multi-button:nth-child(2), .fields-preview .multi-button:nth-child(2),
.multi-button:nth-child(9), .fields-preview .multi-button:nth-child(9),
.multi-button.red-colour, .fields-preview .multi-button.red-colour {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f36f66+0,ee3124+100 */
    background: #f36f66; /* Old browsers */
    background: -moz-linear-gradient(top, #f36f66 0%, #ee3124 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f36f66 0%, #ee3124 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f36f66 0%, #ee3124 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f36f66', endColorstr='#ee3124', GradientType=0); /* IE6-9 */
}


.multi-button:nth-child(3), .fields-preview .multi-button:nth-child(3),
.multi-button:nth-child(10), .fields-preview .multi-button:nth-child(10),
.multi-button.orange-colour, .fields-preview .multi-button.orange-colour {

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fcbe5f+0,faa21a+100 */
    background: #fcbe5f; /* Old browsers */
    background: -moz-linear-gradient(top, #fcbe5f 0%, #faa21a 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fcbe5f 0%, #faa21a 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fcbe5f 0%, #faa21a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcbe5f', endColorstr='#faa21a', GradientType=0); /* IE6-9 */
    border-color: #ac7113;
}

.multi-button:nth-child(3):hover, .multi-button:nth-child(3).selected, .fields-preview .multi-button:nth-child(3):hover, .fields-preview .multi-button:nth-child(3).selected, .multi-button:nth-child(10):hover, .multi-button:nth-child(10).selected, .fields-preview .multi-button:nth-child(10):hover, .fields-preview .multi-button:nth-child(10).selected,
.multi-button.orange-colour.selected, .multi-button.orange-colour:hover, .fields-preview .multi-button.orange-colour:hover,  .fields-preview .multi-button.orange-colour.selected
{
    background: #da8200;
    border-color: #000;
}

.multi-button:nth-child(4), .fields-preview .multi-button:nth-child(4),
.multi-button:nth-child(11), .fields-preview .multi-button:nth-child(11),
.multi-button.blue-colour, .fields-preview .multi-button.blue-colour{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6891c9+0,2761b1+100 */
    background: #6891c9; /* Old browsers */
    background: -moz-linear-gradient(top, #6891c9 0%, #2761b1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #6891c9 0%, #2761b1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #6891c9 0%, #2761b1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6891c9', endColorstr='#2761b1', GradientType=0); /* IE6-9 */
}

.multi-button:nth-child(4):hover, .multi-button:nth-child(4).selected, .fields-preview .multi-button:nth-child(4):hover, .fields-preview .multi-button:nth-child(4).selected, .multi-button:nth-child(11):hover, .multi-button:nth-child(11).selected, .fields-preview .multi-button:nth-child(11):hover, .fields-preview .multi-button:nth-child(11).selected,
.multi-button.blue-colour.selected, .multi-button.blue-colour:hover, .fields-preview .multi-button.blue-colour:hover,  .fields-preview .multi-button.blue-colour.selected {
background: #114B9B;
    border-color: #000;
}

.multi-button:nth-child(5), .fields-preview .multi-button:nth-child(3n),
.multi-button:nth-child(5n), .fields-preview .multi-button:nth-child(3n),
.multi-button.cyan-colour, .fields-preview .multi-button.cyan-colour {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7de0e3+0,45d2d7+100 */
    background: #7de0e3; /* Old browsers */
    background: -moz-linear-gradient(top, #7de0e3 0%, #45d2d7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #7de0e3 0%, #45d2d7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #7de0e3 0%, #45d2d7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7de0e3', endColorstr='#45d2d7', GradientType=0); /* IE6-9 */
}

.multi-button:nth-child(5):hover, .multi-button:nth-child(5).selected, .fields-preview .multi-button:nth-child(5):hover, .fields-preview .multi-button:nth-child(5).selected, .multi-button:nth-child(12):hover, .multi-button:nth-child(12).selected, .fields-preview .multi-button:nth-child(12):hover, .fields-preview .multi-button:nth-child(12).selected,
.multi-button.cyan-colour.selected, .multi-button.cyan-colour:hover, .fields-preview .multi-button.cyan-colour:hover,  .fields-preview .multi-button.cyan-colour.selected {

    background: #079499;
    border-color: #000;
}

.multi-button:nth-child(6), .fields-preview .multi-button:nth-child(6),
.multi-button:nth-child(13), .fields-preview .multi-button:nth-child(13),
.multi-button.purple-colour, .fields-preview .multi-button.purple-colour {

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b971bd+0,9b34a1+100 */
    background: #b971bd; /* Old browsers */
    background: -moz-linear-gradient(top, #b971bd 0%, #9b34a1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #b971bd 0%, #9b34a1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #b971bd 0%, #9b34a1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b971bd', endColorstr='#9b34a1', GradientType=0); /* IE6-9 */
}

.multi-button:nth-child(6):hover, .multi-button:nth-child(6).selected, .fields-preview .multi-button:nth-child(6):hover, .fields-preview .multi-button:nth-child(6).selected, .multi-button:nth-child(13):hover, .multi-button:nth-child(13).selected, .fields-preview .multi-button:nth-child(13):hover, .fields-preview .multi-button:nth-child(13).selected,
.multi-button.purple-colour.selected, .multi-button.purple-colour:hover, .fields-preview .multi-button.purple-colour:hover,  .fields-preview .multi-button.purple-colour.selected{
    background: #7F1885;
    border-color: #000;
}

.multi-button:nth-child(7), .fields-preview .multi-button:nth-child(7),
.multi-button:nth-child(14), .fields-preview .multi-button:nth-child(14),
.multi-button.dark-green-colour, .fields-preview .multi-button.dark-green-colour {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4d7f88+0,004854+100 */
    background: #4d7f88; /* Old browsers */
    background: -moz-linear-gradient(top, #4d7f88 0%, #004854 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4d7f88 0%, #004854 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4d7f88 0%, #004854 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d7f88', endColorstr='#004854', GradientType=0); /* IE6-9 */
}

.multi-button:nth-child(7):hover, .multi-button:nth-child(7).selected, .fields-preview .multi-button:nth-child(7):hover, .fields-preview .multi-button:nth-child(7).selected,
.multi-button:nth-child(14):hover, .multi-button:nth-child(14).selected, .fields-preview .multi-button:nth-child(14):hover, .fields-preview .multi-button:nth-child(14).selected {
    background: #004854;
    border-color: #00080e;
}

.fields-preview button.rules-button + .rules-button {
    clear: none;
}

.customised-layout-1 .sop-files-list-for-station {
    width: 100% !important;
    margin-bottom: 30px;
}

.icon-link.alerts-single {
    cursor: pointer;
}

.angularjs-datetime-picker[date-format="HH:mm:ss"] #adp-time, .angularjs-datetime-picker[date-format="yyyy-MM-dd HH:mm:ss"] #adp-time {
    display: block !important;
}

.hidden-label-1 {
    display: none;
}



.field-inner-container input[type="checkbox"] {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    margin-right: 10px;
    float: left;

}

.user-select-pop-up {
    float: left;
    width: 100%;
    box-sizing: border-box;
    /* padding: 10px; */
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: -4px;
    padding-left: 0px;
    position: relative;
    z-index: 1;
    background: #17505A;
}

.users-selected-list span {
    color: #fff;
}

.user-select-pop-up td {
    cursor: pointer;
}

.task-remaining-text-question-title {
    font-size: 20px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
}

.user-select-pop-up td:hover {
    color: white;
}

.load-more-data-button + .btn.btn-primary, .load-more-data-button + .btn.btn-primary + .btn.btn-primary {
    float: left !important;
    margin-top: 0px;
    border: none !important;
    padding: 10px 20px;
}

.station-meta-data {
    color: #cfcfcf;
    font-size: 12px;
    margin-top: 2px;
    position: absolute;
}

.field-type-results-box {
    overflow: auto;
}

.operation-station.has-meta-data {
    padding: 17px 19px 33px 25px;
}

.remove-form-from-list {

    float: right;
    margin-right: 50px;
    margin-top: 11px;
}

.meta-data-values {
    float: left;
    width: 100%;
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: lighter;
}

.users-selected-list {
    float: left;
    width: 100%;
    margin-top: -17px;
}

.default-link {
    color: #06c492;
    text-decoration: none;
}

.manage-cartolytics-customer .standard-box {
    margin-bottom: 27px;
}

.operation-station-setup-wrapper .delete.job-page {
    bottom: 0px;
    right: 0px;
    top: 0px;
    height: 24px;
    background: #d44c4c !important;
    opacity: .7;
}

.delete.job-page:hover {
    opacity: 1;
}

.scan-results td.red, .more-data-pop-up td.red, .field-type-results-box td.red {
    background: #ee3124;
    color: #fff;
    border-bottom: 1px solid #f36f66;
    border-top: 1px solid #f36f66;
}

.scan-results td.green, .more-data-pop-up td.green, .field-type-results-box td.green {
    background: #10b43e;
    color: #fff;
    border-top: 1px solid #58cb78;
    border-bottom: 1px solid #58cb78;
}

.scan-results td.yellow, .more-data-pop-up td.yellow, .field-type-results-box td.yellow {
    background: #d8d807;
    color: #fff;
    border-top: 1px solid #e3e309;
    border-bottom: 1px solid #e3e309;
}

.scan-results td.blue, .more-data-pop-up td.blue, .field-type-results-box td.blue {
    background: #2761B1;
    color: #fff;
    border-top: 1px solid #6891c9;
    border-bottom: 1px solid #6891c9;

}

.scan-results td.orange, .more-data-pop-up td.orange, .field-type-results-box td.orange {
    background: #faa21a;
    color: #fff;
    border-top: 1px solid #fcbe5f;
    border-bottom: 1px solid #fcbe5f;

}

.scan-results td.sherpa-blue, .more-data-pop-up td.sherpa-blue, .field-type-results-box td.sherpa-blue {
    background: #004854;
    color: #fff;
    border-top: 1px solid #4d7f88;

}

.standard-box tr:nth-of-type(2n+1) td.sherpa-blue, .more-data-pop-up tr:nth-of-type(2n+1) td.sherpa-blue, .job-single-in-list:nth-of-type(2n+1) td.sherpa-blue, .sop-sign-off-table tr:nth-of-type(2n+1) td.sherpa-blue {

    color: #fff;

}

.scan-results td.cyan, .more-data-pop-up td.cyan, .field-type-results-box td.cyan {
    background: #45D2D7;
    color: #fff;
    border-bottom: 1px solid #7de0e3;
    border-top: 1px solid #7de0e3;
}

.scan-results td.purple, .more-data-pop-up td.purple, .field-type-results-box td.purple {
    background: #9B34A1;
    border-bottom: 1px solid #b971bd;
    border-top: 1px solid #b971bd;
    color: #fff;
}

.date-box-data-wrapper {
    position: absolute;
    bottom: 0px;
    color: #fff;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 80%;
    padding-left: 5%;
}

.date-box.today {
    background: #dadada;
}

.calendar .date-box.today > .date-box-content {
    background: none !important;
}

.calendar .date-box.selected > .date-box-content {
    background: none !important;
}

#calendar-wrapper {
    margin-top: 20px;
}

.calendar .date-box.today .date {
    color: #000 !important;
}

.date-box.has-data {
    background: #64ba4c;
    color: #fff;
}

.meta-data-filters {
    margin-top: 10px;
}

.meta-data-filter {
    float: left;
    width: 46%;
    clear: left;
    margin-bottom: 32px;
    box-sizing: border-box;
    border: 1px solid #616983;
    padding: 14px;
}

.meta-data-filter:nth-child(2n) {
    float: right;
    clear: none;
}

.with-or-without-data div {
    float: left;
    width: 100%;
}

.meta-data-filter {
    font-size: 13px;
}

.meta-data-filter select {
    font-size: 13px;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 3px;
    width: 100%;
    /* clear: left; */
    border: 1px solid #616983;
}

.with-or-without-data {
    float: left;
    width: 100%;
    margin-top: 5px;
}

.show-setting-0.field-outer-container {
    display: none !important;
}

/* WYSIWYG editor */

#wysiwyg-editor {
    background: #3b4465;
    color: #c1c7db;
}

#wysiwyg-editor.wysiwyg-open {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 10000;
    bottom: 20px;
    right: 20px;
}

#wysiwyg-editor:not(.wysiwyg-open) {
    display: none;
}

#wysiwyg-editor .ql-toolbar button {
    background: none !important;
    clear: none;
    margin-top: 0;
    margin-bottom: 0;
}

#wysiwyg-editor .ql-toolbar {

    background: #fff;
}

#wysiwyg-close {
    position: absolute;
    z-index: 100001;
    background: #fff;
    top: 0;
    right: 0;
    /* border: 2px solid #000; */
    /* border-radius: 50%; */
    display: none;
    padding-bottom: 12px;
}

#wysiwyg-editor.wysiwyg-open #wysiwyg-close {
    display: block;
}

.field-inner-container .ql-editor {
    padding: 0;
}

#wysiwyg-data {
    height: 1px;
    width: 1px;
    visibility: hidden;
}

/* WYSIWYG EDITOR END */

.digital-signature-sign-button {
    float: left;
    margin-bottom: 20px;
}

.form-digital-signature {
    float: left;
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    max-width: 205px;
    text-align: center;
    display: block;
    float: none;
    margin: 0 auto;
    clear: both;
    margin-bottom: 14px;
}

.form-digital-signature img {
    max-width: 100%;
}

td .form-digital-signature {
    max-width: 150px;
}

.button-float-left {
    float: left;
}

.button-float-right {
    float: right;
}

.mce-widget button {
    background: #c0c0c0 !important;
}

.field-rules.edit-field-popup.temp-hide {
    z-index: -1 !important;
}

.operation-station.has-meta-data.with-data-from-all-users {
    background: green;
    border-color: green;
}

.operation-station.has-meta-data.without-data-from-all-users {
    color: #000 !important;
    background: orange;
    border-color: orange !important;
}

.operation-station.has-meta-data.without-data-from-all-users span {
    color: #000 !important;
}

.operation-station.has-meta-data.without-data-from-all-users .arrow::before, .operation-station.has-meta-data.without-data-from-all-users .arrow::after, .operation-station.has-meta-data.without-data-from-all-users .arrow {
    border-color: black !important;
}

.red-cursor {
    cursor: pointer;
    color: red;
}

.generate-public-link a {
    float: right;
    clear: both;
    margin-top: 12px;
    margin-bottom: 13px;
    color: #0ea9ad !important;
}

.edit-switch {
    float: right;
    font-size: 16px;
    margin-right: 26px;
    font-weight: bold;
}

.edit-switch input {
    /* width: 26px; */
    /* height: 26px; */
    margin-top: 6px;
    transform: scale(2);
    position: relative;
    right: 14px;
}

.per-page-dropdown {
    float: right;

    font-size: 16px;
    margin-right: 10px;
}

.inner-standard-box-wrapper.show-filtering-options {
    float: right;
    width: 52%;
    margin-top: 20px;
}

.show-filtering-options select {
    float: right;
    width: 30%;
    margin-right: 16px;
}

.grid-or-block-view {
    float: left;
    margin-top: 11px;
}

.inner-standard-box-wrapper.show-filtering-options button, .actions-for-data-filtering button {
    float: right;
    margin-top: 0px;
    padding: 12px 22px;
}

.actions-for-data-filtering {
    float: left;
    width: 505px;
    margin-top: 20px;
    margin-left: 29px;
}

.actions-for-data-filtering div {
    float: left;
    margin-right: 20px;
}

.actions-for-data-filtering div:first-child {
    margin-top: 10px;
}

th select {
    max-width: 200px;
}

.scan-station-results th select {
    max-width: none;
}

.template-list-single a {
    cursor: pointer;
}

.invisible-wrap.one-third-block.first.scan-station-entry {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 15px;
}

.invisible-wrap.two-thirds-block.last.scan-station-results {
    margin-top: 0px;
}

.field-inner-container iframe {
    border-radius: 15px;
}

.fields-preview .scan-station-field-previews {
    display: none;
}

.file-upload-container img {
    width: 61px;
}

.remove-bottom-margin {
    margin-bottom: 0px !important;
}

.send-alert .mce-tinymce {
    float: left !important;
}

.file-manager .inner-standard-box-wrapper {
    overflow: auto;
}

.scan-station-results .pagination {
    float: right;
    width: 100%;
    border: none;
    box-sizing: border-box;
    padding: 25px 25px 0px 25px;
    display: inline;
    margin-bottom: 0px;
}

.scan-station-results .pagination select {
    width: 39%;
    margin-right: 27px;
    margin-bottom: 0px;
}

.scan-station-results .pagination button + button {
    margin-left: 10px;
    float: left;
}

.scan-station-results .page-number {
    float: left;
    /* clear: both; */
    margin-top: 8px;
    font-weight: bold;
    margin-left: 20px;
}

.page-number + label {
    float: left;
    margin-left: 0px;
    margin-top: 6px;
    width: auto;
    /* font-weight: bold; */
    clear: both;
}

.scan-station-results .pagination button {
    padding: 10px;
}

.pagination-button-wrapper {
    float: left;
}

.pagination-button-wrapper-jobs {
    float: right;
}

.pagination-button-wrapper-jobs button {
    float: right;
    padding: 8px 16px;
    transition: background-color .3s;
    margin-right: 10px;
}

.pagination-button-wrapper .page-number {
    float: left;
    /* clear: both; */
    margin-top: 8px;
    font-weight: bold;
    margin-left: 20px;
}

.jobs-page-number {
    float: right;
    margin-top: 8px;
    margin-right: 8px;
    width: auto;
    font-weight: bold;
}

.colour-scheme-delete {
    size: 40%;
    float: right;
}

.show-form-button {
    float: left !important;
    margin-bottom: -6px;
}

.return-to-job {
    float: right !important;
    margin-right: 30px;
    display: none;
}

.mobile.sidebar-switches {
    float: left;
    list-style: none;
    width: 100%;
    padding-left: 10px;
    font-size: 17px;
}

.sidebar-switches .help-mode {
    float: left;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.sidebar-switches .help-mode {
    clear: both;
    float: left;
}

.disabled-button {
    opacity: .2;
    cursor: default;
}

.disabled-button:hover {
    background: #0ea9ad !important;
}

.scan-station-entry .standard-box {
    position: relative;
}

#showMoreSingleDataBox.field-outer-container {
    float: left;
}

.covid-19-pop-up {
    position: absolute;
    z-index: 999;
    left: 50%;
    margin-left: -414px;
    top: 20px;
    background: #fff;
}

.covid-19-pop-up .close-rules {
    color: #000 !important;
}

.uib-timepicker {
    float: left;
    width: 100%;
    height: 100%;
    min-height: 78px;
}

.uib-timepicker div {
    float: left;
    width: 45px;
    margin-right: 10px;
    position: relative;
    height: 20px;
}

@media only screen and (max-width: 976px) {
    .uib-timepicker div{
        width: 32px;
    }
}


.timepicker-hour .up-switch,
.timepicker-hour .down-switch,
.timepicker-minute .up-switch,
.timepicker-minute .down-switch
{
    height: 20px;
}

.uib-timepicker .time-input {
    height: calc(1.5em + .75rem + 2px);
}

.uib-timepicker i.fa.fa-angle-up, .uib-timepicker i.fa.fa-angle-down {
    border: none !important;
}

.uib-timepicker input {
    margin-bottom: 0px !important;
}

.uib-timepicker .btn.btn-link {
    padding: 0px !important;
    margin-left: 9.5px;
    font-size: 31px;
    height: 7px !important;
    position: absolute;
    margin-top: -15px;
    color: grey !important;
}

@media only screen and (max-width: 976px) {

    .uib-timepicker .btn.btn-link{
        margin-left: 2.5px;
        margin-top: -18px;
    }
}


.time-input {
    position: relative !important;
    z-index: 1;
}

.uib-timepicker .down-switch .btn.btn-link {
    margin-top: -8px;
}

.date-picker-input {
    position: relative;
    float: left;
    width: 100%;
}

.date-picker-input input {
    width: 68%;
    float: left;
    padding: 0.375rem 0.45rem;
}
.uib-timepicker input {
    padding: 0.375rem 0.45rem;
}

@media only screen and (max-width: 976px) {
    .date-picker-input input {
        width: 80%;
        float: left;
        padding: 0.375rem 0.25rem;
    }

    .uib-timepicker input {
        padding: 0.375rem 0.45rem;
    }

}

.date-picker-input .fa.fa-calendar {
    border: 0px !important;
    font-size: 21px;
    color:#fff;
}

.date-picker-input button {
    padding: 5px;
    float: left;
    margin-left: 8px;
}

.uib-datepicker-popup.dropdown-menu.ng-scope {
    padding: 0px !important;
    border: 1px solid #023038 !important;
    bottom: 0px !important;
    top: auto !important;
    background: #eeeeee;
}

.uib-datepicker-popup i {
    border: none !important;
    /* font-size: 19px; */
    /* padding: 0px !important; */
}

.uib-datepicker-popup thead tr:first-child th {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.uib-datepicker-popup thead tr + tr th {
    color: #313131 !important;
    font-size: 17px;
    padding: 0px !important;
    margin-top: 1px !important;
    padding-bottom: 5px !important;
    color: #fff !important;
}

.uib-datepicker-popup thead tr:first-child button {
    border: none !important;
    color: #fff !important;
}

.uib-datepicker-popup thead tr:first-child {
    background: #0d4751 !important;
}

.uib-datepicker-popup button {
    background: none !important;
    color: #3b3a3a !important;
    border: none !important;
}

.uib-datepicker-popup thead tr th {
    border: none !important;
}

.uib-datepicker-popup tr td:first-child em {
    display: none !important;
}

.uib-datepicker-popup thead tr:first-child th + th + th button {
    margin-left: 0px !important;
    margin-right: 10px !important;
}

.uib-datepicker-popup thead tr + tr {
    background: #136574 !important;
    padding-bottom: 10px !important;
}

.uib-months, .uib-years {
    background: none !important;
}

.uib-weeks {
    background: none !important;
}

.uib-weeks .btn.btn-default {
    margin-left: 0px !important;
}

.formception-container {
    position: absolute;
    width: 80%;
    height: 90%;
    left: 10%;
    top: 25px;
    background: #022126;
    padding: 2% 2% 2% 2%;
    color: white;
    overflow: auto;
    box-sizing: border-box;
}

.text-transform-capitalize {
    text-transform: capitalize !important;
}

.form-buttons {
    float: left;
    width: 100%;
    clear: both;
}

.scan-station-buttons{
    padding: 0px 26px;
}

.new-or-edit-field-wrapper {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    background: #e1e5e6;
    box-sizing: border-box;
    padding: 8px 13px;
    word-break: break-word;
    border-radius: 10px;
}

.skin-setting-dark .new-or-edit-field-wrapper {
    background: #374244;
}

.new-or-edit-field-wrapper input {
    position: relative;
    top: 3px;
}

.hide-field {
    display: none !important;
}

.show-field {
    display: inline !important;
}

.form-data-filter-button {
    margin-top: -48px;
    padding-bottom: 10px;
    padding-top: 11px;
}

.blocworx-forms-container {
    margin-top: 30px;
}

.field-mulitbutton-blocworx-links a {
    color: #73bc73;
}

.field-mulitbutton-blocworx-links {
    float: left;
    margin-bottom: 30px;
    margin-right: 14px;
}

.radio-button-from-station-field-type input[type="radio"] {
    /* width: 17px; */
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    position: relative;
    top: 4px;
    margin-right: 15px;
}

.fields-preview .multi-button:nth-child(2n) {
    background: #bfbf2a !important;
}

/*.yes-answers .multi-button:nth-child(4n) {
    background: red !important;
}*/

.field-inner-container pre {
    color: #fff !important;
}

#showMoreSingleDataBox .hidden-label-1 {
    display: inline;
}

.faded-out {
    float: left;
    width: 100%;
    position: relative;
    opacity: .2;
}

.redirect-box {
    position: absolute;
    z-index: 99999;
    text-align: center;
    width: 100%;
    padding-top: 150px;
    font-size: 28px;
    box-sizing: border-box;
    padding-left: 20%;
    padding-right: 20%;
    height: 100%;
    color: #fff;
    background: #022126;
}

.field-inner-container.ng-scope {
    position: relative;
    float: left;
    width: 100%;
}

.field-inner-container > .user-select-pop-up {
    position: absolute;
    top: 106%;
    z-index: 99;
}

.customised-layout-1 .field-outer-container:not(.height-auto) {
    min-height: unset;
}

.hide-in-form {
    display: none;
}

.data-filtering-section {
    float: left;
    width: 100%;
}

.edit-mode-true {
    border-color: red !important;
}

.show-more-mode-true {
    border-color: greenyellow !important;
}

.field-type-results-box {
    overflow: auto;
}

.scan-station-results.two-thirds-block .box-title span, .scan-station-results.two-thirds-block .box-title .edit-switch {
    float: left;
    clear: both;
}

.scan-station-results.two-thirds-block .edit-switch input {
    right: -6px;
    margin-right: 20px;
}

.scan-station-results.two-thirds-block .box-title .edit-switch {
    margin-top: 7px;
}

.email-alert-options {
    float: left;
    width: 100%;
}

.email-alert-options .load-more-data-button {
    float: left;
    margin-left: 10px;
}

.add-all-button {
    float: left;
    font-size: 13px;
    padding: 7px 13px;
    border-radius: 5px;
    clear: both;
    margin-bottom: 20px;
    margin-top: 5px;
}

.recipient-list {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px;
    border: 1px solid #004854;
    margin-top: 6px;
    margin-bottom: 10px !important;
    float: left;
}

.data-added-message {
    float: right;
    clear: right;
    margin-top: 10px;
    color: #18e418;
    margin-bottom: 10px;
    margin-right: 25px;
}

.skin-setting-bright .data-added-message {
    color: #426632;
}



.field-type-wysiwyg-for-user .close-rules {
    z-index: 9999999;
    position: absolute;
}

.rule-back-to-top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    font-size: 12px;
    z-index: 999;
}

.rule-back-to-top .fa.fa-angle-up {
    border: none !important;
}

.is-in-iframe .help-message-box {
    display: none;
}

.is-in-iframe .content {
    margin-left: 0px;
    width: 100%;
    max-width: none !important;
    padding-right: 0px;
    margin-top: 0px;
    padding-left: 0px;
}

.embedded-iframe {
    border: 1px solid;
    border-radius: 23px;
    border-color: #434343;
}

.is-in-iframe .customised-layout-0 .scan-form .inner-standard-box-wrapper {
    padding-top: 21px;
}

.is-in-iframe .customised-layout-0 .standard-box, .is-in-iframe .customised-layout-0 #scan-station-fields {
    margin-right: 0px !important;
}

.is-in-iframe .customised-layout-0 .scan-form .inner-standard-box-wrapper {
    padding-top: 21px;
    padding-left: 2%;
    padding-right: 2%;
}

.is-in-iframe .customised-layout-0 .invisible-wrap.two-thirds-block.last.scan-station-results {
    margin-top: 0px;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    width: 68.6%;
    margin-left: 0px !important;
}

.embedded-iframe iframe {
    position: relative;
    width: 100% !important;
    z-index: 98;
    float: left;
    height: 100% !important;
}

.embedded-iframe.iframe-type-popup {
	padding-top: 0px !important;
	position: fixed !important;
}

.embedded-iframe.iframe-type-popup iframe {
	position: relative !important;
	width: 100% !important;
	z-index: 98;
	float: left;
	height: 100%;
	top: 0%;
	left: 0%;
}



.is-in-iframe .customised-layout-0 #scanStationHeader {
    border-radius: 0px 0px 0px 0px !important;
}

.embedded-iframe.iframe-type-popup {
    position: fixed;
    height: 88% !important;
    width: 80% !important;
    z-index: 99999;
    top: 6%;
    left: 10%;
}

.embedded-iframe.iframe-type-embed {
    height:600px;
}

.embedded-iframe.iframe-type-full-screen-popup {
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;
    z-index: 99999;
    top: 0px;
    left: 0px;
}

.embedded-iframe.iframe-type-embed {
    height: 500px;
}

.embedded-iframe.iframe-type-embed-with-button {
    position: relative;
}

.embedded-iframe .close-popup {
    z-index: 999999;
    top: 8px;
    right: 33px;
    font-size: 23px;
}

.box-title .fas {
    float: right ;
}

.formception-2-container .box-title {
    padding-left: 0px;

}


.formception-2-container .box-title + .accordian-sub-bloc-container {
    border-bottom: 2px dashed;
    padding-left: 0px;
    margin-bottom: 0px;
    width: 100%;
    float: left;
}

.iframe-background {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 0;
    width: 100%;
    background: #000;
    opacity: 0;
    z-index: 99;
}

.iframe-type-popup + .iframe-background {
    height: 100%;
    opacity:.75;
}

.is-in-iframe.compact-view .scan-form input, .is-in-iframe.compact-view .scan-form textarea {
    font-size: 13px;
    padding: 7px;
}

.skin-setting-bright .is-in-iframe .standard-box {
    background: #d4d8d9;
}

.skin-setting-bright .embedded-iframe {
    border-color: #bfbfbf;
}

.is-in-iframe.compact-view button {
    padding: 3px 12px;
    font-size: 13px;
}

.is-in-iframe .invisible-wrap.two-thirds-block.last.scan-station-results {
    margin-top: 0px;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
}

.is-in-iframe .scan-results h3, .is-in-iframe .scan-results .load-more-data-button, .is-in-iframe .scan-results .load-more-data-button + button, .is-in-iframe .counts-section {
    display: none;
}

.is-in-iframe .close-rules.scan-station-header-close {
    display: none !important;
}

.invisible-wrap.full-size-block.show-profiles-pop-up {
    position: fixed;
    z-index: 99999999;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    overflow: auto;
    padding-bottom: 200px;
    box-sizing: border-box;
}

.file-or-image + span {
    display: none;
}

.choose-template.ng-scope {
    float: right;
    width: 177px;
}

.template-list-single.ng-scope {
    float: left;
    clear: both;
}

.choose-template.ng-scope {
    float: right;
    width: 233px;
    position: absolute;
    width: 500px;
    left: 50%;
    margin-left: -280px;
    top: 100px;
    z-index: 999;
    background: #023038;
    padding: 30px;
}

.pdf-or-word {
    float: left;
    width: 100%;
}

.field-type-wysiwyg .field-inner-container a, .field-type-wysiwyg-for-user .field-inner-container a {
    color: #48d9cb;
}

.choose-template p {
    float: left;
}

.choose-template .close-rules {
    position: relative;
    float: right;
    margin-top: -16px;
}

.section-block .icon-link:hover {
    background: #2FA798;
}

.section-block .icon-link:hover .link-text {
    background: #003743;
}

.job-page .icon-link:hover .link-icon {
    background: #003743;
}

.icon-link:hover .link-text:after {
    border-left: 8px solid #003743;
}

.invisible-wrap.full-size-block.section-block:nth-child(2n) .icon-link:hover {
    background: #ef970f;
}

.invisible-wrap.full-size-block.section-block:nth-child(3n) .icon-link:hover {
    background: #e12417;
}

.field-type-multi-file .file-upload-container input {
    width: 35%;
}

.field-type-multi-file .file-upload-container button {
    width: 47%;
    padding-top: 9px;
    padding-bottom: 11px;
    height: 100%;
    box-sizing: border-box;
    float: left;
    margin-top: -3px;
}

.table.sop-sign-off-table thead td {
    color: #fff;
}

fieldset {
    width: 100% !important;
}

.faded-background {
    position: fixed;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 2;
}

.blocworx-popup {
    position: fixed;
    z-index: 3;
    width: 80%;
    top: 50px;
    left: 10%;
    height: 600px;
}

.manage-page-pop-up p {
    font-size: 16px;
    float: left;
    width: 100%;
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 6px;
    color: #fff;
}

.skin-setting-bright .manage-page-pop-up p {
    color: #004854;
}

.manage-page-pop-up .box-title {
    font-size: 16px;
    width: 77%;
}

.edit-mode, .live-mode {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    background: #d03939;
    margin-bottom: 12px;
}

.live-mode {
    background: #4fb14f;
    margin-top: 20px;
    color: #f0f4f5;
}

.skin-setting-bright .selected-icon-display, .skin-setting-bright .manage-page-pop-up div {
    color: #004854;
}

.new-or-edit-field-wrapper.multi-button-field-builder button {
    margin-bottom: 7px;
    font-size: 11px;
    padding: 9px 25px !important;
    margin-top: 9px;
    border: none;
}

.multi-button-field-builder .invisible-wrap {
    margin-bottom: 4px;
}

.help-button {
    display: none;
}

.help-button .fa {
    box-shadow: none;
    border: none !important;
    position: absolute;
    right: -9px;
    font-size: 24px;
    cursor: pointer;
    top: -4px;
}

.floating-after-title.help-button .fa {
    position: relative !important;
    display: inline;
    top: 1px;
}

.help-mode-on .help-button {
    display: inline;
}

.help-button a {
    color: #fff;
}

.scan-form .inner-standard-box-wrapper {
    padding: 0px;
}

/* Override Bootstrap */

.row {
    font-size: unset !important;
    font-weight: unset !important;
}

p {
    margin-top: unset;
    margin-bottom: unset;
    word-break: break-word;
}

.outer-container-edit-field *:not(.arrow):not(.rules-button):not(.reorder-field-arrows):not(.delete):not(.fa-angle-down):not(.fa-angle-up), .outer-container-edit-field button:not(.arrow):not(.rules-button):not(.delete) {
    cursor: move;
}

.child-element.dragged-enter * {
    pointer-events: none;
}

.child-element.dragged-enter {
    background: grey;
}

.resize-grid-popup {
    position: absolute;
    z-index: 1000;
    width: 180px;
    padding: 10px;
    background: #455052;
    border: 1px solid #ddd;
    right: 0px;
}

.add-new-item {
    position: absolute;
    /* right: -157px; */
    background: #455052;
    z-index: 998;

}

.new-item-plus .fa.fa-plus {
    border: none;
    padding: 9px !important;
    cursor: pointer;
}

.child-element-add-field {
    position: absolute;
    left: 0px;
    bottom: 10px;
    top: auto;
    margin-bottom: 0px;
    cursor: pointer;
    opacity: 0;
}
/*.row .container{*/
/*    position: absolute;*/
/*}*/
.resize-element {
    position: absolute;
    right: 2px;
    top: -2px;
    font-size: 23px;
    cursor: pointer;
    opacity: 0;
    z-index: 999;
}


.delete.child-element-add-item {
    -webkit-transition: all .25s ease 0s !important;
    -moz-transition: all .25s ease 0s !important;;
    -ms-transition: all .25s ease 0s !important;;
    -o-transition: all .25s ease 0s !important;;
    transition: all .25s ease 0s !important;;
}

.chart-count-item {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 12px;
    position: relative;
}

.chart-count-item .fa.fa-trash {
    top: -7px;
    font-size: 18px;
    position: relative;
    right: -6px;
}

.chart-selection {
    float: left;
    width: 100%;
    cursor: pointer;
}

.invisible-wrap.full-size-block.accordian .standard-box {
    margin-bottom: 0px;
}

.invisible-wrap.full-size-block.accordian {
    margin-bottom: 20px;
}

.added-rules.broken-rule-true {
    border-color: #de4141;
    border-width: 3px;
}

.broken-rule-message {
    margin-left: -4px;
    margin-top: -4px;
    padding: 6px;
    background: #de4141;
    margin-right: -44px;
    color: white;
    font-size: 13px;
    /* display: none; */
    margin-top: -22px;
    margin-left: -22px;
    border-radius: 10px 10px 0px 0px;
    display: none;
}

.broken-rule-true .broken-rule-message {
    display: block;
}

.broken-rule-true .edit-rule, .broken-rule-true .delete-rule, .broken-rule-true .cancel-edit-rule {
    top: 50px;
}

.data-view-selection .one-quarter-block {
    border: 1px solid;
    padding: 10px;
    text-align: center;
    margin-bottom: 22px;
    margin-top: 0px !important;
    border-radius: 11px;
}

.data-view-selection i {
    font-size: 35px;
    margin-top: 10px;
    margin-bottom: 13px;
}

.data-view-selection .one-quarter-block {
    border: 1px solid;
    padding: 10px;
    text-align: center;
    margin-bottom: 22px;
    margin-top: 0px !important;
    border-radius: 11px;
    opacity: .3;
    cursor: pointer;
}

.data-view-selection .one-quarter-block.selected, .data-view-selection .one-quarter-block:not(.not-available):hover {
    opacity: 1;
}

.data-view-selection .one-quarter-block.not-available {
    cursor: default;
}

.data-lookup h3 {
    font-size: 20px;
}

.signature-pad {
    background: white;
}

.digital-signature .signature-pad {
    margin-left: 25px;
}

.digital-signature-field button {
    font-size: 13px;
    float: left;
}

.finished-sig {
    background: #bababa;
}

.skin-setting-dark .update-data {
    color: #fff;
}

.skin-setting-bright .update-data {
    color: #004854;
}
.field-params-popout-click {

}

.show-field-rule-parameters {
    position: fixed;
    top: 35px;
    /* left: -4px; */
    z-index: 9999;
    opacity: 1;
    right: 0px;
    cursor: pointer;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
		width: 0;
	height: 0;
	border-style: solid;
	border-width: 25px 50px 25px 0;
	border-color: transparent #455052 transparent transparent;
	cursor:pointer;
}

.skin-setting-bright .show-field-rule-parameters {
    background: none;
}

#blocworx .page-bar h1 {
	color: #014854;
}


#blocworx .embedded-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    /*padding-top: 56.25%; !* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) *!*/
    padding-top: 35.25%;
}

.responsive-iframe {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

#iframe .content{
background-color: #323d3f;
}


#iframe.skin-setting-dark .content{
    background-color: #323d3f;
}

#iframe .data-filtering-wrapper {
	display: none;
}


#iframe.skin-setting-bright .content{
    background-color: #d4d8d9;
}


#blocworx .linked-data-options,
#blocworx .linked-list{
    border-top: 10px solid #323d3f;
    margin: 0px auto;
    padding: 20px 0px 0px 0px;
    border-radius: initial;
    float: left;
    width: 100%;
}

#blocworx.skin-setting-dark .linked-data-options{
    border-color: #323d3f;

}

#blocworx.skin-setting-bright .linked-data-options {
    border-color: #d4d8d9;
}

#blocworx.skin-setting-bright .page-bar h1 {
    color: #004854;
}

.skin-setting-bright .select-icon-box .section-block .link-icon i, .skin-setting-bright .select-icon-box .section-block .link-icon span {
	color: #004854;
}

#export-pdf-button {
	cursor: pointer;
}

.single-checkbox-parameter {
    float: left;
    width: 100%;
    margin-bottom: 11px;
    word-break: break-word;
}

.single-checkbox-parameter input[type="checkbox"] {
    margin-right: 10px;
}

.time-input .ng-invalid {
    border: 2px solid red !important;
    color: #fff !important;
    background: red !important;
}

.one-third-block.automatically-set-margins:nth-child(3n+1), .one-third-block.automatically-set-margins:first-child {
    margin-left: 0px !important;
}

.one-third-block.automatically-set-margins:nth-child(3n) {
    margin-right: 0px !important;
}

.one-third-block.single-role {
    margin-bottom: 36px;
    position: relative;
}

.single-added-role {
    border: 1px solid;
    padding: 9px;
    float: left;
    border-radius: 11px;
    margin-right: 14px;
    margin-bottom: 14px;
}

.single-added-role .fa.fa-window-close {
    cursor: pointer;
}

.accordian .fas.fa-plus {
    color: #172124;
}

.form-buttons .submit-button {
	margin-right: 25px;
}

#grid-form-station input.minor-issue, #grid-form-station textarea.minor-issue, #grid-form-station  div.minor-issue, #grid-form-station  span.minor-issue {
	background: red !important;
}

.is-in-iframe.compact-view .edit-switch {
	font-size: 13px;
	margin-bottom: -29px !important;
}

.is-in-iframe .pagination.upper, .is-in-iframe .pagination-button-wrapper.upper, .is-in-iframe .pagination-button-wrapper.upper + .page-number, .is-in-iframe .pagination-button-wrapper.upper, .is-in-iframe .pagination-button-wrapper.upper + .page-number + label + label {
	display: none !important;
}

.is-in-iframe .edit-switch {
	float: left;
	width: 100% !important;
	height: 49px;
}

.is-in-iframe .edit-switch input[type="checkbox"] {
		transform: scale(1.3);
}

.is-in-iframe .edit-switch input {
	right: -11px;
	margin-right: 15px !important;
	margin-top: 3px;
	margin-bottom: 0px;
}

.child-element.col-md-1 .resize-element + button.delete,
.child-element.col-md-2 .resize-element + button.delete,
.child-element.col-md-3 .resize-element + button.delete,
.child-element.col-md-4 .resize-element + button.delete,
.child-element.col-md-5 .resize-element + button.delete,
.child-element.col-md-6 .resize-element + button.delete,
.child-element.col-md-7 .resize-element + button.delete,
.child-element.col-md-8 .resize-element + button.delete,
.child-element.col-md-9 .resize-element + button.delete,
.child-element.col-md-10 .resize-element + button.delete,
.child-element.col-md-11 .resize-element + button.delete,
.child-element.col-md-12 .resize-element + button.delete
{
    width: 66px;
    line-height: 13px;
    font-size: 10px;
    padding: 2px;
    top: 23px;
    right: -17px;
    position: relative;
    margin: 0px 7px;
}

.mce-panel {
	z-index: 999999 !important;
}

.inner-pop-up-box {
    float: left;
    width: 100%;
    background: #394446;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 10px;
}

.inner-pop-up-box .box-title {
    padding-left: 0px;
    margin-left: 0px;
}

.skin-setting-bright .inner-pop-up-box {
    background: #e2e6e7;
}

.skin-setting-bright .add-new-item {
    background: #dde1e2;
}

.for-checkbox {
    font-weight: normal;
    cursor: pointer;
    justify-content: normal !important;
}

.section-length-0 {
    display: none;
}

.selected-icon-display {
    font-size: 32px;
    margin-bottom: 10px;
}

.selected-icon-display i {
    border: none;
}

.selected-icon-display {
    font-size: 22px;
    background: #384547;
    padding: 7px 12px;
    border-radius: 10px;
    float: left;
    text-align: center;
}

.inner-pop-up-box .selected-icon-display {
    background: #2C3739;
    margin-top: 10px;
}

.skin-setting-bright .selected-icon-display {
    background: #dce0e1;
}

.box-title.field-name {
    position: sticky !important;
    top: -17px;
    width: 100%;
    left: 0px !important;
    background: #A8B3B5;
    top: -30px;
    box-sizing: border-box;
    padding: 10px;
    border-bottom: 3px solid #5b5a5a;
    color: black;
}

.reorder-section, .reorder-grid-row {
    float: left;
    width: 2%;
    margin-top: -10px;
    font-size: 14px !important;
}

.module-manage-section .sub-header-wrapper h2, .page-manage-section .sub-header-wrapper h2 {
    width: 98%;
    padding-left: 19px;
    padding-bottom: 20px;
}

.reorder-section i, .reorder-grid-row i {
    font-size: 25px !important;
    line-height: 21px;
    cursor: pointer;
}

.invisible-box.full-size-block.manage-field-results-order {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.field-section-title {
    font-size: 16px;
    float: left;
    width: 100%;
    /* margin-bottom: 20px; */
    cursor: pointer;
    font-weight: bold;
    box-sizing: border-box;
    /* position: relative; */
    /* padding-right: 18%; */
    /* position: absolute; */
    left: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    /* line-height: 22px; */
    /* display: flex; */
    align-items: center;
    padding-left: 5px;
    padding-right: 0px;
    margin-bottom: -54px;
}


.new-edit-field-parameters .standard-box {
    background: #3C4749;
}

.edit-new-field-container-inner {
    float: left;
    margin-top: 30px;
    width: 100%;
}

.skin-setting-bright .field-parameter-information, .skin-setting-bright .new-edit-field-parameters {
    background: #cfcfcf !important;
}

.skin-setting-bright .side-bar-show-hide {
    color: #004854 !important;
}

.invisible-wrap.one-third-block.first.field-select {
    width: 100% !important;
}

.field-section-title i {
    font-size: 28px;
    position: relative;
    float: right;
    position: absolute;
    right: 5%;
    top: 51%;
    margin-top: -19px;
}

.field-select .new-edit-field-parameters {
    display: none !important;
}

.field-parameter-information .edit-new-field-title {
    display: none !important;
}

.field-parameter-information .field-select-checkbox {
    display: none;
}

.field-parameter-information .new-or-edit-field-wrapper {
    background: none;
    padding: 0px;
    margin: 0px;
}

.edit-new-field-container > .fas {
    color: #fff !important;
}

.new-or-edit-field-wrapper label.for-checkbox {
    margin-bottom: 0px !important;
}

.bloc-setting-panel {
    background: #3C4749;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 17px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.skin-setting-bright .bloc-setting-panel {
    background: #e0e0e0;
}

.skin-setting-bright .full-row-delete-reorder {
    background: #c9c9c9;
}

.bloc-setting-panel button {
    border: none;
    font-size: 14px;
}

.bloc-editor-update-complete {
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    -ms-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 278px;
    text-align: center;
    background: green;
    box-sizing: border-box;
    padding: 20px 33px;
    border-radius: 9px 0px 0px 0px;
    -webkit-transform: translateY(80px);
    -moz-transform: translateY(80px);
    -ms-transform: translateY(80px);
    -o-transform: translateY(80px);
    transform: translateY(80px);
    opacity: 0;
    z-index: 9999999999;
}

.bloc-editor-update-complete.show {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
}

.single-rule-error {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid aliceblue;
    padding-bottom: 5px;
}

.side-bar-show-hide {
    float: left;
    width: 100%;
}

h3.side-bar-show-hide {
    float: left;
    width: 100%;
    color: #fff;
    font-size: 16px;
    border-bottom: 1px dashed;
    padding-bottom: 12px;
    margin-bottom: 25px;
    cursor: pointer;
}

.initial-field-parameter-seperator {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.edit-new-field-container.accordian.invisible-wrap.one-third-block.first.selected {
    width: 100% !important;
    height: auto !important;
}

.edit-new-field-container.accordian.invisible-wrap.one-third-block.selected {
    width: 100% !important;
    height: auto !important;
    box-sizing: border-box !important;
    margin-left: 0px !important;
}

.edit-new-field-container.accordian.invisible-wrap.one-third-block.selected .field-section-title {
    position: relative !important;
    padding: 0px !important;
    font-size: 20px !important;
    margin-left: 6px;
}

.matching-pairs .invisible-wrap.full-size-block.first {
    margin-bottom: 0px !important;
    border-bottom: 1px solid;
}

.edit-new-field-title {
    position: relative;
}

.skin-setting-dark .inner-field-initial-parameter-panel {
    padding: 15px;
    background: #232E30;
    border-radius: 11px;
    float: left;
    margin-bottom: 23px;
}

.page-sections-modules-wrapper {
    padding-top:19px
}

/*.field-type-results-box .field-rules-hover.edit-field-popup-on-hover {*/
/*    !*right: 0px !important;*!*/
/*    left: auto !important;*/
/*    top: 0px !important;*/
/*}*/

table.for-preview {
    opacity: 0.4;
    display: none;
}


.scan-form .multi-button-container:focus {
    border: 1px dashed grey;
}

.multi-button-container:focus {
    border: 1px dashed #747F81;
}

.multi-button-container:active {
    border: 1px dashed #747F81;
}

.locked-filter {
    opacity:.4;
}

.initial-field-parameter-seperator .multi-button {
    float: left;
    clear: none;
    margin-top: 13px;
    margin-right: 13px;
    margin-bottom: 0px;
}

/* TODO add to component saas */
cross-bloc-data-updates-component button {
    width: 100%;
}

.date-picker-input > div + input {
    width: 80%;
}

.scan-station-buttons .delete {
    color: #fff;
}